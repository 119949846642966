<script>
    import axios from 'axios';

    export default {
        name: 'AppClientShow',
        props: {
            client: {
                type: Object
            },
            roles: {
                type: Array
            }
        },
        data: function() {
            return {
                selectedRoles: [],
                selectedContactRoles: [],
                showContactModal: false,
                currentContactShown : {},
                item: {}
            }
        },
        created: function() {
            this.item = this.client;
        },
        mounted: function() {
            this.populateSelectedRoles(this.item);
        },
        methods: {
            populateSelectedRoles: function (object) {
                this.selectedRoles = object.roles.map((item) => {
                    return item
                });
               // console.log(this.selectedRoles);
            },
            checkBeforeSubmit: function (e, isContact = null) {
                let data = {};
                data.roles = isContact ? this.selectedContactRoles : this.selectedRoles;
                console.log("dataRoles",data.roles);
                for(let d of data.roles) {
                   // console.log(d);
                }

                const config = {
                    headers: { 'content-type': 'application/json' }
                };

                let url = isContact ? (e.target.action + '/' + this.currentContactShown.id) : e.target.action;

                axios.put(url, data, config)
                    .then((response) => {
                       // console.log(data);
                        if(!isContact) {
                            this.item = response.data;
                            //console.log(this.item);
                            this.populateSelectedRoles(this.item);
                        }
                    })
                    .catch((error) => {
                        console.warn(error);
                    })
            },
            toggleContactModal: function(id) {
                this.showContactModal = !this.showContactModal;
                this.currentContactShown = this.item.contacts.find(item => item.id === id);
                this.selectedContactRoles = this.currentContactShown.roles;
            }
        }
    }
</script>