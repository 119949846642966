<template>
    <div class="minco-sidebar" :class="{ 'minco-sidebar--opened': openMenu, 'minco-sidebar--closed': !openMenu}">
        <ul class="minco-sidebar__items list-unstyled pt-4 pb-5">
            <li v-for="(item, index) in navItems" :key="index" class="minco-sidebar__item" :class="{ 'minco-sidebar__item--extra': item.extra }">
                <div class="minco-sidebar__entry" :class="{ 'minco-sidebar__entry--active': asChildActivated(item, currentItem.url)}">
                    <a v-if="!item.children" class="minco-sidebar__link" :href="item.url" :target="item.target ? item.target : '_self'" @click="(event) => { manageClick(event, item) }">
                        <div :class="{ 'text-white text-uppercase': item.extra }">
                            <div class="minco-sidebar__icon text-center">
                                <i :class="'icon ' + item.icon"></i>
                            </div>
                            <div class="minco-sidebar__label pr-5 ">
                                <span v-if="item.label.includes('Vos contacts Minco')" class="d-block" style="margin-top:11px;display:inline-block;" v-html="item.label"></span>
                                <span v-if="!item.label.includes('Vos contacts Minco')" v-html="item.label" style="margin-top:15px;"></span>

                                <div v-if="item.extra && infoSeller != null" :class="{ ' infosContact': item.extra}" id="infoSeller">
                                    <span class="text-white">Commercial</span><br>
                                    <b>{{infoSeller ? infoSeller.firstname : ""}} {{infoSeller ? infoSeller.lastname : ""}}</b><br/>
                                    <i class="icon icon-telephone"></i> {{infoSeller ? ((infoSeller.mobile!="") ? infoSeller.mobile : infoSeller.phone ): ""}}<br/>
                                    <i class="icon icon-plane"></i> <a v-if="infoSeller" :href="'mailto:'+infoSeller.email">{{infoSeller.email}}</a>
                                </div>

                                <div v-if="item.extra && infoCommercial != null" :class="{ 'd-md-inline-block infosContact': item.extra }" id="infoCom">
                                    <span class="text-white">Correspondant ADV</span><br>
                                    <b>{{infoCommercial ? infoCommercial.firstname : ""}} {{infoCommercial ? infoCommercial.lastname : ""}}</b><br/>
                                    <i class="icon icon-telephone"></i> {{infoCommercial ? ((infoCommercial.mobile!="") ? infoCommercial.mobile : infoCommercial.phone ) : ""}}<br/>
                                    <i class="icon icon-plane"></i> <a v-if="infoCommercial" :href="'mailto:'+infoCommercial.email">{{infoCommercial.email}}</a>
                                </div>

                                <div v-if="item.extra && isSeller=='false'" :class="{ 'infosContact': item.extra}" id="infoServiceClient">
                                    <span class="text-white">Service client</span><br>
                                    <b>Dorothée Gillot-Chevalier</b><br/>
                                    <i class="icon icon-telephone"></i> 02 40 33 56 56<br/>
                                    <i class="icon icon-plane"></i> <a :href="'mailto:contact@minco.fr'">contact@minco.fr</a>
                                </div>

<!--                                <div v-if="item.extra" :class="{ 'infosContact': item.extra}" id="infoExpeditionService">-->
<!--                                    <span class="text-white">Service expéditions</span><br>-->
<!--                                    <b>Maud Jarny</b><br/>-->
<!--                                    <i class="icon icon-telephone"></i> 02 40 33 58 39<br/>-->
<!--                                    <i class="icon icon-plane"></i> <a :href="'mailto:maud.jarny@minco.fr'">maud.jarny@minco.fr</a>-->
<!--                                    <br/><br/>-->
<!--                                    <b>Olivier Grollier</b><br/>-->
<!--                                    <i class="icon icon-plane"></i> <a :href="'mailto:olivier.grollier@minco.fr'">olivier.grollier@minco.fr</a>-->
<!--                                </div>-->
                              <div v-if="item.extra && infoAssistant != null" :class="{ 'infosContact': item.extra}" id="infoExpeditionService">
                                    <span class="text-white">Service expéditions</span><br>
                                    <b>{{infoAssistant ? infoAssistant.firstname : ""}} {{infoAssistant ? infoAssistant.lastname : ""}}</b><br/>
                                    <i class="icon icon-telephone"></i> {{infoAssistant ? ((infoAssistant.mobile!="") ? infoAssistant.mobile : infoAssistant.phone ) : ""}}<br/>
                                    <i class="icon icon-plane"></i> <a v-if="infoAssistant" :href="'mailto:'+infoAssistant.email">{{infoAssistant.email}}</a>
                                </div>

                              <div v-if="item.extra" :class="{ 'infosContact': item.extra}" id="serviceAccopagnement">
                                <span class="text-white">Service d’accompagnement technique </span><br>
                                <b>Bertrand Figureau</b><br/>
                                <i class="icon icon-telephone"></i> 06 60 20 02 22<br/>
                                <i class="icon icon-plane"></i> <a :href="'mailto:bertrand.figureau@minco.fr'">bertrand.figureau@minco.fr</a>r
                              </div>

                                <!--<div v-if="item.extra" :class="{ 'infosContact': item.extra }">-->
                                    <!--<span class="text-white ">Service SAV &amp; pièces détachées</span><br/>-->
                                    <!--<i class="icon icon-plane"></i> <a href="mailto:minco.piecesdetachees@minco-sa.fr">minco.piecesdetachees@minco-sa.fr</a>-->
                                <!--</div>-->
                            </div>

                        </div>

                    </a>
                    <span v-if="item.children" class="pointer minco-sidebar__link minco-sidebar__link--arrow">
                        <div :class="{ 'text-white text-uppercase': item.extra }" @click="toggleDropdown(index)">
                            <div class="minco-sidebar__icon text-center">
                                <i :class="'icon ' + item.icon"></i>
                            </div>
                            <span class="minco-sidebar__label pr-5" v-html="item.label"></span>
                        </div>
                      </span>
                    <ul v-if="item.children" class="minco-sidebar__sub-items list-unstyled" :class="{ 'minco-sidebar__sub-items--opened': showDropdowns[index] }">
                        <li class="minco-sidebar__sub-item" v-for="(subItem, i) in item.children" :key="i">

                            <a v-if="subItem.url.includes('downloads')" download class="minco-sidebar__sub-link"  :class="{ 'minco-sidebar__sub-link--active': isChildOf(currentItem.url, subItem.url) }" :href="subItem.url"  :target="subItem.target ? subItem.target : '_self'"><span  v-html="subItem.label"></span></a>
                            <a v-else-if="subItem.url.includes('https://sav.minco.fr')" :href="subItem.url" target="_blank"  class="minco-sidebar__sub-link" ><span >Mon SAV</span></a>
<!--                          <form v-else-if="subItem.url.includes('https://preprod-sav.minco.fr')" :action="subItem.url" method="get" target="_blank">-->
<!--                                  <input type="hidden" name="email" :value="subItem.email" />-->
<!--                                  <input type="hidden" name="jwt" :value="subItem.jwt" />-->
<!--                                  <input class="minco-sidebar__button-form" type="submit"  value="Mon SAV" />-->
<!--                            </form>-->
                            <a v-else class="minco-sidebar__sub-link"  :class="{ 'minco-sidebar__sub-link--active': isChildOf(currentItem.url, subItem.url) }" :href="subItem.url"  :target="subItem.target ? subItem.target : '_self'" ><span v-html="subItem.label"></span></a>

                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: 'AppSidebar',
        props: {
            navItems: {
                type: Array
            },
            currentItem: {
                type: Object
            },
            codeCommercial:
                {
                    type: String
                },
            codeSeller:
                {
                    type: String
                },
            urlInfosContact:
                {
                    type: String
                },
            userAdmin: {
                type: Number
            },
            isSeller: {
                type: String
            },
            codeAssistant:
            {
              type: String
            },
        },
        data: function () {
            return {
                openMenu: false,
                showDropdowns: [],
                infoCommercial: null,
                infoSeller: null,
                infoAssistant: null
            }
        },
        created: function () {
            this.showDropdowns = [];
            for (let i = 0; i < this.navItems.length; i++) {
                this.showDropdowns[i] = false;
            }
        },
        mounted: function () {
            this.$root.$on('menu-state', this.listenToMenuState);
            let data = new FormData();
            data.append("codeCommercial", this.codeCommercial);
            data.append("codeSeller", this.codeSeller);
            data.append("codeAssistant", this.codeAssistant);
            axios.post(this.urlInfosContact, data).then(response=>{
                //console.log(response.data)
                let retour = response.data;
                this.infoCommercial = retour.Commercial;
                this.infoSeller = retour.Seller;
                this.infoAssistant = retour.Assistant;

            }).catch(error => {
                console.warn(error);
            });
        },
        beforeDestroy: function () {
            this.$root.$off('menu-state', this.listenToMenuState);
        },
        methods: {
            listenToMenuState: function (event) {
                this.openMenu = event;
            },
            toggleDropdown(index) {
                this.showDropdowns = [];
                this.showDropdowns[index] = true;
            },
            isChildOf(child, parent) {
                return child.indexOf(parent) == 0
            },
            asChildActivated(item) {
                if (item.url == this.currentItem.url) {
                    return true;
                }
                if (item.children) {
                    for (let i = 0; i < item.children.length; i++) {
                        if (this.isChildOf(this.currentItem.url, item.children[i].url)) {
                            return true;
                        }
                    }
                }

                return false;
            },
            manageClick(event, item) {
              if (item.edit) {
                event.preventDefault();
                var editor = ContentTools.EditorApp.get();
                editor.domElement().classList.add('ct-app--enabled');
              }

              if (item.extra) {
                event.preventDefault();
              }
            }
        }
    }
</script>
