<template>
    <div class="row pt-4 justify-content-center new-project__content__body" v-cloak>
        <div class="col-md-12 quote__order">
            <div class="row quote__order__intro">
                <slot name="orderIntro"></slot>
            </div>

            <div class="row" v-if="projectStatus == 0 || projectStatus == 4 || projectStatus == 6 || projectStatus == 8 || projectStatus == 10">
                <div class="my-3 col-12">
                  <div class="form-check">
                    <input class="form-check-input" ref="chkCGV" type="checkbox" id="cgv" />
                      <label class="form-check-label" ref="lblCGV" for="cgv"><a target="_blank" href="/conditions-generales-de-vente/"><slot name="cgv"></slot></a></label>
                  </div>
                </div>
            </div>
            <div class="row text-center" v-if="projectStatus == 0 ||  projectStatus == 4 || projectStatus == 6 || projectStatus == 8 || projectStatus == 10">
                <!-- <div class="col-md-4 col-lg-3">
                    <button class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                        <slot name="saveLater"></slot>
                    </button>
                </div>
                <div class="col-md-4 col-lg-3 mt-3 mt-md-0">
                    <button @click="toggleCommande" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                        <slot name="saveNow"></slot>
                    </button>
                </div> -->
                <div class="col mt-3 mt-md-0">
                    <button @click="toggleCommande" class="w-25 btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                        <slot name="saveNow"></slot>
                    </button>
                </div>
            </div>
        </div>

        <b-modal centered v-model="openAskOrderModal" size="lg" title="Récapitulatif de votre commande">
            <div >
                <div class="row">
                    <div class="col" id="tabListItems">
                      <b-table stacked="md" id="listItems" class="project-table simple-table mb-0 " :items="quoteItems"  :fields="fields" primary-key="id">

                          <template slot="landmark" slot-scope="row" v-if="row.item.origin !== 2">
                              <div v-if="row.item.origin !== 2">
                                  {{ row.item.landmark }}
                              </div>
                          </template>
                          <template slot="carpentry" slot-scope="row" v-if="row.item.origin !== 2">
                              <div v-if="row.item.origin < 1" class="project-table__small">
                                  <span v-for="desc in row.item.summaryConfiguration">
                                       <span>
                                          <b>{{desc.label}}</b> : {{desc.value}}<br/>
                                       </span>
                                  </span>
                                  <br>
                                  <span class="small" v-if="row.item.origin === 1">{{ row.item.importedQuote }}</span>
                              </div>
                              <div v-if="row.item.origin === 2" class="input-group">
                                  {{ row.item.landmark }}
                              </div>
                              <div v-if="row.item.origin === 1" class="input-group">
                                  {{ row.item.carpentry }}<br/>
                                  {{ row.item.importedQuote }}
                              </div>
                          </template>
                          <template slot="quantity" slot-scope="row" v-if="row.item.origin !== 2">
                              <div class="input-group input-group--small">
                                  {{row.item.quantity}}
                              </div>
                          </template>
                          <template slot="purchasingPrice" slot-scope="row" v-if="row.item.origin !== 2">
                              <div v-if="row.item.origin < 2" class="">
                                  {{ row.item.purchasingPriceExTax|toFixed|formatPrice }}
                              </div>
                          </template>
                      </b-table>
                  </div>
                </div>
                <div class="row justify-content-center my-4">
                  <div>Ma commande est complète et ne nécessite aucune mise au point particulière<br/>(conformité norme PMR, ajout de châssis techniques, d’accessoires, etc…)</div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 col-lg-3">
                        <button type="button"  @click="printTable" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed"> IMPRIMER </button>
                    </div>
                    <div class="col-md-4 col-lg-3">
                        <!--<button type="button"   @click="completeOrder" class="w-100 btn btn-rounded btn-rounded&#45;&#45;white btn-rounded&#45;&#45;shadowed"> OUI </button>-->
                        <button type="button"   @click="openConfirmOrder" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed"> OUI </button>
                    </div>
                    <div class="col-md-4 col-lg-3">
                        <button type="button"  @click="showOrderComplements" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed"> NON </button>
                    </div>
                </div>
            </div>
            <div slot="modal-footer">

            </div>
        </b-modal>
        <b-modal centered v-model="openOrderComplementsModal" size="lg">
            <div>
                <span ><strong>Indiquez les points à compléter ou à vérifier par votre correspondant ADV</strong></span><br/><br/>
                <div class="row">
                    <div class="col">
                        <label for="chkDelaisSpec"><input type="checkbox" v-model="chkDelaisSpec" @change="checkedAddItem($event)" id="chkDelaisSpec"/> &nbsp; Délai particulier spécifié : </label><br/><br/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 col-lg-5">
                      <span :class="{ 'text-danger': incompleteFormErrors['txtDelaisSpec'], 'has-error': incompleteFormErrors['txtDelaisSpec']}">
                          Indiquer le délai souhaité :<br/>
                        <textarea rows="3" cols="35" v-model="txtDelaisSpec"></textarea>
                      </span>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col">
                        <label for="chkPoigneePMR"><input type="checkbox" v-model="chkPoigneePMR" @change="checkedAddItem($event)" id="chkPoigneePMR"/> Vérifier conformité des hauteurs poignées à la norme PMR </label><br/><br/>
                    </div>
                </div>
            </div>
                <hr/>
                <div class="row">
                    <div class="col">
                      <label for="chkMisePointTechnique"><input type="checkbox" @change="checkedAddItem($event)" v-model="chkMisePointTechnique" id="chkMisePointTechnique" /> &nbsp; Mise au point Technique</label><br/><br/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-3">
                      <span :class="{ 'text-danger': incompleteFormErrors['txtMisePointTechnique'], 'has-error': incompleteFormErrors['txtMisePointTechnique']}">
                        Précisez:<br/>
                        <textarea rows="3" cols="35" v-model="txtMisePointTechnique"></textarea>
                      </span>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col">
                        <label for="chkNonConfigurable"><input type="checkbox" @change="checkedAddItem($event)" v-model="chkNonConfigurable" id="chkNonConfigurable"/> &nbsp; Repère(s) non configurable(s)</label><br/><br/>
                    </div>
                </div>
                <div class="row" v-for="(nonConfigurableItem,index) in nonConfigurableItems">
                    <div class="col-md-4 col-lg-5">
                      <span :class="{ 'text-danger': incompleteFormErrors['nomNonConfigurable'+index], 'has-error': incompleteFormErrors['nomNonConfigurable'+index]}">
                        Nom du repère* :<br/>
                            <input type="text" ref="nomNonConfigurable" :value="nonConfigurableItem.num"  />
                      </span>
                        <br><br>
                        <span :class="{ 'text-danger': incompleteFormErrors['txtNonConfigurable'+index], 'has-error': incompleteFormErrors['txtNonConfigurable'+index]}">
                          Précisez:<br/>
                            <textarea rows="3" cols="35" ref="txtNonConfigurable" :value="nonConfigurableItem.txt"></textarea>
                        </span>
                    </div>
                    <div class="col-md-4 col-lg-5">
                      <span :class="{ 'text-danger': incompleteFormErrors['fileNonConfigurable'+index], 'has-error': incompleteFormErrors['fileNonConfigurable'+index]}">
                        Joindre un pdf (5 Mo maxi):<br/>
                        <label class="input-group-btn">
                                     <span class="btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                                       Parcourir <input type="file" ref="fileNonConfigurable" id="docFileName"  @change="onFileInput" style="display: none;">
                                     </span>
                        </label>
                      </span>
                    </div>
                    <div class="col-md-1 col-lg-1">
                        <button type="button" @click="deleteNonConfigurableItem(index)"> X </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-4">
                        <button @click="toogleAddNonConfigurableItem" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                            Ajouter un repère
                        </button>
                    </div>
                </div>
                <hr />
                <div class="row" >
                    <div class="col">
                        <label for="chkDevisComplementaire"><input type="checkbox" @change="checkedAddItem($event)" v-model="chkDevisComplementaire" id="chkDevisComplementaire" /> &nbsp; Repère(s) ayant fait l'objet d'un devis par commercial et non intégré</label><br/><br/>
                    </div>
                </div>
                <div class="row" v-for="(devisComplementaire,index) in devisComplementaires">
                    <div class="col-md-4 col-lg-5">
                        <span :class="{ 'text-danger': incompleteFormErrors['nomDevisComplementaire'+index], 'has-error': incompleteFormErrors['nomDevisComplementaire'+index]}">
                          N° de Devis* : <br/>
                          <input type="text" ref="nomDevisComplementaire" required="required" :value="devisComplementaire.num"  /><br><br>
                        </span>
                        <span :class="{ 'text-danger': incompleteFormErrors['txtDevisComplementaire'+index], 'has-error': incompleteFormErrors['txtDevisComplementaire'+index]}">
                          Précisions<br/>
                          <textarea rows="3" cols="35" ref="txtDevisComplementaire" :value="devisComplementaire.txt" ></textarea>
                        </span>
                    </div>
                    <div class="col-md-4 col-lg-5">
                      <span :class="{ 'text-danger': incompleteFormErrors['fileDevisComplementaire'+index], 'has-error': incompleteFormErrors['fileDevisComplementaire'+index]}">
                        Joindre un pdf (5 Mo maxi):<br/>
                        <label class="input-group-btn">
                                         <span class="btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                                           Parcourir <input type="file" id="docDevisComplementaire" ref="fileDevisComplementaire" @change="onFileInput" style="display: none;">
                                         </span>
                        </label>
                      </span>
                    </div>
                    <div class="col-md-1 col-lg-1">
                        <button type="button" @click="deleteDevisComplementaireItem(index)"> X </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-4">
                        <button @click="toogleAddDevisComplementaireItem" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                            Ajouter un repère
                        </button>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col">
                        <label for="chkAccessoire"><input type="checkbox" @change="checkedAddItem($event)" v-model="chkAccessoire" id="chkAccessoire"/> &nbsp; Accessoire complémentaire à ajouter</label><br/><br/>
                    </div>
                </div>
                <div class="row" v-for="(accessoire,index) in accessoireComplementaires">
                    <div class="col-md-4 col-lg-3">
                      <span :class="{ 'text-danger': incompleteFormErrors['accessoireComplementaireNom'+index], 'has-error': incompleteFormErrors['accessoireComplementaireNom'+index]}">
                            Désignation* :<br/>
                            <input type="text" ref="accessoireComplementaireNom" :value="accessoire.nom"  />
                      </span>
                    </div>
                    <div class="col-md-3 col-lg-3">
                        Longueur (mm)<br/><input class="w-50" ref="accessoireComplementaireLongueur" type="number" :value="accessoire.longueur" />
                    </div>
                    <div class="col-md-3 col-lg-2">
                        Largeur (mm)<br/><input class="w-50" ref="accessoireComplementaireLargeur" type="number" :value="accessoire.largeur">
                    </div>
                    <div class="col-md-3 col-lg-2">
                        Quantité<br/><input class="w-50" ref="accessoireComplementaireQuantite" type="number" :value="accessoire.qte">
                    </div>
                    <div class="col-md-1 col-lg-1">
                        <button type="button" @click="deleteAccessoireItem(index)"> X </button>
                    </div>
                </div>
                <br>
                <div class="col-md-4 col-lg-4">
                    <button @click="toogleAddAccessoireItem" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                        Ajouter un accessoire
                    </button>
                </div>
                <hr/>
                <!--<div class="row">-->
                    <!--<div class="col">-->
                        <!--<label for="chkAdressLivraison"><input type="checkbox" @change="checkedAddItem($event)" v-model="chkAdressLivraison" id="chkAdressLivraison"/> &nbsp; Adresse de livraison particulière</label><br/><br/>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="row">-->
                    <!--<div class="col-md-4 col-lg-4">-->
                      <!--<span :class="{ 'text-danger': incompleteFormErrors['adressLivraison'], 'has-error': incompleteFormErrors['adressLivraison']}">-->
                        <!--Adresse* :<br/>-->
                        <!--<input type="text" v-model="adressLivraison" /><br/><br/>-->
                      <!--</span>-->
                        <!--Complément d'adresse :<br/>-->
                        <!--<input type="text" v-model="complementLivraison"/><br/><br/>-->
                      <!--<span :class="{ 'text-danger': incompleteFormErrors['zipcodeLivraison'], 'has-error': incompleteFormErrors['zipcodeLivraison']}">-->
                        <!--Code Postal* :<br/>-->
                        <!--<input type="text" v-model="zipcodeLivraison"/>-->
                      <!--</span>-->
                        <!--<br/><br/>-->
                      <!--<span :class="{ 'text-danger': incompleteFormErrors['cityLivraison'], 'has-error': incompleteFormErrors['cityLivraison']}">-->
                        <!--Ville* :<br/>-->
                        <!--<input type="text" v-model="cityLivraison"/>-->
                      <!--</span>-->
                    <!--</div>-->
                    <!--<div class="col-md-4 col-lg-4">-->
                      <!--<span :class="{ 'text-danger': incompleteFormErrors['txtLivraison'], 'has-error': incompleteFormErrors['txtLivraison']}">-->
                        <!--Précisions<br/>-->
                        <!--<textarea rows="3" cols="35" v-model="txtLivraison"></textarea>-->
                      <!--</span>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<hr/>-->
                <div class="row">
                    <div class="col">
                        <label for="chkAutre"><input type="checkbox" v-model="chkAutre" @change="checkedAddItem($event)" id="chkAutre"/> &nbsp; Autres points techniques à prendre en compte : </label><br/><br/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 col-lg-5">
                      <span :class="{ 'text-danger': incompleteFormErrors['txtAutre'], 'has-error': incompleteFormErrors['txtAutre']}">
                        Précisions<br/>
                        <textarea rows="3" cols="35" v-model="txtAutre"></textarea>
                      </span>
                    </div>
                    <div class="col-md-4 col-lg-5">
                      <span :class="{ 'text-danger': incompleteFormErrors['docAutre'], 'has-error': incompleteFormErrors['docAutre']}">
                        Joindre un pdf (5 Mo maxi):<br/>
                        <label class="input-group-btn">
                         <span class="btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                           Parcourir <input type="file" id="docAutre" ref="docAutre" @change="onFileInput" style="display: none;">
                         </span>
                        </label>
                      </span>
                    </div>
                </div>
            <div slot="modal-footer">
                <div class="row">
                    <div class="col-md-4 col-lg-3">
                        <button type="button"  @click="validIncompleteOrder" class="btn btn-rounded btn-rounded--white btn-rounded--shadowed"> Valider </button>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal centered v-model="openInitModal" size="lg">
            <div class="text-center" v-if="errorIniWinPro.length>0 && errorIniWinPro!=false">
                <span style="display:inherit;" v-for="error in errorIniWinPro">{{error}}</span><br/>
            </div>
            <div class="text-center" v-if="(errorIniWinPro.length==0 || errorIniWinPro == false) && errorValidationOrder == -1">
                <span style="display:inherit;" >Votre commande a bien été enregistrée.<br/>Vous recevrez un ARC dans les 24h et vous bénéficierez de 48h pour la modifier.<br/> Aucun bon pour accord ne vous sera demandé avant lancement fabrication</span><br/>
            </div>
            <div class="text-center" v-if="errorValidationOrder >0">
                <span style="display:inherit;" v-if="errorValidationOrder ==1">Votre commande a bien été enregistrée.<br/>Vous recevrez un ARC dans les 24h et vous bénéficierez de 48h pour la modifier.<br/> Aucun bon pour accord ne vous sera demandé avant lancement fabrication</span><br/>
                <span style="display:inherit;" v-if="errorValidationOrder ==2">Une erreur est survenue dans le processus du passage de commande, veuillez réessayer ultérieurement.<br />Si le problème persiste veuillez contacter l'administrateur.</span><br/>
            </div>
            <div slot="modal-footer">

            </div>
        </b-modal>

        <b-modal centered v-model="openConfirmCommand" size="lg">
            <div>
                <div class="row">
                    <div class="col-12 text-center" >
                        <span style="display:inherit;" >Vous êtes sur le point de passer votre commande. Etes-vous sûr ?</span><br/>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="offset-md-2 offset-lg-3 col-md-4 col-lg-3">
                        <button type="button"   @click="completeOrder" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed"> OUI </button>
                    </div>
                    <div class="col-md-4 col-lg-3">
                        <button type="button"  @click="openConfirmCommand= false" class="w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed"> NON </button>
                    </div>
                </div>
            </div>
            <div slot="modal-footer">

            </div>
        </b-modal>

    </div>
</template>

<script>
    import axios from 'axios';
    import { en, fr } from 'vuejs-datepicker/dist/locale';
    import { EventBus } from '../../event';
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: "app-project-accordeon-item-four",
        props: {
            submitLabel: {
                type: String
            },
            project: {
                type: Object
            },
            apiProjectProcessOrderUrl: {
                type: String
            },
            apiProjectProcessOrderIncompleteUrl: {
                type: String
            },
            tableLandmarkLabel: {
                type: String
            },
            tableCarpentryLabel: {
                type: String
            },
            tableQuantityLabel: {
                type: String
            },
            tablePurchasingPriceLabel: {
                type: String
            },
            urlCss: {
                type: String
            }
        },
        components: {
            Datepicker: Datepicker,
        },
        data: function () {
            return {
                orderDate : new Date(),
                fr: fr,
                en: en,
                projectId: 0,
                projectStatus: 0,
                quoteId: 0,
                quoteItems: [],
                errorIniWinPro: [],
                openInitModal : false,
                openAskOrderModal:false,
                responseCompleteOrder: 1,
                openOrderComplementsModal: false,
                chkMisePointTechnique: 0,
                txtMisePointTechnique:"",
                chkNonConfigurable:0,
                txtNonConfigurable:"",
                fileNonConfigurable:"",
                chkDevisComplementaire:0,
                devisComplementaireNum:"",
                txtDevisComplementaire:"",
                chkAccessoire:0,
                designationAccessoire:"",
                dimensionsAccessoire:0,
                largeurAccessoire: 0,
                qteAccessoire:0,
                chkAdressLivraison: 0,
                adressLivraison: "",
                complementLivraison: "",
                zipcodeLivraison: "",
                cityLivraison:"",
                txtLivraison:"",
                chkAutre:0,
                txtAutre:"",
                nonConfigurableItems: [],
                devisComplementaires: [],
                accessoireComplementaires:[],
                errorValidationOrder : -1,
                fields: [],
                vat: [
                    { label: '5,5 %', value: 5.5 },
                    { label: '10 %', value: 10 },
                    { label: '20 %', value: 20 }
                ],
                incompleteFormErrors: [],
                openConfirmCommand: false,
                chkDelaisSpec: 0,
                txtDelaisSpec: "",
                chkPoigneePMR: 0,
            }
        },
        created:function()
        {
            console.log(this.nonConfigurableItems);
            this.nonConfigurableItems.push({
                num: "",
                txt: "",
                file: ""
            });
            this.devisComplementaires.push({
                num: "",
                txt: "",
                file: ""
            });
            this.accessoireComplementaires.push({
                nom: "",
                longueur: "0",
                largeur:"0",
                qte:"1"
            });
        },
        mounted:function(){
            if (this.project["id"] !== null) {
              this.projectId = this.project["id"];
              this.projectStatus = this.project["status"];
              let quote = this.project.quotes[this.project.quotes.length - 1];
              this.quoteId = quote.id;
                for (let item of quote.items) {
                  if (item.origin != 2) {
                      this.quoteItems.push(
                          {
                              id: item.id,
                              landmark: item.landmark,
                              carpentry: item.carpentry,
                              quantity: item.quantity,
                              purchasingPriceExTax: (item.purchasingPriceExTax / 100),
                              marginRatio: item.marginRatio,
                              laying: (item.laying !== 0) ? item.laying / 100 : 0,
                              vat: item.vat,
                              sellingPrice: (item.sellingPrice / 100),
                              configurationDetails: item.configurationDetails,
                              configurationXML: item.configurationXML,
                              configurationXMLFile : item.configurationXMLFile,
                              importedQuote: item.importedQuote,
                              origin: item.origin,
                              rank: item.rank,
                              summaryConfiguration: ((item.summaryConfiguration!="") ? JSON.parse(item.summaryConfiguration) : "")
                              //summaryConfiguration: item.summaryConfiguration
                          }
                      );
                  }
                }
          }
            this.populateFields();
            EventBus.$on('project-details', this.getProjectDetails);
            EventBus.$on('refresh-items', this.refreshProjectItems);

        },
        methods: {
            populateFields:function() {
                this.fields.push(
                    { key: 'landmark', label: this.tableLandmarkLabel, sortable: false },
                    { key: 'carpentry', label: this.tableCarpentryLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'quantity', label: this.tableQuantityLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'purchasingPrice', label: this.tablePurchasingPriceLabel, sortable: false, tdClass: this.alignTop() }
//                    { key: 'ratio', label: this.tableRatioLabel, sortable: false, tdClass: this.alignTop() },
//                    { key: 'laying', label: this.tableLayingLabel, sortable: false, tdClass: this.alignTop() },
//                    { key: 'sellingPrice', label: this.tableSellingPriceLabel, sortable: false, tdClass: this.alignTop() },
//                    { key: 'vat', label: this.tableVatLabel, sortable: false, tdClass: this.alignTop() },
//                    { key: 'action', label: this.tableActionLabel, sortable: false, tdClass: this.alignTop() }

                );
            },
            toggleCommande: function () {
                if(this.$refs["chkCGV"].checked)
                {
                    this.openAskOrderModal = true;
                    this.$refs["lblCGV"].removeAttribute("class");
                    this.$refs["lblCGV"].setAttribute("class","form-check-label");
                }
                else
                {
                    this.$refs["lblCGV"].setAttribute("class","form-check-label text-danger")
                }
            },
            completeOrder: function()
            {
                this.$root.$emit('set-loading', true);

                    axios.post(this.apiProjectProcessOrderUrl + "/" + this.projectId)
                        .then((response) => {
                            if (response.request.responseURL.includes("connexion")) {
                                location.href = response.request.responseURL;
                            }
                            else {
                                console.log(response.data);
                                this.errorIniWinPro = response.data;
                                this.openInitModal = true;
                                if (response.data === false) {
                                    this.project["status"] = 2;
                                    EventBus.$emit('update-status', this.project["status"]);
                                }
                                this.$root.$emit('set-loading', false);
                            }
                        })
            },
            openConfirmOrder: function()
            {
                this.openConfirmCommand = true;
            },
            getProjectDetails: function(event) {
                this.projectId = event.project.id;
                this.projectStatus = event.project.status;
                this.quoteId = event.id;
            },
            refreshProjectItems: function(items) {
                this.quoteItems = items;

                this.filterByRef();
            },
            filterByRef : function()
            {
                    this.quoteItems = this.quoteItems.filter((item)=>{
                        return item.origin <2 ;
                    });

            },
            showOrderComplements: function()
            {
                this.openAskOrderModal = false;
                this.openOrderComplementsModal = true;

            },
            onFileInput:function()
            {

            },
            validIncompleteOrder: function()
            {
                let data = new FormData();
                let arrayFile = [];
                let arrayFileDevis = [];
                this.incompleteFormErrors = [];
                let errorCount = 0;
                if(this.chkMisePointTechnique) {
                  // if (this.txtMisePointTechnique.length == 0) {
                  //   this.incompleteFormErrors['txtMisePointTechnique'] = 'mandatory';
                  //   errorCount++
                  // }
                  data.append("txtMisePointTechnique", this.txtMisePointTechnique);
                }

                if(this.chkNonConfigurable)
                {
                    let newNonConfigurableItems = [];

                    for(let index =0; index < this.$refs.nomNonConfigurable.length; index++)
                    {
                      if (this.$refs.nomNonConfigurable[index].value.length == 0) {
                        this.incompleteFormErrors['nomNonConfigurable'+index] = 'mandatory';
                        errorCount++
                      }

                      // if (this.$refs.nomNonConfigurable[index].value.length == 0) {
                      //   this.incompleteFormErrors['txtNonConfigurable'+index] = 'mandatory';
                      //   errorCount++
                      // }
                      //
                      // if (this.$refs.fileNonConfigurable[index].value.length == 0) {
                      //   this.incompleteFormErrors['fileNonConfigurable'+index] = 'mandatory';
                      //   errorCount++
                      // }

                        newNonConfigurableItems.push(
                            {
                                num: this.$refs.nomNonConfigurable[index].value,
                                txt : this.$refs.txtNonConfigurable[index].value,
                                file: this.$refs.fileNonConfigurable[index].value,
                            }
                        );
                    }
                    this.nonConfigurableItems=newNonConfigurableItems;

                    data.append('arrayNonConfigurable', JSON.stringify( this.nonConfigurableItems));

                    for(let file of this.$refs.fileNonConfigurable)
                    {
                      if (file.files[0]) {
                        arrayFile.push({
                            "file":file.files[0],
                            "name":file.files[0].name
                        });
                      }
                    }
                    arrayFile.forEach(function (element, index) {
                        data.set("filesNonConfigurable"+index,element["file"],element["name"]);
                    });
                }
                if(this.chkDevisComplementaire)
                {

                    let newDevisComplemtairesItems = [];

                    for(let index =0; index < this.$refs.nomDevisComplementaire.length; index++)
                    {

                        if (this.$refs.nomDevisComplementaire[index].value.length == 0) {
                          this.incompleteFormErrors['nomDevisComplementaire'+index] = 'mandatory';
                          errorCount++
                        }

                        // if (this.$refs.txtDevisComplementaire[index].value.length == 0) {
                        //   this.incompleteFormErrors['txtDevisComplementaire'+index] = 'mandatory';
                        //   errorCount++
                        // }
                        //
                        // if (this.$refs.fileDevisComplementaire[index].value.length == 0) {
                        //   this.incompleteFormErrors['fileDevisComplementaire'+index] = 'mandatory';
                        //   errorCount++
                        // }

                        newDevisComplemtairesItems.push(
                            {
                                num: this.$refs.nomDevisComplementaire[index].value,
                                txt : this.$refs.txtDevisComplementaire[index].value,
                                file: this.$refs.fileDevisComplementaire[index].value,
                            }
                        );
                    }
                    this.devisComplementaires=newDevisComplemtairesItems;
                    data.append("arrayDevisComplementaire",JSON.stringify(this.devisComplementaires));
                    for(let file of this.$refs.fileDevisComplementaire)
                    {
                      if (file.files[0]) {
                        arrayFileDevis.push({
                            "file":file.files[0],
                            "name":file.files[0].name
                        });
                      }
                    }
                    arrayFileDevis.forEach(function (element, index) {
                        data.set("fileDevisComplementaire"+index,element["file"],element["name"]);
                    });
                }
                if(this.chkAccessoire)
                {
                    let newAccessoiresItems = [];

                    for(let index =0; index < this.$refs.accessoireComplementaireNom.length; index++)
                    {
                        if (this.$refs.accessoireComplementaireNom[index].value.length == 0) {
                          this.incompleteFormErrors['accessoireComplementaireNom'+index] = 'mandatory';
                          errorCount++
                        }

                        newAccessoiresItems.push(
                            {
                                nom: this.$refs.accessoireComplementaireNom[index].value,
                                longueur : this.$refs.accessoireComplementaireLongueur[index].value,
                                largeur: this.$refs.accessoireComplementaireLargeur[index].value,
                                qte: this.$refs.accessoireComplementaireQuantite[index].value
                            }
                        );
                    }
                    this.accessoireComplementaires=newAccessoiresItems;
                    data.append("arrayAccessoire",JSON.stringify(this.accessoireComplementaires));
                }
//                if(this.chkAdressLivraison)
//                {
//                    if (this.adressLivraison.length == 0) {
//                      this.incompleteFormErrors['adressLivraison'] = 'mandatory';
//                      errorCount++
//                    }
//
//                    if (this.zipcodeLivraison.length == 0) {
//                      this.incompleteFormErrors['zipcodeLivraison'] = 'mandatory';
//                      errorCount++
//                    }
//
//                    if (this.cityLivraison.length == 0) {
//                      this.incompleteFormErrors['cityLivraison'] = 'mandatory';
//                      errorCount++
//                    }
//
//                    data.append("adressLivraison",this.adressLivraison);
//                    data.append("complementLivraison",this.complementLivraison);
//                    data.append("zipcodeLivraison",this.zipcodeLivraison);
//                    data.append("cityLivraison",this.cityLivraison);
//                    data.append("txtLivraison",this.txtLivraison);
//                }
                if(this.chkAutre)
                {
                    // if (this.txtAutre.length == 0) {
                    //   this.incompleteFormErrors['txtAutre'] = 'mandatory';
                    //   errorCount++
                    // }
                    //
                    // if (!this.$refs.docAutre.files[0]) {
                    //   this.incompleteFormErrors['docAutre'] = 'mandatory';
                    //   errorCount++
                    // }

                    data.append("txtAutre",this.txtAutre);

                    if (this.$refs.docAutre.files[0]) {
                      data.set('autreFile', this.$refs.docAutre.files[0], this.$refs.docAutre.files[0].name);
                      data.append('autreName', this.$refs.docAutre);
                    }
                }
                if(this.chkDelaisSpec)
                {
                    data.append("delaisSpec",this.txtDelaisSpec);
                }
                if(this.chkPoigneePMR)
                {
                    data.append("poigneePMR",1);
                }

                if (errorCount == 0) {
                  const config = {
                      headers: { 'content-type': 'multipart/form-data' }
                  }

                  data.append('_method', 'post' );

                  this.$root.$emit('set-loading', true);
                  axios.post(this.apiProjectProcessOrderIncompleteUrl+"/"+this.projectId, data, config)
                      .then(response=>{
                        this.$root.$emit('set-loading', false);
                          this.error = response.data;
//                          if(this.error == "0")
//                          {
//                              this.errorValidationOrder = 1;
//                          }
//                          else
//                          {
//                              this.errorValidationOrder = 2;
//                          }
                          //this.id=response.data.id;
                          console.log(response.data);
                          this.errorIniWinPro = response.data;
                          this.openInitModal = true;
                          if (response.data === false) {
                              this.project["status"] = 2;
                              EventBus.$emit('update-status', this.project["status"]);
                          }
                          this.$root.$emit('set-loading', false);
                      })
                      .catch(error=>{
                        this.$root.$emit('set-loading', false);
                          console.warn(error);
                      });
                }

            },
            checkedAddItem: function(event)
            {
                console.log(this.$refs.fileNonConfigurable);
                console.log(this.nonConfigurableItems);
                let newNonConfigurableItems = [];
                if(this.$refs.nomNonConfigurable)
                {
                    for(let index =0; index < this.$refs.nomNonConfigurable.length; index++)
                    {

                        newNonConfigurableItems.push(
                            {
                                num: this.$refs.nomNonConfigurable[index].value,
                                txt : this.$refs.txtNonConfigurable[index].value,
                                file: this.$refs.fileNonConfigurable[index].value,
                            }
                        );
                    }
                }
                this.nonConfigurableItems=newNonConfigurableItems;

                let newDevisComplemtairesItems = [];

                for(let index =0; index < this.$refs.nomDevisComplementaire.length; index++)
                {

                    newDevisComplemtairesItems.push(
                        {
                            num: this.$refs.nomDevisComplementaire[index].value,
                            txt : this.$refs.txtDevisComplementaire[index].value,
                            file: this.$refs.fileDevisComplementaire[index].value,
                        }
                    );
                }

                this.devisComplementaires=newDevisComplemtairesItems;

                let newAccessoiresItems = [];

                for(let index =0; index < this.$refs.accessoireComplementaireNom.length; index++)
                {

                    newAccessoiresItems.push(
                        {
                            nom: this.$refs.accessoireComplementaireNom[index].value,
                            longueur : this.$refs.accessoireComplementaireLongueur[index].value,
                            largeur: this.$refs.accessoireComplementaireLargeur[index].value,
                            qte: this.$refs.accessoireComplementaireQuantite[index].value
                        }
                    );
                }

                this.accessoireComplementaires=newAccessoiresItems;
            },
            toogleAddNonConfigurableItem: function()
            {
                let newNonConfigurableItems = [];
                if(this.$refs.nomNonConfigurable)
                {
                    for(let index =0; index < this.$refs.nomNonConfigurable.length; index++)
                    {

                        newNonConfigurableItems.push(
                            {
                                num: this.$refs.nomNonConfigurable[index].value,
                                txt : this.$refs.txtNonConfigurable[index].value,
                                file: this.$refs.fileNonConfigurable[index].value,
                            }
                        );
                    }
                    newNonConfigurableItems.push(
                        {
                            num: "",
                            txt : "",
                            file: "",
                        }
                    );
                }
                else
                {
                    newNonConfigurableItems.push(
                        {
                            num: "",
                            txt : "",
                            file: "",
                        }
                    );
                }

                this.nonConfigurableItems=newNonConfigurableItems;

            },
            deleteNonConfigurableItem: function(index)
            {

                let newNonConfigurableItems = [];
                for(let index =0; index < this.$refs.nomNonConfigurable.length; index++)
                {

                    newNonConfigurableItems.push(
                        {
                            num: this.$refs.nomNonConfigurable[index].value,
                            txt : this.$refs.txtNonConfigurable[index].value,
                            file: this.$refs.fileNonConfigurable[index].value,
                        }
                    );
                }

                this.nonConfigurableItems=newNonConfigurableItems;


                this.nonConfigurableItems.splice(index,1);

            },
            toogleAddDevisComplementaireItem: function()
            {
                let newDevisComplemtairesItems = [];

                for(let index =0; index < this.$refs.nomDevisComplementaire.length; index++)
                {

                    newDevisComplemtairesItems.push(
                        {
                            num: this.$refs.nomDevisComplementaire[index].value,
                            txt : this.$refs.txtDevisComplementaire[index].value,
                            file: this.$refs.fileDevisComplementaire[index].value,
                        }
                    );
                }
                newDevisComplemtairesItems.push(
                    {
                        num: "",
                        txt : "",
                        file: "",
                    }
                );

                this.devisComplementaires=newDevisComplemtairesItems;

            },
            deleteDevisComplementaireItem: function(index)
            {
                let newDevisComplemtairesItems = [];

                for(let index =0; index < this.$refs.nomDevisComplementaire.length; index++)
                {

                    newDevisComplemtairesItems.push(
                        {
                            num: this.$refs.nomDevisComplementaire[index].value,
                            txt : this.$refs.txtDevisComplementaire[index].value,
                            file: this.$refs.fileDevisComplementaire[index].value,
                        }
                    );
                }
                this.devisComplementaires=newDevisComplemtairesItems;

                this.devisComplementaires.splice(index,1);
            },
            toogleAddAccessoireItem: function()
            {
                let newAccessoiresItems = [];

                for(let index =0; index < this.$refs.accessoireComplementaireNom.length; index++)
                {

                    newAccessoiresItems.push(
                        {
                            nom: this.$refs.accessoireComplementaireNom[index].value,
                            longueur : this.$refs.accessoireComplementaireLongueur[index].value,
                            largeur: this.$refs.accessoireComplementaireLargeur[index].value,
                            qte: this.$refs.accessoireComplementaireQuantite[index].value
                        }
                    );
                }
                newAccessoiresItems.push(
                    {
                        nom: "",
                        longueur: "0",
                        largeur:"0",
                        qte:"1"
                    }
                );

                this.accessoireComplementaires=newAccessoiresItems;

            },
            deleteAccessoireItem: function(index)
            {
                let newAccessoiresItems = [];

                for(let index =0; index < this.$refs.accessoireComplementaireNom.length; index++)
                {

                    newAccessoiresItems.push(
                        {
                            nom: this.$refs.accessoireComplementaireNom[index].value,
                            longueur : this.$refs.accessoireComplementaireLongueur[index].value,
                            largeur: this.$refs.accessoireComplementaireLargeur[index].value,
                            qte: this.$refs.accessoireComplementaireQuantite[index].value
                        }
                    );
                }
                this.accessoireComplementaires=newAccessoiresItems;

                this.accessoireComplementaires.splice(index,1);
            },
            calculateItemSellingPrice: function (purchasingPrice, marginRatio, laying) {
                if(marginRatio === 0 || marginRatio === null) {
                    return (parseFloat(purchasingPrice) + parseFloat(laying));
                } else {
                    return ((parseFloat(purchasingPrice) * parseFloat(marginRatio)) + parseFloat(laying));
                }
            },
            calculateCustomItemSellingPrice: function (quantity, sellingPrice) {
                return quantity * sellingPrice;
            },
            calculateTotalClientPriceExtTax: function() {
                this.totalClientPriceExTax = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        if(this.quoteItems[i].purchasingPriceExTax !== null) {
                            this.totalClientPriceExTax = this.totalClientPriceExTax + (parseFloat(this.quoteItems[i].purchasingPriceExTax) * this.quoteItems[i].quantity);
                        }
                    }
                }
            },
            calculateTotalLaying: function() {
                this.totalLaying = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        if(this.quoteItems[i].laying !== null) {
                            this.totalLaying = parseFloat(this.totalLaying) + (parseFloat(this.quoteItems[i].laying) * parseInt(this.quoteItems[i].quantity));
                        }
                    }
                }
            },
            calculateTotalMargin: function() {
                this.totalMarginRatio = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        if(this.quoteItems[i].marginRatio !== null && this.quoteItems[i].purchasingPriceExTax !== null) {
                            this.totalMarginRatio = this.totalMarginRatio + ((parseFloat(this.quoteItems[i].purchasingPriceExTax) * (this.quoteItems[i].marginRatio - 1)) * this.quoteItems[i].quantity);
                        }
                    }
                }
            },
            calculateTotalClientExTax: function() {
                this.totalClientExTaxPrice = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        let sellingPrice = 0;
                        if( this.quoteItems[i].origin < 2) {
                            if(this.quoteItems[i].marginRatio > 0) {
                                sellingPrice = ((parseFloat(this.quoteItems[i].purchasingPriceExTax) * this.quoteItems[i].marginRatio) + parseFloat(this.quoteItems[i].laying)) * this.quoteItems[i].quantity;
                            } else {
                                sellingPrice = (parseFloat(this.quoteItems[i].purchasingPriceExTax) + parseFloat(this.quoteItems[i].laying)) * this.quoteItems[i].quantity;
                            }
                        } else {
                            sellingPrice = this.quoteItems[i].sellingPrice * this.quoteItems[i].quantity;
                        }
                        this.totalClientExTaxPrice = (this.totalClientExTaxPrice + sellingPrice);

                    }
                    this.totalClientExTaxPrice = this.totalClientExTaxPrice - this.clientDiscount;
                }
            },
            calculateTotalClientVAT: function() {
                this.totalClientPriceVAT = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        if(this.quoteItems[i].vat !== null) {
                            let sellingPriceTTC = 0;
                            if( this.quoteItems[i].origin < 2) {
                                if(this.quoteItems[i].marginRatio > 0) {
                                    let sellingPriceHT = (parseFloat(this.quoteItems[i].purchasingPriceExTax) * this.quoteItems[i].marginRatio) + parseFloat(this.quoteItems[i].laying);
                                    sellingPriceTTC = (sellingPriceHT * (this.quoteItems[i].vat / 100)) * this.quoteItems[i].quantity;
                                } else {
                                    let sellingPriceHT = parseFloat(this.quoteItems[i].purchasingPriceExTax) + parseFloat(this.quoteItems[i].laying);
                                    sellingPriceTTC = (sellingPriceHT * (this.quoteItems[i].vat / 100)) * this.quoteItems[i].quantity;
                                }
                            } else {
                                sellingPriceTTC = (this.quoteItems[i].sellingPrice * (this.quoteItems[i].vat / 100)) * this.quoteItems[i].quantity;
                            }
                            this.totalClientPriceVAT = this.totalClientPriceVAT + sellingPriceTTC;
                        }
                    }
                }
            },
            calculateTotalClientIncTax: function() {
                this.totalClientIncTaxPrice = (this.totalClientExTaxPrice + this.totalClientPriceVAT);
            },
            updateOnMarginChange: function () {
                this.calculateTotalMargin();
                this.calculateTotalClientExTax();
                this.calculateTotalClientIncTax();
                this.calculateTotalClientVAT();
            },
            updateOnLayingChange: function () {
                this.calculateTotalLaying();
                this.calculateTotalClientExTax();
                this.calculateTotalClientIncTax();
                this.calculateTotalClientVAT();
            },
            updateOnQuantityChange: function (item) {
                if (item.quantity != '') {
                    item.quantity = Math.max(item.quantity, 1);
                }
                this.calculateTotalClientPriceExtTax();
                this.calculateTotalClientIncTax();
                this.calculateTotalLaying();
                this.calculateTotalMargin();
                this.calculateTotalClientVAT();
                this.calculateTotalClientExTax();
            },
            updateOnQuantityBlur: function (item) {
                if (item.quantity == '') {
                    item.quantity = 1;
                }
                this.updateOnQuantityChange(item);
            },
            updateOnSellingPriceChange: function () {
                this.calculateTotalClientExTax();
                this.calculateTotalClientIncTax();
                this.calculateTotalClientVAT();
            },
            alignTop: function () {
                if(this.isTextArea) {
                    return 'class';
                }
            },
            printTable: function()
            {
                console.log(this.urlCss);
                var zone = document.getElementById("tabListItems").innerHTML;

                // Ouverture du popup
                var fen = window.open("", "", "height=768, width=1024,toolbar=0, menubar=0, scrollbars=1, resizable=1,status=0, location=0, left=10, top=10");

                // style du popup
                fen.document.body.style.color = '#000000';
                fen.document.body.style.backgroundColor = '#FFFFFF';
                fen.document.body.style.padding = "20px";


                // Ajout des données a imprimer
                fen.document.title = "Imprimer le récapitulatif de ma commande";
                fen.document.head.innerHTML+="<meta charset=\"UTF-8\" />\n" +
                    "    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0, shrink-to-fit=no\">";
                fen.document.head.innerHTML+=" <link rel='stylesheet' type='text/css' href='"+this.urlCss+"' media='print' />";
                fen.document.head.innerHTML+=" <link rel='stylesheet' type='text/css' href='"+this.urlCss+"'  />";
                fen.document.body.innerHTML += "<h2>Récapitulatif pour la commande de votre devis "+ this.project.reference+"</h2> " + zone + " ";

                // Impression du popup
                fen.window.print();

                //Fermeture du popup
                fen.window.close();
                return true;
            }
        }
    }
</script>

<style scoped>

</style>
