<script>
    import axios from 'axios';

    export default {
        name: 'AppInfosRequestShow',
        props: {
            infos: {
                type: Object
            },
            urlToQuote:
            {
                type: String
            }
        },
        data: function () {
            return {
                currentStatus: (this.infos.status == 2 ? 1 :0)
            }
        },
        watch: {
            currentStatus: function (val) {
                if(val !== this.infos.status) {
                    this.sendNewStatus();
                }
            }
        },
        mounted: function () {
            console.log(this.infos);
        },
        beforeDestroy: function () {
            this.currentStatus.removeEventListener('click', this.sendNewStatus);
        },
        methods: {
            sendNewStatus: function() {
                let form = document.getElementsByName('registration-status-form')[0];
                let data = {};
                for (let key in this.infos) {
                    if (this.infos.hasOwnProperty(key)) {
                        if(key !== 'status') {
                            data[key] = this.infos[key];
                        }
                    }
                }
                data.status = +this.currentStatus;
                const config = {
                    headers: { 'content-type': 'application/json' }
                };
                axios.put(form.action, data, config)
                    .catch((error) => {
                        console.warn(error);
                    })
            },
            createQuoteRequest: function()
            {
                this.$root.$emit('set-loading', true);
                let form = document.getElementsByName('transform-in-quote-form')[0];
                let data = {};
                for (let key in this.infos) {
                    if (this.infos.hasOwnProperty(key)) {
                        if(key !== 'status') {
                            data[key] = this.infos[key];
                        }
                    }
                }
                data.status = +this.currentStatus;
                const config = {
                    headers: { 'content-type': 'application/json' }
                };
                axios.put(form.action, data, config).then((response)=>{
                    console.log(response);
                    this.$root.$emit('set-loading', false);
                    window.location.href = this.urlToQuote+"/"+response.data.id;
                })
                    .catch((error) => {
                        console.warn(error);
                    })
            }
        }
    }
</script>