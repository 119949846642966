<template>
    <div  class="row pt-4 justify-content-center new-project__content__body" v-cloak>

        <div class="col-md">
          <div v-if="projectStatus == 0">
            <div class="row align-items-end">
                <div class="col-md-5 h-100 form-group">
                    <slot name="landmark"></slot> <span v-bind:class=" { 'text-danger': isErrorSyntaxeItem }" style="font-size:12px;">6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace.</span>
                    <input type="text" id="project_imported-landmark" style="width:37%;text-transform: uppercase" :placeholder="inputPlaceholder" v-model="importedLandmark" required="required" class="form-control">
                    <span v-if="isDuplicateLandmark" class="text-danger" style="font-size:12px;">Le nom du repère doit être unique</span>
                </div>
            </div>
            <div class="row align-items-end">
                <div class="col-md-3 col-lg-2 h-100 form-group">
                    <slot name="carpentry"></slot>
                    <select @change="selectGamme()" name="importedCarpentry" class="form-control" v-model="importedCarpentry" id="project_imported-carpentry">
                        <option v-for="carpentry in filteredCarpentryLabels"  :value="carpentry">{{ carpentry }}</option>
                    </select>
                </div>
                <div v-if="importedGammeLabels.length > 0" class="col-md-4 col-lg-2 h-100 form-group">
                    <slot name="gamme"></slot>
                    <select name="importedGamme" class="form-control" v-model="importedGamme" id="project_imported-gamme">
                        <option v-for="gamme in importedGammeLabels" :value="gamme">{{ gamme }}</option>
                    </select>
                </div>
                <div class="col-auto h-100 form-group">
                    <button @click="toggleItemModal()" class="arrowed-link-rigth btn btn-rounded btn-rounded--dark-blue px-4">{{ configureProductLabel }}</button>
                </div>
                <div class="col-12 mb-3">
                    <slot name="requiredBis"></slot>
                </div>
            </div>
            <hr>
            <div class="row align-items-end">
                <slot name="nonConfigurable"></slot>
                <span class="col-12" v-bind:class=" { 'text-danger': isErrorSyntaxeItemBis }" style="font-size:12px;">6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace.</span>
                <div class="mt-3 mt-md-0 col-md-2 h-100 form-group">
                    <input type="text" style="text-transform: uppercase" id="project_landmark" :placeholder="landmarkPlaceholder" v-model="nonConfigurableLandmark" required="required" class="form-control">

                </div>
                <div class="col-md-2 h-100 form-group">
                    <input type="text" id="project_quote" :placeholder="quoteNumberPlaceholder" v-model="nonConfigurableImportedQuote" required="required" class="form-control">
                </div>
                <div class="col h-100 form-group">
                    <input type="text" id="project_carpentry" :placeholder="carpentryPlaceholder" v-model="nonConfigurableCarpentry" required="required" class="form-control">
                </div>
                <div class="col-md-2 h-100 form-group">
                    <input type="number" lang="en-150" id="project_purchase_price" :placeholder="purchasePriceExTaxPlaceholder" v-model="nonConfigurablePurchasingPriceExTax" required="required" class="form-control">
                </div>
                <div class="col-auto h-100 form-group">
                    <button @click="addNonConfigurableItem()" class="arrowed-link-rigth btn btn-rounded btn-rounded--dark-blue px-4">{{ addToQuoteLabel }}</button>
                </div>
                <div class="col-12 mb-3">
                    <span v-if="isDuplicateLandmarkNonConfigurated" class="text-danger" style="font-size:12px;">Le nom du repère doit être unique</span><br/>
                    <slot name="required"></slot>
                </div>
            </div>
            <hr>
            <div class="row align-items-end">
                <div class="col form-group">
                    <slot name="customService"></slot>
                    <input type="text" id="project_customService" :placeholder="inputPlaceholder" v-model="customLandmark" required="required" class="form-control">
                </div>
                <div class="col-md-auto h-100 form-group">
                    <button @click="addCustomItem()" class="arrowed-link-rigth btn btn-rounded btn-rounded--dark-blue px-4">{{ addToQuoteLabel }}</button>
                </div>
            </div>
            <slot name="myCarpentry"></slot>
          </div>
            <b-table v-sortable="sortableOptions" stacked="md" hover @change="goToTop" id="listItems" class="project-table simple-table mb-0 " :items="quoteItems" :fields="fields" primary-key="id">
              <template v-slot:cell(id)="row" >
                <i class="d-none d-lg-inline icon icon-expand"></i>
                <!--{{row.item.rank}}-->
              </template>
              <template v-slot:cell(landmark)="row" >
                <div v-if="row.item.origin !== 2">
                  {{ row.item.landmark }}
                </div>
              </template>
              <template v-slot:cell(carpentry)="row" >
                    <div v-if="row.item.origin < 1" class="project-table__small">
                        <!--{{ row.item.carpentry }}<br>-->
                        <span v-for="desc in row.item.summaryConfiguration">
                             <span v-if="desc.mandatory == true">
                                <b>{{desc.label}}</b> : {{desc.value}}<br/>
                             </span>
                        </span>
                        <br>
                        <span class="small" v-if="row.item.origin === 1">{{ row.item.importedQuote }}</span>
                    </div>
                    <div v-if="row.item.origin === 2" class="input-group">
                        {{ row.item.landmark }}
                    </div>
                    <div v-if="row.item.origin === 1" class="input-group">
                        <!--<textarea rows="5" cols="10" class="form-control" v-model="row.item.carpentry"></textarea>-->
                        {{ row.item.carpentry }}<br/>
                        {{ row.item.importedQuote }}
                    </div>
                </template>
              <template v-slot:cell(quantity)="row" >
                <div class="input-group input-group__increment ">
                  <span v-on:click="row.item.quantity===0 ? row.item.quantity=0  : row.item.quantity --; updateOnQuantityChange(row.item)">-</span>
                  <input type="number" min="1" lang="en-150" class="form-control" v-model="row.item.quantity"  v-on:blur="updateOnQuantityBlur(row.item)" :disabled="projectStatus != 0">
                  <span v-on:click="row.item.quantity ++; updateOnQuantityChange(row.item)">+</span>
                </div>
              </template>
              <template v-slot:cell(purchasingPrice)="row" >
                <div v-if="row.item.origin < 2" class="project-table__value">
                  {{ row.item.purchasingPriceExTax|toFixed|formatPrice }}
                </div>
              </template>
              <template v-slot:cell(ratio)="row" >
                <div v-if="row.item.origin < 2" class="input-group input-group__increment ">
                  <span v-on:click="row.item.marginRatio===0 ? row.item.marginRatio =0 : row.item.marginRatio = Math.round((+row.item.marginRatio - 0.1)*10)/10;updateOnMarginChange()">-</span>
                  <input type="number" lang="en-150" step="0.1" min="1" class="form-control" v-model="row.item.marginRatio" @input="updateOnMarginChange()" :disabled="projectStatus != 0">
                  <span v-on:click="row.item.marginRatio = Math.round((+row.item.marginRatio + 0.1)*10)/10;updateOnMarginChange()">+</span>
                </div>
              </template>
              <template v-slot:cell(laying)="row" >
                <div v-if="row.item.origin < 2" class="input-group input-group__increment ">
                  <span v-on:click="row.item.laying===0 ? row.item.laying =0 : row.item.laying --;updateOnLayingChange()">-</span>
                  <input type="number" lang="en-150" step="10" min="0" class="form-control" v-model="row.item.laying" @input="updateOnLayingChange()" :disabled="projectStatus != 0">
                  <span v-on:click="row.item.laying ++;updateOnMarginChange()">+</span>
                </div>
              </template>
              <template v-slot:cell(sellingPrice)="row" >
                <div v-if="row.item.origin < 2" class="project-table__value">
                  {{ calculateItemSellingPrice(row.item.purchasingPriceExTax, row.item.marginRatio, row.item.laying)|toFixed|formatPrice }}
                </div>
                <div v-if="row.item.origin === 2" class="input-group input-group--small">
                  <input type="number" lang="en-150" step="10" class="form-control" v-model="row.item.sellingPrice" @input="updateOnSellingPriceChange()" :disabled="projectStatus != 0">
                </div><br/>
<!--                dont éco-contribution {{calculateItemEco(row.item.ecoContribMenui,row.item.ecoContribVr,row.item.quantity)|toFixed|formatPrice }} €-->
              </template>
              <template v-slot:cell(vat)="row" >
                <div class="input-group input-group--small">
                  <select class="form-control" name="vat" id="vat" v-model="row.item.vat" @change="calculateTotalClientVAT()" :disabled="projectStatus != 0">
                    <option v-for="v in vat" :value="v.value">{{ v.label }}</option>
                  </select>
                </div>
              </template>
                <template v-slot:cell(action)="row">
                    <div class="text-left text-lg-center" v-if="projectStatus == 0">
                      <app-action-dropdown :row-index="row.index" :item-id="row.item.id" :audience="row.item.origin == 0 ? 'productItem' : 'quoteItem'"></app-action-dropdown>
                    </div>
                </template>
            </b-table>
            <div class="row justify-content-end no-gutters bg-white-three">
                <div class="col-md-2">
                    <div class="simple-table__element-container">
                        <slot name="purchaseLabel"></slot>
                        <span class="d-inline-block d-lg-block ml-2 ml-lg-0 mt-lg-2">
                            <template v-if="totalClientPriceExTax > 0">
                                {{ totalClientPriceExTax|toFixed|formatPrice }} {{ euroExtax }}
                            </template>
                            <template v-else>
                                {{ totalClientPriceExTax}} {{ euroExtax }}
                            </template>
                        </span>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="simple-table__element-container">
                        <slot name="marginLabel"></slot>
                        <span class="d-inline-block d-lg-block ml-2 ml-lg-0 mt-lg-2">
                            <template v-if="totalMarginRatio > 0">
                                {{ totalMarginRatio|toFixed|formatPrice }} {{ euroExtax }}
                            </template>
                            <template v-else>
                                {{ totalMarginRatio }} {{ euroExtax }}
                            </template>
                        </span>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="simple-table__element-container">
                        <slot name="layingLabel"></slot>
                        <span class="d-inline-block d-lg-block ml-2 ml-lg-0 mt-lg-2">
                            <template v-if="totalLaying > 0">
                                {{ totalLaying|toFixed|formatPrice }} {{ euroExtax }}
                            </template>
                            <template v-else>
                                {{ totalLaying }} {{ euroExtax }}
                            </template>
                        </span>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="simple-table__element-container">
                        <slot name="discountLabel"></slot>
                        <span class="d-inline-block ml-2 ml-lg-0 mt-lg-2 align-middle text-nowrap">
                        <div class="input-group" style="width: 4.4em">
                            <input type="number" lang="en-150" min="0"  step="10" v-model="clientDiscount" class="form-control" @input="calculateTotalClientExTax()"  :disabled="projectStatus != 0"/>
                        </div>
                        </span>
                        <span class="">&nbsp;{{ euroExtax }}</span>
                    </div>
                </div>
                <div class="col-md-3 bg-dark-blue">
                    <div class="simple-table__element-container simple-table__element-container--white">
                        <slot name="totalLabel"></slot>
                        <p class="mt-2">
                            <template v-if="totalClientExTaxPrice > 0">
                                {{ totalClientExTaxPrice|toFixed|formatPrice }} {{ euroExtax }}
                            </template>
                            <template v-else>
                                {{ totalClientExTaxPrice }} {{ euroExtax }}
                            </template>
                            <br />
                            <template v-if="totalClientPriceVAT > 0">
                                {{ totalClientPriceVAT|toFixed|formatPrice }} {{ euroTax }}
                            </template>
                            <template v-else>
                                {{ totalClientPriceVAT }} {{ euroTax }}
                            </template>
                            <br />
                            <template v-if="totalClientIncTaxPrice > 0">
                                {{ totalClientIncTaxPrice|toFixed|formatPrice }} {{ euroInctax }}
                            </template>
                            <template v-else>
                                {{ totalClientIncTaxPrice }} {{ euroInctax }}
                            </template>
                        </p>
                    </div>
                </div>
            </div>
            <div class="text-right my-4" v-if="projectStatus == 0">
                <button @click="saveQuote()" class="arrowed-link-rigth btn btn-rounded btn-rounded--dark-blue px-4">{{ saveLabel }}</button>
            </div>
            <slot name="bonuses" v-if="projectStatus == 0"></slot>
            <div class="row text-center" v-if="projectStatus == 0">
                <div class="col-md my-2 my-md-0">
                    <div class="bg-dark-blue p-2 p-md-3 h-100">
                        <div class="row h-100">
                            <div class="col-12 align-self-start mb-3">
                                <slot name="unfinishedQuoteLabel"></slot>
                            </div>
                            <div class="col-12 align-self-end">
                                <button @click="toggleDevisIncomplet()" class="arrowed-link-rigth btn btn-rounded btn-rounded--white px-3">
                                    <slot name="unfinishedQuoteButton"></slot>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md my-2 my-md-0" style="display: none;">
                    <div class="bg-dark-blue p-2 p-md-3 h-100">
                        <div class="row h-100">
                            <div class="col-12 align-self-start mb-3">
                                <slot name="alternativeQuoteLabel"></slot>
                            </div>
                            <div class="col-12 align-self-end">
                                <button class="arrowed-link btn btn-rounded btn-rounded--white px-3">
                                    <slot name="alternativeQuoteButton"></slot>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md my-2 my-md-0">
                    <div class="bg-dark-blue p-2 p-md-3 h-100">
                        <div class="row h-100">
                            <div class="col-12 align-self-start mb-3">
                                <span class="text-white">
                                    <slot name="alertLabel"></slot>
                                </span>
                            </div>
                            <div class="col-12 align-self-end">
                                <button v-if="errorAlert===-1" @click="toggleAlert()" class="arrowed-link-rigth btn btn-rounded btn-rounded--white px-3">
                                    <slot name="alertButton"></slot>
                                </button>
                                <div class="text-white" v-if="errorAlert===0">
                                    <p>
                                        Une alerte a été créée pour<br/> le {{chosenDate}}.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md my-2 my-md-0">
                    <div class="bg-dark-blue p-2 p-md-3 h-100">
                        <div class="row h-100">
                            <div class="col-12 align-self-start mb-3">
                                <span class="text-white">
                                    <slot name="sheetLabel"></slot>
                                </span>
                            </div>
                            <div class="col-12 align-self-end">
                                <button @click="toggleSheetModal()" class="arrowed-link-rigth btn btn-rounded btn-rounded--white px-3">
                                    <slot name="sheetButton"></slot>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal class="iframe" centered v-model="addItemModal" hide-footer size="xl" :title="modalTitle" @hide="clearConfigurationFrame" no-enforce-focus :id="'new-project-config'" :no-close-on-backdrop="true" :no-close-on-esc="true">
            <div class="row justify-content-center" style="height:100%;">
            <iframe class="col-12" height="90%" style="display: inline-block;" id="configurationFrame" src=""></iframe>

            <!--<p>-->
                <!--<slot name="modalLandmark"></slot> : {{importedLandmark}}-->
                <!--<br>-->
                <!--<slot name="modalProduct"></slot> : {{importedCarpentry}}-->
            <!--</p>-->
            </div>
        </b-modal>
        <b-modal @hidden="onHiddenDelete" centered v-model="openDeleteModal" size="md">
            <div v-if="errorDelete===-1">
                <p class="text-center">
                    <slot name="confirmDelete"></slot>
                    <input type="hidden" v-model="modalItemId">
                </p>
            </div>
            <div v-if="errorDelete===0">
                <p class="text-center">
                    <slot name="deleteCarpentryMessage"></slot>
                </p>
            </div>
            <div slot="modal-footer" v-if="errorDelete===-1">
                <button @click="deleteItem(modalItemId)" class="btn btn-rounded btn-rounded--white border-black px-3 mr-2">{{ yesLabel }}</button>
                <button @click="openDeleteModal = false" class="btn btn-rounded btn-rounded--black px-3">{{ noLabel }}</button>
            </div>
            <div slot="modal-footer" v-if="errorDelete===0">

            </div>
        </b-modal>
        <b-modal  centered v-model="openUpdateModal" size="lg">
            <input type="hidden" v-model="modalItemId">
            <template v-if="currentItemOrigin === 0">
              <label>{{ landmarkPlaceholder }} :</label> <span v-bind:class=" { 'text-danger': isErrorSyntaxeItem }" style="font-size:12px;">6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace.</span>
              <input type="text" :value="modalItem.landmark" ref="modalInputLandmark" style="text-transform: uppercase" required="required" class="form-control" maxlength="6">
            </template>
            <div class="row justify-content-center" v-if="currentItemOrigin === 1">
                <div class="form-group col-md-4">
                    <label>{{ landmarkPlaceholder }} :</label> <span v-bind:class=" { 'text-danger': isErrorSyntaxeItem }" style="font-size:12px;">6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace.</span>
                    <input type="text" :value="modalItem.landmark" ref="modalInputLandmark" style="text-transform: uppercase" required="required" class="form-control">

                </div>
                <div class="form-group col-md-4">
                    <label>{{ quoteNumberPlaceholder }} :</label>
                    <input type="text" :value="modalItem.importedQuote" ref="modalInputImportedQuote" required="required" class="form-control">
                </div>
                <div class="form-group col-md-8">
                    <label>{{ carpentryPlaceholder }} :</label>
                    <input type="text" :value="modalItem.carpentry" ref="modalInputCarpentry" required="required" class="form-control">
                </div>
                <div class="w-100"></div>
                <div class="form-group col-md-4">
                    <label>{{ purchasePriceExTaxPlaceholder }} :</label>
                    <input type="number" lang="en-150" :value="modalItem.purchasingPriceExTax" ref="modalInputPurchasingExTax" required="required" class="form-control">
                </div>
                <div class="col-md-4"></div>
            </div>
            <div class="row justify-content-center" v-if="currentItemOrigin === 2">
                <div class="form-group col-md-8">
                    <label>{{ landmarkPlaceholder }} :</label> <span v-bind:class=" { 'text-danger': isErrorSyntaxeItem }" style="font-size:12px;">6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace.</span>
                    <input type="text" :value="modalItem.landmark" style="text-transform: uppercase;" ref="modalInputLandmark" required="required" class="form-control">
                </div>
            </div>
            <span v-if="isDuplicateLandmarkUpdateLandMark" class="text-danger row justify-content-center" style="font-size:14px;">Le nom du repère doit être unique</span>
            <div slot="modal-footer">
                <button @click="updateItem(modalItem)" class="btn btn-rounded btn-rounded--white border-black px-3 mr-2">{{ submitLabel }}</button>
                <button @click="openUpdateModal = false" class="btn btn-rounded btn-rounded--black px-3">{{ backLabel }}</button>
            </div>
        </b-modal>
        <b-modal @hidden="onHidden" centered v-model="openDevisIncompletModal" size="lg">
            <div class="row justify-content-center" >
                <div class="col-8" v-if="error===-1">
                    <h4>Devis incomplet, transférez-le à Mincco</h4>
                    <p>
                        Vous ne pouvez pas chiffrer une menuiserie en raison de sa complexité technique.
                        Décrivez votre menuiserie ici et joignez éventuelement un document (PDF ou Word)
                        Votre commercial vous adressera son chiffrage que vous pourrez intégrer à votre devis.
                    </p>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>Précisez votre demande* :</label>
                            <textarea rows="5" required="required" v-model="demande" class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            Joignez un document pdf ou word (poids maximum 2 Mo)<br/>
                            <div class="input-group mb-2">
                                <input

                                        type="text" ref="docFile" class="form-control mr-4" readonly>
                                <label class="input-group-btn">
                                     <span class="btn btn-rounded btn-rounded--white btn-rounded--shadowed">
                                       Parcourir <input type="file" id="docFileName" ref="docInput" @change="onFileInput" style="display: none;">
                                     </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-8" v-if="error===0">
                    <h4>Demande de devis incomplet enregistrée</h4>
                    <p>
                        Votre demande a bien été adressée. Notre équipe met tout en oeuvre pour vous répondre dans les meilleurs délais.<br/><br/>
                        L’équipe Minco
                    </p>
                </div>
                <div class="col-8" v-if="error===1">
                    <h4>Demande de devis incomplet</h4>
                    <p>
                        Le fichier fourni n'est pas au bon format, .pdf ou .docx attendu.
                    </p>
                </div>
                <div class="col-8" v-if="error===2">
                    <h4>Demande de devis incomplet</h4>
                    <p>
                        Le fichier fourni est trop volumineux, il ne doit pas dépasser 2 Mo.
                    </p>
                </div>
            </div>
            <div class="row justify-content-between w-100" slot="modal-footer" v-if="error===-1">
                <span class="col-4 pl-5">* Champs obligatoires</span>
                <div class="col-4 text-right">
                    <button @click="sendDevisIncomplet()" class="btn btn-rounded btn-rounded--black px-3 mr-2">{{ submitLabel }}</button>
                </div>
                <!--<button @click="openDevisIncompletModal = false" class="btn btn-rounded btn-rounded&#45;&#45;black px-3">{{ backLabel }}</button>-->
            </div>
            <div class="row justify-content-between w-100" slot="modal-footer" v-if="error > -1">

            </div>
        </b-modal>
        <b-modal centered v-model="openAlertModal" size="lg">
            <div class="row justify-content-center" v-if="errorAlert===-1">
                <div class="col-8">
                    <h4>Augmentez votre taux de transformation</h4>
                    <h5>Optez pour la relance en ligne</h5>
                    <p>
                        Minco va encore plus loin et vous aide à augmenter votre taux de transformation
                        devis. En optant pour la relance en ligne, Minco vous adresse un mail à la date et
                        l’heure de votre choix pour vous rappeler de relancer vos clients.<br/><br/>
                        Un vrai service en plus !
                    </p>
                </div>
                <div class="col-8">
                    <label for="deliverydate">Sélectionnez une date </label>
                    <datepicker style="width: 32%;display: inline-block;"  ref="dateAlert" v-model="dateAlert" input-class="form-control" :language="fr"></datepicker>
                    <vue-timepicker style="width: 32%;display: inline-block;" v-model="heureAlert"  ref="heureAlert"
                                    :format="yourFormat"
                                    :minute-interval="15">
                    </vue-timepicker>
                </div>
            </div>
            <div class="row justify-content-center" v-if="errorAlert===0">
                <div class="col-8">
                    <h4>Augmentez votre taux de transformation</h4>
                    <p>
                        Votre demande de relance en ligne a bien été enregistrée. Vous recevrez un e-mail le {{chosenDate}}.
                    </p>
                </div>
            </div>
            <div slot="modal-footer" v-if="errorAlert===-1">
                <span>* Champs obligatoires</span>
                <button @click="sendAlert()" class="btn btn-rounded btn-rounded--white border-black px-3 mr-2">{{ submitLabel }}</button>
                <button @click="openAlertModal = false" class="btn btn-rounded btn-rounded--black px-3">{{ backLabel }}</button>
            </div>
            <div slot="modal-footer" v-if="errorAlert > -1">

            </div>
        </b-modal>
        <b-modal centered  v-model="openDataSheetModal" size="lg">
            <div class="row justify-content-center" >
                <div class="col-8">
                    <h4>Fiche métré</h4>
                    <p>
                        Vous avez terminé votre devis en fourniture seule ou fourni/posé, mais vous devez vérifier les cotes avant de commander.
                        Votre chantier a bien été enregistré dans « mes chantiers en cours ».
                        <br/><br/>
                        Téléchargez la fiche métré ci-dessous qui vous guidera dans votre prise de cotes.
                        Vous pourrez saisir les cotes définitives de votre chantier avant de passer votre commande.
                    </p>
                </div>
            </div>
            <div slot="modal-footer" >
                <a :href="downloadSheetUrl" class="btn btn-rounded btn-rounded--dark-blue border-black px-3 mr-2">Télécharger la fiche métré (Excel)</a>
                <a :href="downloadSheetUrlPdf" target="_blank" class="btn btn-rounded btn-rounded--dark-blue border-black px-3 mr-2">Télécharger la fiche métré (PDF)</a>
            </div>
        </b-modal>
        <b-modal @hidden="onHiddenSave" centered v-model="openSaveModal" size="lg">
            <p class="text-center" v-if="errorSave===0">
                <slot v-if="typeItem===0" name="saveCarpentryMessage"></slot>
                <slot v-if="typeItem===1" name="saveCarpentryMessage"></slot>
                <slot v-if="typeItem===2" name="saveCustomCarpentryMessage"></slot>
            </p>
            <div slot="modal-footer">

            </div>
        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import AppActionDropdown from '../common/AppActionDropdown';
    import { EventBus } from '../../event';
    import Datepicker from 'vuejs-datepicker';
    import { en, fr } from 'vuejs-datepicker/dist/locale';
    import VueTimepicker from 'vue2-timepicker';
    import Sortable from 'sortablejs';

    const createSortable = (el, options, vnode) => {
      return Sortable.create(el, {
        ...options,
        onEnd: function (evt) {

          const items = vnode.context.quoteItems;
          const item = items[evt.oldIndex]
          if (evt.newIndex > evt.oldIndex) {
            for (let i = evt.oldIndex; i < evt.newIndex; i++) {
              items[i] = items[i + 1]
            }
          } else {
            for (let i = evt.oldIndex; i > evt.newIndex; i--) {
              items[i] = items[i - 1]
            }
          }
          items[evt.newIndex] = item

          for (let i = 1; i <= items.length; i++) {
            items[i - 1].rank = i;
          }
          console.log(items);
        }
      })
    }

    /**
     * We add a new instance of Sortable when the element
     * is bound or updated, and destroy it when it's unbound.
     */
    const sortable = {
      name: 'sortable',
      bind(el, binding, vnode) {
        const table = el
        table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode)
      },
      update(el, binding, vnode) {
        const table = el
        table._sortable.destroy()
        table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode)
      },
      unbind(el) {
        const table = el
        table._sortable.destroy()
      }
    }

    export default {
        name: 'AppProjectAccordeonItemTwo',
        components: { AppActionDropdown,
            Datepicker: Datepicker,
            VueTimepicker: VueTimepicker,
        },
        props: {
            hasCoffre:
                {
                    type: String
                },
            hasntCoffre:
                {
                    type: String
                },
            apiDuplicateItemUrl:
                {
                    type: String
                },
            apiQuoteUrl: {
                type: String
            },
            apiQuoteItemUrl: {
                type: String
            },
            apiQuotesUrl: {
                type: String
            },
            apiQuoteItemsUrl: {
                type: String
            },
            apiQuoteIncompleteUrl: {
                type: String
            },
            apiQuoteAlertUrl:
            {
                type: String
            },
            apiItemsUpdateUrl:
            {
                type: String
            },
            apiItemUpdateUrl:
                {
                    type: String
                },
            downloadSheetUrl :
                {
                    type: String
                },
            downloadSheetUrlPdf :
                {
                    type: String
                },
            configureProductLabel: {
                type: String
            },
            addToQuoteLabel: {
                type: String
            },
            saveLabel: {
                type: String
            },
            submitLabel: {
                type: String
            },
            backLabel: {
                type: String
            },
            yesLabel: {
                type: String
            },
            noLabel: {
                type: String
            },
            inputPlaceholder: {
                type: String
            },
            landmarkPlaceholder: {
                type: String
            },
            carpentryPlaceholder: {
                type: String
            },
            quoteNumberPlaceholder: {
                type: String
            },
            purchasePriceExTaxPlaceholder: {
                type: String
            },
            euroExtax: {
                type: String
            },
            euroTax: {
                type: String
            },
            euroInctax: {
                type: String
            },
            modalTitle: {
                type: String
            },
            tableLandmarkLabel: {
                type: String
            },
            tableCarpentryLabel: {
                type: String
            },
            tableQuantityLabel: {
                type: String
            },
            tablePurchasingPriceLabel: {
                type: String
            },
            tableRatioLabel: {
                type: String
            },
            tableLayingLabel: {
                type: String
            },
            tableSellingPriceLabel: {
                type: String
            },
            tableVatLabel: {
                type: String
            },
            tableActionLabel: {
                type: String
            },
            importedCarpentryLabels: {
                type: Array
            },
            importedGammeLabelsCoulissant: {
                type: Array
            },
            importedGammeLabelsFenetre:{
                type: Array
            },
            quoteAlert:
                {
                    type: Object
                },
            apiConfigurateurUrl:
                {
                    type: String
                },
            codeClient:
                {
                    type: String
                },
            project: {
                type: Object
            }
        },
        directives: { sortable },
        data: function() {
            return {
                sortableOptions: {
                  chosenClass: 'is-selected'
                },
                fr: fr,
                en: en,
                fields: [],
                importedLandmark: '',
                importedCarpentry: this.importedCarpentryLabels[0],
                //importedGamme: this.importedGammeLabelsCoulissant[0],
                importedGamme: [],
                importedPurchasingPriceExTax: 119296   / 100,
                importedConfigurationDetails: '',
                importConfigurationXml:'',
                importConfigurationXMLFile:'',
                importSummary:[],
                nonConfigurableLandmark: '',
                nonConfigurableImportedQuote: '',
                nonConfigurableCarpentry: '',
                nonConfigurablePurchasingPriceExTax: null,
                customLandmark: '',
                quoteItems: [],
                vat: [
                    { label: '5,5 %', value: 5.5 },
                    { label: '10 %', value: 10 },
                    { label: '20 %', value: 20 }
                ],
                imgConfiguration:'',
                clientDiscount: 0,
                quoteVersion: "0",
                defaultQuantity: 1,
                totalClientExTaxPrice: 0,
                totalMarginRatio: 0,
                totalLaying: 0,
                totalClientPriceExTax: 0,
                totalClientPriceVAT: 0,
                totalClientIncTaxPrice: 0,
                defaultVat: 20,
                projectId: 0,
                projectStatus: 0,
                quoteId: 0,
                addItemModal: false,
                openDeleteModal: false,
                openUpdateModal: false,
                currentItemOrigin: -1,
                modalItemId: 0,
                modalItem: null,
                isTextArea: false,
                openDevisIncompletModal:false,
                docFileName :"",
                demande: "",
                openAlertModal: false,
                dateAlert: null,
                //heureAlert:null,
                yourFormat: 'HH:mm',
                heureAlert: {
                    HH: '00',
                    mm: '00'
                },
                error: -1,
                errorAlert:-1,
                chosenDate:null,
                openDataSheetModal: false,
                errorDuplicate: -1,
                errorDelete:-1,
                errorArchivate:-1,
                errorSave: -1,
                openSaveModal:false,
                isErrorSyntaxeItem: false,
                isErrorSyntaxeItemBis: false,
                isDuplicateLandmark:false,
                isDuplicateLandmarkNonConfigurated: false,
                isDuplicateLandmarkUpdateLandMark: false,
                importedGammeLabels: [],
                typeItem: 0,
                filteredCarpentryLabels: [],
                hasCoffreData : "0",
                hasntCoffreData :"0"
            }
        },
        watch: {
            quoteItems: function () {
                this.calculateTotalClientPriceExtTax();
                this.calculateTotalLaying();
                this.calculateTotalMargin();
                this.calculateTotalClientExTax();
                this.calculateTotalClientVAT();
                this.calculateTotalClientIncTax();
            },
            totalClientPriceExTax: function () {
                this.calculateTotalClientIncTax();
            },
            totalClientPriceVAT: function () {
                this.calculateTotalClientIncTax();
            },
            clientDiscount: function () {
                this.calculateTotalClientExTax();
                this.calculateTotalClientIncTax();
            }

        },
        created: function(){

        },
        mounted: function() {
            this.hasCoffreData = this.hasCoffre;
            this.hasntCoffreData = this.hasntCoffre;
           this.majLabelCarpentry();
          if(this.project["id"] !== null) {
            this.projectId = this.project.id;
            this.projectStatus = this.project.status;
            let quote = this.project.quotes[this.project.quotes.length - 1];
            this.quoteId = quote.id;
            this.clientDiscount = quote.clientDiscount;
            this.quoteVersion = quote.version;
            for (let item of quote.items) {
                this.quoteItems.push(
                    {
                        id: item.id,
                        landmark: item.landmark,
                        carpentry: item.carpentry,
                        quantity: item.quantity,
                        purchasingPriceExTax: (item.purchasingPriceExTax / 100),
                        marginRatio: item.marginRatio,
                        laying: (item.laying !== 0) ? item.laying / 100 : 0,
                        vat: item.vat,
                        sellingPrice: (item.sellingPrice / 100),
                        configurationDetails: item.configurationDetails,
                        configurationXML: item.configurationXML,
                        configurationXMLFile : item.configurationXMLFile,
                        importedQuote: item.importedQuote,
                        origin: item.origin,
                        rank: item.rank,
                        summaryConfiguration: item.summaryConfiguration ? JSON.parse(item.summaryConfiguration) : ''
                    }
                );
            }
          }

            this.populateFields();
            EventBus.$on('project-details', this.getProjectDetails);
            EventBus.$on('send-action', this.setActionOnItem);
            EventBus.$on('update-status', this.updateStatus);
            if(this.quoteAlert != null)
            {
                if(this.quoteAlert.dateRappel !=null) {
                    this.chosenDate = this.formatTime(this.quoteAlert.dateRappel.timestamp);
                    this.errorAlert = 0;
                }
            }
            //this.importedGammeLabels = this.importedGammeLabelsCoulissant;
            this.importedGammeLabels = [];

            let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
            let eventer = window[eventMethod];
            let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

            eventer(messageEvent,(e) => {

                let key = e.message ? "message" : "data";
                let dataObj = typeof e[key] == 'object' ? e[key] : JSON.parse(e[key]);
                let item;
                if (dataObj.from == 'configurator') {
                  item = this.quoteItems.find(d => d.id == dataObj.itemId);

                  if (item) {
                    if (dataObj.id > 0) {
                        // ajouter l'item à la liste
                        if(dataObj.itemId)
                        {
                            item.configurationDetails = dataObj.configuration;
                        }
                        item.purchasingPriceExTax = dataObj.price;
                        item.configurationDetails = dataObj.configuration;
                        item.imgConfiguration = dataObj.image;
                        item.configurationXML = dataObj.fileName;
                        item.configurationXMLFile = dataObj.fileName+".xml";
                        item.summaryConfiguration = JSON.stringify(dataObj.summary);

                        this.$root.$emit('set-loading', true);
                        axios.put(this.apiItemUpdateUrl, {"item":item})
                            .then((response) => {
                                if(response.request.responseURL.includes("connexion"))
                                {
                                    location.href = response.request.responseURL;
                                }
                                else {
                                    this.$root.$emit('set-loading', false);
                                    item.summaryConfiguration = dataObj.summary;
                                    this.addItemModal = false;
                                    this.errorSave = 0;
                                    this.openSaveModal = true;
                                    EventBus.$emit('refresh-items', this.quoteItems);
                                }
                            });

                    }
                  } else {
                    if (dataObj.id > 0 && this.importedLandmark!="") {
                        // ajouter l'item à la liste
                        this.importedPurchasingPriceExTax = dataObj.price;
                        this.importedConfigurationDetails = dataObj.configuration;
                        this.imgConfiguration = dataObj.image;
                        this.importConfigurationXml = dataObj.fileName+'.xml';
                        this.importConfigurationXMLFile = dataObj.fileName+'.xml';
                        this.importSummary = dataObj.summary;
                        this.addImportedItem();
                    }
                  }
                }
            },false);


        },
        beforeDestroy: function () {
            EventBus.$off('project-details', this.getProjectDetails);
            EventBus.$off('send-action', this.setActionOnItem);
            EventBus.$off('update-status', this.updateStatus);
        },
        methods: {
            populateFields:function() {
                this.fields.push(
                    { key: 'id', label: ' ' },
                    { key: 'landmark', label: this.tableLandmarkLabel, sortable: false },
                    { key: 'carpentry', label: this.tableCarpentryLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'quantity', label: this.tableQuantityLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'purchasingPrice', label: this.tablePurchasingPriceLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'ratio', label: this.tableRatioLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'laying', label: this.tableLayingLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'sellingPrice', label: this.tableSellingPriceLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'vat', label: this.tableVatLabel, sortable: false, tdClass: this.alignTop() },
                    { key: 'action', label: this.tableActionLabel, sortable: false, tdClass: this.alignTop() }

                );
            },
            getProjectDetails: function(event) {
                this.projectId = event.project.id;
                this.projectStatus = event.project.status;
                this.quoteId = event.id;
                this.clientDiscount = event.client_discount;
                this.quoteVersion = event.version;
                this.quoteItems = [];
                for (let item of event.items) {
                    this.quoteItems.push(
                        {
                            id: item.id,
                            landmark: item.landmark,
                            carpentry: item.carpentry,
                            quantity: item.quantity,
                            purchasingPriceExTax: (item.purchasing_price_ex_tax / 100),
                            marginRatio: item.margin_ratio,
                            laying: (item.laying !== 0) ? item.laying / 100 : 0,
                            vat: item.vat,
                            sellingPrice: (item.selling_price / 100),
                            configurationDetails: item.configuration_details,
                            configurationXML: item.configurationXML,
                            configurationXMLFile: item.configurationXMLFile,
                            importedQuote: item.imported_quote,
                            summaryConfiguration: item.summaryConfiguration ? JSON.parse(item.summaryConfiguration) : (item.summary_configuration) ? JSON.parse(item.summary_configuration) : '',
                            origin: item.origin
                        },
                    );
                }
                this.goToTop();
            },
            updateStatus: function(event)
            {
                this.projectStatus = event;
            },
            closeModal: function()
            {
              var win = document.getElementById('configurationFrame').contentWindow;
              win.postMessage('USER_CLOSE_IFRAME',"*");
            },
            toggleItemModal: function () {
//                if(this.importedLandmark !== "") {
//                    this.addItemModal = true;
//                }

                let _repereRegex = /^[A-Z0-9]{1,6}$/

                this.isDuplicateLandmark = false;
                this.isErrorSyntaxeItem = false;
                if(this.importedLandmark !== "" && _repereRegex.test(this.importedLandmark.toUpperCase()) === true) {
                    if (this.quoteItems.find((item) => item.landmark == this.importedLandmark.toUpperCase())){
                        this.isDuplicateLandmark = true;
                        return;
                    }
                    else
                    {
                        this.isDuplicateLandmark = false;
                    }
                    if(!this.isDuplicateLandmark)
                    {
                        this.addItemModal = true;

                        window.setTimeout(()=>{
                            //this.addImportedItem();
                            //window.open('https://invis.io/7GOS8JTTWUA', '_blank')
                            let iframe = document.getElementById("configurationFrame");
                            let idProdConf ="";
                            switch(this.importedCarpentry)
                            {
                                case "Coulissant":
                                    idProdConf = "CP_Coulissant";
                                    switch (this.importedGamme)
                                    {
                                        case "COLORIANCE":
                                            idProdConf = "CP_Coloriance_CD";
                                            break;
                                        case "NOVALTUCE":
                                            idProdConf = "CP_Novaltuce_CD";
                                            break;
                                        case "TWINEA":
                                            idProdConf = "CP_Twinea";
                                            break;
                                        default:
                                            break;
                                    }
                                    break;
                                case "Fenêtre":
                                    idProdConf = "CP_Fenetre";
                                    switch (this.importedGamme)
                                    {
                                        case "COLORIANCE":
                                            idProdConf = "CP_Coloriance_FR";
                                            break;
                                        case "COLORIANCE PLUS":
                                            idProdConf = "CP_ColoriancePlus_FR";
                                            break;
                                        case "NOVALTUCE":
                                            idProdConf = "CP_Novaltuce_FR";
                                            break;
                                        case "ODACE":
                                            idProdConf = "CP_Odace";
                                            break;
                                        case "THERMIENCE":
                                            idProdConf = "CP_Thermience";
                                            break;
                                        default:
                                            break;
                                    }
                                    break;
                                case "Porte d'entrée":
                                    idProdConf = "CP_PorteEntree";
                                    break;
                                case "Coffre demi-linteau":
                                    idProdConf ="CP_DEMI_LINTEAU_SEUL";
                                    this.hasCoffreData = "1";
                                    this.hasntCoffreData = "0";
                                    break;
                                default:
                                    break;
                            }
                            //iframe.setAttribute("src",(this.apiConfigurateurUrl+this.importedLandmark.toUpperCase()+"/"+idProdConf+"?Cc="+this.codeClient+"&chantier="+this.projectId+"&Wks=WKS_MINCO_2023"));
                            iframe.setAttribute("src",(this.apiConfigurateurUrl+this.importedLandmark.toUpperCase()+"/"+idProdConf+"?Cc="+this.codeClient+"&chantier="+this.projectId+"&Wks=WKS_MINCO_2019"));
                        });
                    }
                }
                else
                {
                    this.isErrorSyntaxeItem = true;
                }

            },
            toggleSheetModal: function(){
                this.openDataSheetModal = true;
            },
            addImportedItem: function (item = null) {
                this.isTextArea = false;
                if(this.projectId !== 0) {
                    this.quoteItems.push(
                        {
                            id: Date.now(),
                            landmark: (item === null) ? this.importedLandmark.toUpperCase() : item.landmark.toUpperCase(),
                            carpentry: (item === null) ? this.importedCarpentry + " "+this.importedGamme : item.carpentry+" "+(item.gamme ? item.gamme : ""),
                            quantity: (item === null) ? 1 : item.quantity,
                            purchasingPriceExTax: (item === null) ? this.importedPurchasingPriceExTax : item.purchasingPriceExTax,
                            marginRatio: (item === null) ? 1 : item.marginRatio,
                            laying: (item === null) ? 0 : item.laying,
                            vat: (item === null) ? this.defaultVat : item.vat,
                            sellingPrice: (item === null) ? this.importedPurchasingPriceExTax : item.sellingPrice,
                            configurationDetails: (item === null) ? this.importedConfigurationDetails : item.configurationDetails,
                            configurationXML: (item === null) ? this.importConfigurationXml : item.configurationXML,
                            configurationXMLFile: (item===null) ? this.importConfigurationXMLFile : item.configurationXMLFile,
                            importedQuote: null,
                            imgConfiguration: (item === null) ? this.imgConfiguration : item.imgConfiguration,
                            origin: 0,
                            rank:this.quoteItems.length+1,
                            summaryConfiguration:(item === null) ? this.importSummary : item.summaryConfiguration,
                        }
                    );
                    this.importedLandmark = '';
                    this.importedCarpentry = 'Coulissant';
                    this.addItemModal = false;
                   /* this.importedConfigurationDetails = '';
                    this.importConfigurationXml ="";
                    this.imgConfiguration = "";*/
                    this.postQuote(this.quoteItems.length - 1);
                }
            },
            duplicateImportedItem: function (item = null) {
                this.isTextArea = false;

                    this.duplicateQuote(item);

            },
            addNonConfigurableItem: function (item = null) {
                this.isTextArea = false;
                let _repereRegex = /^[A-Z0-9]{1,6}$/;
                this.isDuplicateLandmarkNonConfigurated = false;
                if(this.projectId !== 0) {
                    if(_repereRegex.test(this.nonConfigurableLandmark.toUpperCase()) === true || item !=null)
                    {
                        this.isErrorSyntaxeItemBis = false;
                        if (this.nonConfigurableLandmark !== '' && this.nonConfigurableCarpentry !== '' && this.nonConfigurablePurchasingPriceExTax !== '' && this.nonConfigurableImportedQuote !== '' ) {

                            if (this.quoteItems.find((item) => item.landmark == this.nonConfigurableLandmark.toUpperCase())){
                                this.isDuplicateLandmarkNonConfigurated = true;
                                return;
                            }
                            else
                            {
                                this.isDuplicateLandmarkNonConfigurated = false;
                            }
                            if(!this.isDuplicateLandmarkNonConfigurated) {

                                this.quoteItems.push(
                                    {
                                        id: Date.now(),
                                        landmark: (item === null) ? this.nonConfigurableLandmark.toUpperCase() : item.landmark.toUpperCase(),
                                        carpentry: (item === null) ? this.nonConfigurableCarpentry : item.carpentry,
                                        quantity: (item === null) ? 1 : item.quantity,
                                        purchasingPriceExTax: (item === null) ? this.nonConfigurablePurchasingPriceExTax : item.purchasingPriceExTax,
                                        marginRatio: (item === null) ? 1 : item.marginRatio,
                                        laying: (item === null) ? 0 : item.laying,
                                        vat: (item === null) ? this.defaultVat : item.vat,
                                        sellingPrice: (item === null) ? this.nonConfigurablePurchasingPriceExTax : item.sellingPrice,
                                        configurationDetails: null,
                                        configurationXML: null,
                                        configurationXMLFile: null,
                                        importedQuote: (item === null) ? this.nonConfigurableImportedQuote : item.importedQuote,
                                        origin: 1,
                                        rank: this.quoteItems.length + 1
                                    }
                                );
                                this.nonConfigurableLandmark = '';
                                this.nonConfigurableCarpentry = '';
                                this.nonConfigurableImportedQuote = '';
                                this.nonConfigurablePurchasingPriceExTax = '';
                                this.postQuote(this.quoteItems.length - 1);
                            }
                        }
                    }
                    else
                    {
                        this.isErrorSyntaxeItemBis = true;
                        console.log("error de syntaxxe");
                    }
                }
            },
            addCustomItem: function (item = null) {
                this.isTextArea = false;
                if(this.projectId !== 0) {
                    if (this.customLandmark !== "" || item.landmark !== "") {
                        this.quoteItems.push(
                            {
                                id: Date.now(),
                                landmark: (item === null) ? this.customLandmark : item.landmark,
                                carpentry: (item === null) ? "" : item.carpentry,
                                quantity: (item === null) ? 1 : item.quantity,
                                purchasingPriceExTax: 0,
                                marginRatio: 1,
                                laying: 0,
                                vat: (item === null) ? this.defaultVat : item.vat,
                                sellingPrice: (item === null) ? 0.0 : item.sellingPrice,
                                configurationDetails: null,
                                configurationXML: null,
                                configurationXMLFile:null,
                                importedQuote: null,
                                origin: 2,
                                rank:this.quoteItems.length+1
                            }
                        );
                        this.isTextArea = true;
                        this.customLandmark = '';
                        this.postQuote(this.quoteItems.length - 1);
                    }
                }
            },
            postQuote: function (targetRow) {
                this.$root.$emit('set-loading', true);

                if(this.quoteId === 0) {
                    axios.post(this.apiQuoteUrl, {
                        project: this.projectId,
                        version: this.quoteVersion,
                        clientDiscount: this.clientDiscount
                    })
                        .then(quoteResponse => {
                            if(quoteResponse.request.responseURL.includes("connexion"))
                            {
                                location.href = quoteResponse.request.responseURL+"?message=unactivity";
                            }
                            else {
                                this.quoteId = quoteResponse.data.id;

                                axios.post(this.apiQuoteItemUrl, {
                                    quote: this.quoteId,
                                    landmark: this.quoteItems[targetRow].landmark,
                                    carpentry: this.quoteItems[targetRow].carpentry,
                                    quantity: this.quoteItems[targetRow].quantity,
                                    purchasingPriceExTax: this.quoteItems[targetRow].purchasingPriceExTax * 100,
                                    marginRatio: this.quoteItems[targetRow].marginRatio,
                                    laying: this.quoteItems[targetRow].laying,
                                    vat: this.quoteItems[targetRow].vat,
                                    sellingPrice: this.quoteItems[targetRow].sellingPrice * 100,
                                    configurationDetails: this.quoteItems[targetRow].configurationDetails,
                                    configurationXML: this.quoteItems[targetRow].importConfigurationXml,
                                    configurationXMLFile: this.quoteItems[targetRow].importConfigurationXmlFile,
                                    imgConfiguration: this.quoteItems[targetRow].imgConfiguration,
                                    importedQuote: this.quoteItems[targetRow].importedQuote,
                                    origin: this.quoteItems[targetRow].origin,
                                    rank: this.quoteItems[targetRow].rank,
                                    summaryConfiguration: this.quoteItems[targetRow].summaryConfiguration
                                })
                                    .then(quoteItemResponse => {
                                        if(quoteItemResponse.request.responseURL.includes("connexion"))
                                        {
                                            location.href = quoteItemResponse.request.responseURL+"?message=unactivity";
                                        }
                                        else {
                                            this.$root.$emit('set-loading', false);
                                            this.quoteItems[targetRow].id = quoteItemResponse.data.id;
                                            if (this.quoteItems[targetRow].id > 0) {
                                                this.errorSave = 0;
                                                this.typeItem = this.quoteItems[targetRow].origin;
                                                this.openSaveModal = true;
                                            }
                                            EventBus.$emit('refresh-items', this.quoteItems);
                                            this.majLabelCarpentry();
                                        }
                                    })
                                    .catch(error => {
                                        this.$root.$emit('set-loading', false);
                                        console.warn(error);
                                    })
                            }
                        });
                } else {
                    axios.post(this.apiQuoteItemUrl, {
                        quote: this.quoteId,
                        landmark: this.quoteItems[targetRow].landmark,
                        carpentry: this.quoteItems[targetRow].carpentry,
                        quantity: this.quoteItems[targetRow].quantity,
                        purchasingPriceExTax: this.quoteItems[targetRow].purchasingPriceExTax * 100,
                        marginRatio: this.quoteItems[targetRow].marginRatio,
                        laying: this.quoteItems[targetRow].laying,
                        vat: this.quoteItems[targetRow].vat,
                        sellingPrice: this.quoteItems[targetRow].sellingPrice * 100,
                        configurationDetails: this.quoteItems[targetRow].configurationDetails,
                        configurationXML: this.quoteItems[targetRow].configurationXML,
                        configurationXMLFile: this.quoteItems[targetRow].configurationXMLFile,
                        imgConfiguration : this.quoteItems[targetRow].imgConfiguration,
                        origin: this.quoteItems[targetRow].origin,
                        importedQuote: this.quoteItems[targetRow].importedQuote,
                        rank:this.quoteItems[targetRow].rank,
                        summaryConfiguration: JSON.stringify(this.quoteItems[targetRow].summaryConfiguration)
                    })
                        .then(quoteItemResponse => {
                            if(quoteItemResponse.request.responseURL.includes("connexion"))
                            {
                                location.href = quoteItemResponse.request.responseURL+"?message=unactivity";
                            }
                            else
                            {
                                this.$root.$emit('set-loading', false);
                                this.quoteItems[targetRow].id = quoteItemResponse.data.id;
                                console.log(this.quoteItems);
                                if(this.quoteItems[targetRow].id>0)
                                {
                                    this.errorSave=0;
                                    this.typeItem = this.quoteItems[targetRow].origin;
                                    this.openSaveModal = true;
                                }
                                EventBus.$emit('refresh-items', this.quoteItems);
                                this.majLabelCarpentry();
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.$root.$emit('set-loading', false);
                            console.warn(error);
                        })
                }
            },
            duplicateQuote: function (item) {
                this.$root.$emit('set-loading', true);
                if(this.quoteId === 0) {
                    axios.post(this.apiQuoteUrl, {
                        project: this.projectId,
                        version: this.quoteVersion,
                        clientDiscount: this.clientDiscount
                    })
                        .then(quoteResponse => {

                            if(quoteResponse.request.responseURL.includes("connexion"))
                            {
                                location.href = quoteResponse.request.responseURL+"?message=unactivity";
                            }
                            else
                            {
                                this.quoteId = quoteResponse.data.id;

                                axios.post(this.apiQuoteItemUrl, {
                                    quote: this.quoteId,
                                    landmark: item.landmark,
                                    carpentry: item.carpentry,
                                    quantity: item.quantity,
                                    purchasingPriceExTax: item.purchasingPriceExTax * 100,
                                    marginRatio: item.marginRatio,
                                    laying: item.laying,
                                    vat: item.vat,
                                    sellingPrice: item.sellingPrice * 100,
                                    configurationDetails: item.configurationDetails,
                                    configurationXML: item.importConfigurationXml,
                                    configurationXMLFile: item.importConfigurationXmlFile,
                                    imgConfiguration: item.imgConfiguration,
                                    importedQuote: item.importedQuote,
                                    origin: item.origin,
                                    rank: item.rank,
                                    id: item.id
                                })
                                    .then(quoteItemResponse => {
                                        if(quoteItemResponse.request.responseURL.includes("connexion"))
                                        {
                                            location.href = quoteItemResponse.request.responseURL+"?message=unactivity";
                                        }
                                        else {
                                            this.$root.$emit('set-loading', false);
                                            this.quoteItems[length].id = quoteItemResponse.data.id;
                                            if (this.quoteItems[length].id > 0) {
                                                this.errorSave = 0;

                                                this.openSaveModal = true;
                                            }
                                            EventBus.$emit('refresh-items', this.quoteItems);
                                        }
                                    })
                                    .catch(error => {
                                        this.$root.$emit('set-loading', false);
                                        console.warn(error);
                                    })
                            }
                        });
                } else {
                    axios.post(this.apiDuplicateItemUrl, {
                        quote: this.quoteId,
                        landmark: item.landmark,
                        carpentry: item.carpentry,
                        quantity: item.quantity,
                        purchasingPriceExTax: item.purchasingPriceExTax * 100,
                        marginRatio: item.marginRatio,
                        laying: item.laying,
                        vat: item.vat,
                        sellingPrice: item.sellingPrice * 100,
                        configurationDetails: item.configurationDetails,
                        configurationXML: item.configurationXML,
                        configurationXMLFile: item.configurationXMLFile,
                        imgConfiguration : item.imgConfiguration,
                        origin: item.origin,
                        rank:(this.quoteItems.length+1),
                        id:item.id
                    })
                        .then(quoteItemResponse => {
                            if(quoteItemResponse.request.responseURL.includes("connexion"))
                            {
                                location.href = quoteItemResponse.request.responseURL+"?message=unactivity";
                            }
                            else {

                                this.$root.$emit('set-loading', false);

                                if (quoteItemResponse.data.id > 0) {
                                    if (this.projectId !== 0) {
                                        this.quoteItems.push(
                                            {
                                                id: quoteItemResponse.data.id,
                                                landmark: quoteItemResponse.data.landmark.toUpperCase(),
                                                carpentry: quoteItemResponse.data.carpentry + " " + (quoteItemResponse.data.gamme ? quoteItemResponse.data.gamme : ""),
                                                quantity: quoteItemResponse.data.quantity,
                                                purchasingPriceExTax: quoteItemResponse.data.purchasing_price_ex_tax / 100,
                                                marginRatio: quoteItemResponse.data.margin_ratio,
                                                laying: quoteItemResponse.data.laying / 100,
                                                vat: quoteItemResponse.data.vat,
                                                sellingPrice: quoteItemResponse.data.selling_price / 100,
                                                configurationDetails: quoteItemResponse.data.configuration_details,
                                                configurationXML: quoteItemResponse.data.configuration_x_m_l,
                                                configurationXMLFile: quoteItemResponse.data.configuration_x_m_l_file,
                                                importedQuote: (quoteItemResponse.data.importedQuote) ? quoteItemResponse.data.importedQuote : quoteItemResponse.data.imported_quote,
                                                imgConfiguration: quoteItemResponse.data.img_configuration,
                                                origin: quoteItemResponse.data.origin,
                                                summaryConfiguration: quoteItemResponse.data.summary_configuration ? JSON.parse(quoteItemResponse.data.summary_configuration) : '',
                                                rank: this.quoteItems.length + 1
                                            }
                                        );
                                        this.importedLandmark = '';
                                        this.importedCarpentry = 'Coulissant';
                                        this.addItemModal = false;
                                        /* this.importedConfigurationDetails = '';
                                     this.importConfigurationXml ="";
                                     this.imgConfiguration = "";*/
                                        //this.duplicateQuote(this.quoteItems.length - 1);
                                    }

                                    this.errorSave = 0;
                                    this.openSaveModal = true;
                                    EventBus.$emit('refresh-items', this.quoteItems);
                                }
                            }
                        })
                        .catch(error => {
                            console.warn(error);
                        })
                }
            },
            saveQuote: function () {
                if(this.quoteId > 0) {
                    this.$root.$emit('set-loading', true);
                    axios.put(this.apiQuoteUrl+'/' + this.quoteId, {
                        project: this.projectId,
                        version: this.quoteVersion,
                        clientDiscount: this.clientDiscount
                    })
                        .then(response => {
                            if(response.request.responseURL.includes("connexion"))
                            {
                                location.href = response.request.responseURL+"?message=unactivity";
                            }
                            else {
                                //let quoteItems = response.data.items;
                                let quoteItems = [];
                                console.log(quoteItems);
                                //this.quoteItems.sort(function(a,b){return a.id - b.id});
                                console.log(this.quoteItems);
                                for (let i = 0; i < this.quoteItems.length; i++) {
//                                console.log("id item "+this.quoteItems[i].id);
//                                console.log(this.quoteItems[i]);

                                    quoteItems.push(
                                        {
                                            quote: this.quoteId,
                                            id: this.quoteItems[i].id,
                                            landmark: this.quoteItems[i].landmark,
                                            carpentry: this.quoteItems[i].carpentry,
                                            quantity: this.quoteItems[i].quantity,
                                            purchasingPriceExTax: this.quoteItems[i].purchasingPriceExTax * 100,
                                            marginRatio: this.quoteItems[i].marginRatio,
                                            laying: this.quoteItems[i].laying * 100,
                                            vat: this.quoteItems[i].vat,
                                            sellingPrice: (this.quoteItems[i].origin < 2) ? this.calculateItemSellingPrice(this.quoteItems[i].purchasingPriceExTax * 100, this.quoteItems[i].marginRatio, this.quoteItems[i].laying * 100) : this.quoteItems[i].sellingPrice * 100,
                                            configuration_details: this.quoteItems[i].configurationDetails,
                                            configuration_x_m_l: this.quoteItems[i].configurationXML,
                                            configurationXMLFile: this.quoteItems[i].configurationXMLFile,
                                            importedQuote: this.quoteItems[i].importedQuote,
                                            img_configuration: this.quoteItems[i].imgConfiguration,
                                            origin: this.quoteItems[i].origin,
                                            summaryConfiguration: this.quoteItems[i].summaryConfiguration,
                                            rank: this.quoteItems[i].rank
                                        }
                                    );

                                }
                                console.log(quoteItems);
                                axios.put(this.apiItemsUpdateUrl, {
                                    quoteItems: quoteItems
                                })
                                    .then((retour) => {
                                        if(retour.request.responseURL.includes("connexion"))
                                        {
                                            location.href = retour.request.responseURL+"?message=unactivity";
                                        }
                                        else {
                                            this.$root.$emit('set-loading', false);
                                            EventBus.$emit("header-button-action", {step: 'edit', state: true});
                                            EventBus.$emit("header-button-action", {step: 'order', state: true});
                                            EventBus.$emit("send-quote-id", this.quoteId);
                                        }
                                    })
                                    .catch(error => {
                                        this.$root.$emit('set-loading', false);
                                        console.warn(error);
                                    })
                            }
                        })
                        .catch(error => {
                            this.$root.$emit('set-loading', false);
                            console.warn(error);
                        })
                }
            },
            toggleDeleteModal: function (id) {
                this.openDeleteModal = true;
                this.modalItemId = id;
            },
            deleteItem: function (id) {
                for(let i = 0 ; i < this.quoteItems.length ; i++) {
                    if(this.quoteItems[i].id === id) {
                        this.$root.$emit('set-loading', true);
                        axios.delete(this.apiQuoteItemUrl + '/' + id)
                            .then(response => {
                                if(response.request.responseURL.includes("connexion"))
                                {
                                    location.href = response.request.responseURL+"?message=unactivity";
                                }
                                else {
                                    this.$root.$emit('set-loading', false);
                                    this.errorDelete = response.data;
                                    EventBus.$emit("send-action-id", {actionId: id, rowIndex: i});
                                    this.quoteItems.splice(i, 1);
                                    this.modalItemId = 0;
                                    console.log(this.quoteItems);
                                    EventBus.$emit('refresh-items', this.quoteItems);
                                    this.majLabelCarpentry();
                                }
                            })
                    }
                }
            },
            duplicateItem: function (id) {
                this.quoteItems.find( item => {
                    if(item.id === id) {
                        if(item.origin === 0) {
                            this.duplicateImportedItem(item);
                        } else if(item.origin === 1) {
                            this.duplicateImportedItem(item);
                        } else {
                            item.id = 0;
                            this.addCustomItem(item)
                        }
                    }
                })
            },
            toggleUpdateModal: function (id, action = -1) {
                this.modalItemId = id;
                this.isDuplicateLandmarkUpdateLandMark = false;
                this.quoteItems.find( item => {
                    if(item.id === id) {
                        this.modalItem = item;
                        this.currentItemOrigin = item.origin;
                        if(item.origin===0 && action != 2)
                        {
                            this.addItemModal = true;
                            document.getElementById("configurationFrame").setAttribute("src",(this.apiConfigurateurUrl+this.importedLandmark.toUpperCase()+"?Cc="+this.codeClient+"&chantier="+this.projectId+"&itemId="+item.id+"&Wks=WKS_MINCO_2019"));

                        }
                        else {
                            this.openUpdateModal = true;
                        }
                    }
                });

            },
            updateItem: function (item) {
                let _repereRegex = /^[A-Z0-9]{1,6}$/

                this.isDuplicateLandmark = false;
                this.isErrorSyntaxeItem = false;
                if(this.$refs.modalInputLandmark.value !== "" && _repereRegex.test(this.$refs.modalInputLandmark.value.toUpperCase()) === true) {


                    if(this.quoteItems.find((object) => object.landmark == this.$refs.modalInputLandmark.value.toUpperCase() && object.landmark != item.landmark)) {
                        this.isDuplicateLandmarkUpdateLandMark = true;
                        return;
                    }
                    else
                    {
                        this.isDuplicateLandmarkUpdateLandMark = false;
                    }

                    if(!this.isDuplicateLandmarkUpdateLandMark)
                    {
                        if (this.$refs.modalInputLandmark) {
                            item.landmark = this.$refs.modalInputLandmark.value.toUpperCase()
                        }
                        if (this.$refs.modalInputCarpentry) {
                            item.carpentry = this.$refs.modalInputCarpentry.value
                        }
                        if (this.$refs.modalInputPurchasingExTax) {
                            item.purchasingPriceExTax = this.$refs.modalInputPurchasingExTax.value
                        }
                        if (this.$refs.modalInputImportedQuote) {
                            item.importedQuote = this.$refs.modalInputImportedQuote.value
                        }

                        this.$root.$emit('set-loading', true);
                        axios.put(this.apiQuoteItemUrl + '/' + item.id, item)
                            .then((response) => {
                                if(response.request.responseURL.includes("connexion"))
                                {
                                    location.href = response.request.responseURL+"?message=unactivity";
                                }
                                else {
                                    this.$root.$emit('set-loading', false);
                                    this.openUpdateModal = false;
                                    this.errorSave = 0;
                                    this.openSaveModal = true;
                                    EventBus.$emit('refresh-items', this.quoteItems);
                                }
                            });
                    }
                }
                else
                {
                    this.isErrorSyntaxeItem = true;
                }
            },
            calculateItemSellingPrice: function (purchasingPrice, marginRatio, laying) {
                if(marginRatio === 0 || marginRatio === null) {
                    return (parseFloat(purchasingPrice) + parseFloat(laying));
                } else {
                    return ((parseFloat(purchasingPrice) * parseFloat(marginRatio)) + parseFloat(laying));
                }
            },
            calculateCustomItemSellingPrice: function (quantity, sellingPrice) {
                return quantity * sellingPrice;
            },
            calculateTotalClientPriceExtTax: function() {
                this.totalClientPriceExTax = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        if(this.quoteItems[i].purchasingPriceExTax !== null) {
                            this.totalClientPriceExTax = this.totalClientPriceExTax + (parseFloat(this.quoteItems[i].purchasingPriceExTax) * this.quoteItems[i].quantity);
                        }
                    }
                }
            },
            calculateTotalLaying: function() {
                this.totalLaying = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        if(this.quoteItems[i].laying !== null) {
                            this.totalLaying = parseFloat(this.totalLaying) + (parseFloat(this.quoteItems[i].laying) * parseInt(this.quoteItems[i].quantity));
                        }
                    }
                }
            },
            calculateTotalMargin: function() {
                this.totalMarginRatio = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        if(this.quoteItems[i].marginRatio !== null && this.quoteItems[i].purchasingPriceExTax !== null) {
                            this.totalMarginRatio = this.totalMarginRatio + ((parseFloat(this.quoteItems[i].purchasingPriceExTax) * (this.quoteItems[i].marginRatio - 1)) * this.quoteItems[i].quantity);
                        }
                    }
                }
            },
            calculateTotalClientExTax: function() {
                this.totalClientExTaxPrice = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        let sellingPrice = 0;
                        if( this.quoteItems[i].origin < 2) {
                            if(this.quoteItems[i].marginRatio > 0) {
                                sellingPrice = ((parseFloat(this.quoteItems[i].purchasingPriceExTax) * this.quoteItems[i].marginRatio) + parseFloat(this.quoteItems[i].laying)) * this.quoteItems[i].quantity;
                            } else {
                                sellingPrice = (parseFloat(this.quoteItems[i].purchasingPriceExTax) + parseFloat(this.quoteItems[i].laying)) * this.quoteItems[i].quantity;
                            }
                        } else {
                            sellingPrice = this.quoteItems[i].sellingPrice * this.quoteItems[i].quantity;
                        }
                        this.totalClientExTaxPrice = (this.totalClientExTaxPrice + sellingPrice);

                    }
                    this.totalClientExTaxPrice = this.totalClientExTaxPrice - this.clientDiscount;
                }
            },
            calculateTotalClientVAT: function() {
                this.totalClientPriceVAT = 0;
                if(this.quoteItems && this.quoteItems.length > 0) {
                    for(let i = 0 ; i < this.quoteItems.length ; i++) {
                        if(this.quoteItems[i].vat !== null) {
                            let sellingPriceTTC = 0;
                            if( this.quoteItems[i].origin < 2) {
                                if(this.quoteItems[i].marginRatio > 0) {
                                    let sellingPriceHT = (parseFloat(this.quoteItems[i].purchasingPriceExTax) * this.quoteItems[i].marginRatio) + parseFloat(this.quoteItems[i].laying);
                                    sellingPriceTTC = (sellingPriceHT * (this.quoteItems[i].vat / 100)) * this.quoteItems[i].quantity;
                                } else {
                                    let sellingPriceHT = parseFloat(this.quoteItems[i].purchasingPriceExTax) + parseFloat(this.quoteItems[i].laying);
                                    sellingPriceTTC = (sellingPriceHT * (this.quoteItems[i].vat / 100)) * this.quoteItems[i].quantity;
                                }
                            } else {
                                sellingPriceTTC = (this.quoteItems[i].sellingPrice * (this.quoteItems[i].vat / 100)) * this.quoteItems[i].quantity;
                            }
                            this.totalClientPriceVAT = this.totalClientPriceVAT + sellingPriceTTC;
                        }
                    }
                }
            },
            calculateTotalClientIncTax: function() {
                this.totalClientIncTaxPrice = (this.totalClientExTaxPrice + this.totalClientPriceVAT);
            },
            updateOnMarginChange: function () {
                this.calculateTotalMargin();
                this.calculateTotalClientExTax();
                this.calculateTotalClientIncTax();
                this.calculateTotalClientVAT();
            },
            updateOnLayingChange: function () {
                this.calculateTotalLaying();
                this.calculateTotalClientExTax();
                this.calculateTotalClientIncTax();
                this.calculateTotalClientVAT();
            },
            updateOnQuantityChange: function (item) {
                if (item.quantity != '') {
                  item.quantity = Math.max(item.quantity, 1);
                }
                this.calculateTotalClientPriceExtTax();
                this.calculateTotalClientIncTax();
                this.calculateTotalLaying();
                this.calculateTotalMargin();
                this.calculateTotalClientVAT();
                this.calculateTotalClientExTax();
            },
            updateOnQuantityBlur: function (item) {
                if (item.quantity == '') {
                  item.quantity = 1;
                }
                this.updateOnQuantityChange(item);
            },
            updateOnSellingPriceChange: function () {
                this.calculateTotalClientExTax();
                this.calculateTotalClientIncTax();
                this.calculateTotalClientVAT();
            },
            setActionOnItem: function (value) {
                if(value.action === 0) {
                    this.duplicateItem(value.itemId);
                } else if (value.action === 1) {
                    this.toggleDeleteModal(value.itemId);
                } else if (value.action === 2) {
                    this.toggleUpdateModal(value.itemId, value.action);
                } else {
                    this.toggleUpdateModal(value.itemId, value.action);
                }
            },
            alignTop: function () {
                if(this.isTextArea) {
                    return 'class';
                }
            },
            toggleDevisIncomplet: function()
            {
                this.openDevisIncompletModal= true;
            },
            toggleAlert: function()
            {
                this.openAlertModal= true;
            },
            onFileInput: function()
            {
                if (this.$refs.docInput.files[0]) {
                    this.$refs.docFile.value = this.$refs.docInput.files[0].name;
                    this.docFile = this.$refs.docInput.files[0];
                    this.docFileName = this.$refs.docInput.files[0].name;

                }
            },
            sendDevisIncomplet: function()
            {
                let data = new FormData();
                if (this.$refs.docInput.files[0]) {
                    console.log("test");
                    data.set('docFile', this.docFile, this.docFile.name);
                    data.append('docName', this.docFileName);
                }
                else
                {
                    console.log("test2");
                    data.append('docFile', "");
                    data.append('docName', "");
                }
                data.append("demande", this.demande);
                data.append("idProject", this.projectId);
                this.$root.$emit('set-loading', true);
                axios.post(this.apiQuoteIncompleteUrl , data)
                    .then(response => {
                        if(response.request.responseURL.includes("connexion"))
                        {
                            location.href = response.request.responseURL+"?message=unactivity";
                        }
                        else {
                            this.$root.$emit('set-loading', false);
                            this.error = response.data;
                        }
                    });
            },
            onHidden: function()
            {
                this.error=-1;
            },
            onHiddenDelete: function()
            {
                this.errorDelete= -1;
            },
            onHiddenSave: function()
            {
                this.errorSave=-1;
            },
            sendAlert: function()
            {

                let data = new FormData();
                let dateChosen = new Date(this.dateAlert).setHours(this.heureAlert.HH,this.heureAlert.mm,0,0);
                //dateChosen = dateChosen.setHours(this.heureAlert.HH,this.heureAlert.mm,0,0);
                data.append("date", dateChosen/1000);
                data.append("idProject", this.projectId);
                axios.post(this.apiQuoteAlertUrl,data).then(response=>{
                    if(response.request.responseURL.includes("connexion"))
                    {
                        location.href = response.request.responseURL+"?message=unactivity";
                    }
                    else {
                        this.errorAlert = response.data;
                        this.chosenDate = this.formatTimeJS(new Date(this.dateAlert).setHours(this.heureAlert.HH, this.heureAlert.mm, 0, 0));
                    }

                });

            },
            formatTime: function (timestamp) {
                let date = new Date(timestamp * 1000);
                let dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
                let MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
                return dd + '/' + MM + '/' + date.getFullYear();
            },
            formatTimeJS: function (timestamp) {
                let date = new Date(timestamp);
                let dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
                let MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth()+1);
                return dd + '/' + MM + '/' + date.getFullYear();
            },
            goToTop: function()
            {
                window.setTimeout(()=>window.document.getElementById("topStep").scrollIntoView({
                    behavior: 'smooth'
                }),0);
            },
            selectGamme: function()
            {
                switch(this.importedCarpentry)
                {
                    case 'Coulissant' :
                        //this.importedGammeLabels = this.importedGammeLabelsCoulissant;
                        this.importedGammeLabels = [];
                        break;
                    case 'Fenêtre' :
                        //this.importedGammeLabels = this.importedGammeLabelsFenetre;
                        this.importedGammeLabels = [];
                        break;
                    case 'Porte d\'entrée' :
                    case 'Coffre demi-linteau':
                        this.importedGammeLabels = [];
                        break;
                    default:
                        this.importedGammeLabels = [];
                        //this.importedGammeLabels = this.importedGammeLabelsCoulissant;
                        break;
                }

            },
            clearConfigurationFrame: function() {
                var win = document.getElementById('configurationFrame').contentWindow;
                win.postMessage('USER_CLOSE_IFRAME',"*");
                return false;
            },

            majLabelCarpentry: function()
            {


                    this.filteredCarpentryLabels =  this.importedCarpentryLabels.filter((carpentry) =>{
                        let items;
                        if(this.quoteItems.length >0)
                            items = this.quoteItems;
                        else
                        {
                            if(this.project.quotes.length > 0)
                                items = this.project.quotes[this.project.quotes.length - 1].items;
                            else
                                items = [];
                        }

                        if(items.length == 0)
                        {
                            this.hasCoffreData = "0";
                            this.hasntCoffreData="1";
                            this.importedGammeLabels = this.importedGammeLabelsCoulissant;
                        }
                        else
                        {
                            items.filter((item)=>{
                               if(item.carpentry.includes("Coffre demi-linteau"))
                               {
                                   this.hasCoffreData = "1";
                               }
                            });
                        }
                       // console.log(this.hasCoffreData+" / "+ this.hasntCoffreData);
                    if(this.hasCoffreData=="1" )
                    {
                        if( carpentry == "Coffre demi-linteau"  && items.length > 0)
                        {
                            this.importedGammeLabels = [];
                            console.log(this.importedGammeLabels);
                            return carpentry;
                        }
                    }
                    else if(this.hasntCoffreData=="1" && items.length > 0)
                    {
                        if(carpentry!="Coffre demi-linteau")
                            return carpentry;
                    }
                    else
                    {
                        return carpentry;
                    }
                });
            }

        }
    }
</script>
