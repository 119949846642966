<script>
    import axios from 'axios';

    export default {
        name: 'AppLibraryCommonModal',
        data: function () {
            return {
                confirmDeleteModal: false,
                deleteApiUrl: '',
                deleteMediaId: 0
            }
        },
        methods: {
            toggleConfirmDeleteModal: function(apiUrl, id) {
                this.deleteApiUrl = apiUrl;
                this.deleteMediaId = id;
                this.confirmDeleteModal = !this.confirmDeleteModal;
            },
            checkBeforeDelete: function() {
                axios.delete(this.deleteApiUrl + '/' + this.deleteMediaId)
                    .then(()=> {
                        let indexOfMediaDeleted = this.items.findIndex((item) => item.id === +this.deleteMediaId);
                        this.items.splice(indexOfMediaDeleted, 1);
                    })
                    .finally(() => {
                        this.confirmDeleteModal = false;
                        this.deleteApiUrl = "";
                        this.deleteMediaId = 0;
                        location.reload();
                    })
            },
        }
    }
</script>