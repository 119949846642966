<script>
    import AppPagination from '../common/AppPagination';
    import {EventBus} from '../../event';
    import axios from 'axios';
    export default {
        name: 'AppQuoteRequestList',
        props: {
            quoteRequests: {
                type: Array
            },
            sellers: {
                type: Array
            },
            headers: {
                type: Object
            },
            sendUrl: {
                type: String
            },
            getListRequest: {
                type: String
            }
        },
        components: { AppPagination },
        data: function () {
            return {
                displayCount: 50,
                fields: [],
                items: [],
                filteredItems: [],
                filters: [],
                displayModalTrigger: false,
                emailToSend: "",
                idRequest : 0,
                errorSend: 0,
                quoteRequestsDisplayed:[],
                searchTerm: '',
                status:0,
                commercial:0,
            }
        },
        watch: {
//            searchTerm: function (val) {
//                let tempArray = this.quoteRequests.filter((quoteRequest) => {
//                    console.log(quoteRequest);
//                    return (
//                        (quoteRequest.importedUser.firstname.toLowerCase().includes(val.toLowerCase())) ||
//                        (quoteRequest.importedUser.lastname.toLowerCase().includes(val.toLowerCase())) ||
//                        (quoteRequest.seller && quoteRequest.seller.lastname.toLowerCase().includes(val.toLowerCase())) ||
//                        (quoteRequest.seller && quoteRequest.seller.firstname.toLowerCase().includes(val.toLowerCase()))
//                    );
//                });
//
//                this.items = tempArray.map(quoteRequest => ({
//                    id: quoteRequest.id,
//                    enquirer: quoteRequest.importedUser.firstname + ' ' + quoteRequest.importedUser.lastname,
//                    project_type: quoteRequest.quoteRequestProject.work_type,
//                    project_zipcode: quoteRequest.importedUser.zipcode,
//                    created_at: this.formatDate(quoteRequest.created_at),
//                    sold_on:(quoteRequest.status===7) ? this.formatDate(quoteRequest.updated_at) : "",
//                    followed_by: quoteRequest.seller ? quoteRequest.seller.lastname+" "+quoteRequest.seller.firstname : '',
//                    assigned_to: quoteRequest.client ? quoteRequest.client.name : '',
//                    client_id: quoteRequest.client ? quoteRequest.client.id : 0,
//                    seller_id: quoteRequest.seller ? quoteRequest.seller.id : 0,
//                    // STATUS_ACCEPTED = 1
//                    // STATUS_ASSIGNED = 2
//                    // STATUS_CANCELLED = 3
//                    // STATUS_FAILED = 4
//                    // STATUS_NEW = 5
//                    // STATUS_REFUSED = 6
//                    // STATUS_SUCCEEDED = 7
//                    status: quoteRequest.status,
//                    date_create: quoteRequest.created_at
//                    })
//                )
//
//                this.filteredItems = this.items;
//            },
        },
        mounted: function () {

            this.quoteRequestsDisplayed = this.quoteRequests;

            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
            EventBus.$on('filtered-data', this.populateFilters);

        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
            EventBus.$off('filtered-data', this.populateFilters);
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push(
                        {
                            key: key,
                            label: this.headers[key],
                            sortable: (key !== 'action')
                        }
                    )
                }
            },
            searchTermFunction: function () {

                this.$root.$emit('set-loading', true);
                //console.log(e.target.value);

                axios.post(this.getListRequest,{"status":this.status,"search":this.searchTerm} ).then(response=>{
                    let data = response.data;

                    // this.items = data.map(quoteRequest =>
                    //     ({
                    //         id: quoteRequest.id,
                    //         enquirer: quoteRequest.importedUser.firstname + ' ' + quoteRequest.importedUser.lastname,
                    //         project_type: quoteRequest.quoteRequestProject.work_type,
                    //         project_zipcode: quoteRequest.importedUser.zipcode,
                    //         created_at: this.formatDate(quoteRequest.created_at),
                    //         followed_by: quoteRequest.seller ? quoteRequest.seller.lastname+" "+quoteRequest.seller.firstname : '',
                    //         sold_on:(quoteRequest.status===7) ? this.formatDate(quoteRequest.updated_at) : "",
                    //         assigned_to: quoteRequest.client ? quoteRequest.client.name : '',
                    //         client_id: quoteRequest.client ? quoteRequest.client.id : 0,
                    //         seller_id: quoteRequest.seller ? quoteRequest.seller.id : 0,
                    //         description: (!!(quoteRequest.quoteRequestLogs.find(log=>{
                    //             return log.action===14;
                    //         }))) ? "Oui" : "Non",
                    //         contact_taken: quoteRequest.contact_taken ? "Oui" : "Non",
                    //         quote_sent: quoteRequest.quote_sent ? "Oui" : "Non",
                    //         // STATUS_ACCEPTED = 1
                    //         // STATUS_ASSIGNED = 2
                    //         // STATUS_CANCELLED = 3
                    //         // STATUS_FAILED = 4
                    //         // STATUS_NEW = 5
                    //         // STATUS_REFUSED = 6
                    //         // STATUS_SUCCEEDED = 7
                    //         status: quoteRequest.status,
                    //         date_create: quoteRequest.created_at
                    //     })
                    // );
                    this.filteredItems = data;
                    this.quoteRequestsDisplayed = data;
                    this.quoteRequests = this.items;
                    EventBus.$emit('activated-filters', this.items);
                    this.$root.$emit('set-loading', false);
                }).catch(error => {
                    console.warn(error);
                });
            },
            populateTableItems: function (e) {
                    console.log(e);
                    console.time("mapping");
                    // this.items = e.map(quoteRequest =>
                    //     ({
                    //         id: quoteRequest.id,
                    //         enquirer: quoteRequest.importedUser.firstname + ' ' + quoteRequest.importedUser.lastname,
                    //         project_type: quoteRequest.quoteRequestProject.work_type,
                    //         project_zipcode: quoteRequest.importedUser.zipcode,
                    //         created_at: this.formatDate(quoteRequest.created_at),
                    //         sold_on:(quoteRequest.status===7) ? this.formatDate(quoteRequest.updated_at) : "",
                    //         followed_by: quoteRequest.seller ? quoteRequest.seller.lastname+" "+quoteRequest.seller.firstname : '',
                    //         assigned_to: quoteRequest.client ? quoteRequest.client.name : '',
                    //         client_id: quoteRequest.client ? quoteRequest.client.id : 0,
                    //         seller_id: quoteRequest.seller ? quoteRequest.seller.id : 0,
                    //         description: (!!(quoteRequest.quoteRequestLogs.find(log=>{
                    //             return log.action===14;
                    //         }))) ? "Oui" : "Non",
                    //         contact_taken: quoteRequest.contact_taken ? "Oui" : "Non",
                    //         quote_sent: quoteRequest.quote_sent ? "Oui" : "Non",
                    //         // STATUS_ACCEPTED = 1
                    //         // STATUS_ASSIGNED = 2
                    //         // STATUS_CANCELLED = 3
                    //         // STATUS_FAILED = 4
                    //         // STATUS_NEW = 5
                    //         // STATUS_REFUSED = 6
                    //         // STATUS_SUCCEEDED = 7
                    //         status: quoteRequest.status,
                    //         date_create: quoteRequest.created_at
                    //     })
                    // );
                    console.timeEnd("mapping");
                        this.filteredItems = e;
                        console.log(this.filteredItems);
            },
            populateFilters: function (e) {
                    let statusFilter = e.map(quoteRequest => quoteRequest.status);
                    let sellerFilter = [];
                    let clientFilter=[];
                    e.forEach(function (quoteRequest) {
                      console.log(e);
//                        if (quoteRequest.seller) {
//                            let found = sellerFilter.find((element) => {
//                                return element.id == quoteRequest.seller.id;
//                            });
//                            if (!found) {
//                                sellerFilter.push({
//                                    id: quoteRequest.seller.id,
//                                    name: quoteRequest.seller.firstname + ' ' + quoteRequest.seller.lastname
//                                });
//                            }
//                        }
                        if (quoteRequest.client) {
                            let found = clientFilter.find((element) => {
                                return element.id == quoteRequest.client.id;
                            });
                            if (!found) {
                                clientFilter.push({
                                    id: quoteRequest.client.id,
                                    name: quoteRequest.client.name
                                });
                            }
                        }
                    });

                    this.sellers.forEach(function (seller){
                            let found = sellerFilter.find((element) => {
                                return element.id == seller.id;
                            });
                            if (!found) {
                                sellerFilter.push({
                                    id: seller.id,
                                    name: seller.firstname + ' ' + seller.lastname
                                });
                            }
                    });

//                e.map(quoteRequest => {
//                        if(quoteRequest.seller) {
//                            console.log(quoteRequest);
//                            sellerFilter.push(this.getSellerInfo(quoteRequest.seller.seller_code));
//                            return this.getSellerInfo(quoteRequest.seller.seller_code);
//                        }
//                    }
//                );



//                    let clientFilter = e.map(quoteRequest => {
//                            if (quoteRequest.client) {
//                                return {
//                                    id: quoteRequest.client.id,
//                                    name: quoteRequest.client.name
//                                }
//                            }
//                        }
//                    );
                    this.filters.statuses = [...new Set(statusFilter)];
                    this.filters.sellers = [...new Set(sellerFilter)];
                    this.filters.clients = [...new Set(clientFilter)];
            },
            onStatusFilter: function (e) {
                this.$root.$emit('set-loading', true);
                //console.log(e.target.value);


                axios.post(this.getListRequest,{"status":e.target.value,"search":this.search,'seller':this.commercial} ).then(response=>{
                    let data = response.data;
                     console.log(data);
                    // this.items = data.map(quoteRequest =>
                    //     ({
                    //         id: quoteRequest.id,
                    //         enquirer: quoteRequest.importedUser.firstname + ' ' + quoteRequest.importedUser.lastname,
                    //         project_type: quoteRequest.quoteRequestProject.work_type,
                    //         project_zipcode: quoteRequest.importedUser.zipcode,
                    //         created_at: this.formatDate(quoteRequest.created_at),
                    //         followed_by: quoteRequest.seller ? quoteRequest.seller.lastname+" "+quoteRequest.seller.firstname : '',
                    //         sold_on:(quoteRequest.status===7) ? this.formatDate(quoteRequest.updated_at) : "",
                    //         assigned_to: quoteRequest.client ? quoteRequest.client.name : '',
                    //         client_id: quoteRequest.client ? quoteRequest.client.id : 0,
                    //         seller_id: quoteRequest.seller ? quoteRequest.seller.id : 0,
                    //         description: (Array.prototype.forEach.call(quoteRequest.quoteRequestLogs, child => {
                    //             if (child.action==14) { return "Oui" }
                    //         }) == "Oui" ? "Oui" : "Non" ),
                    //         contact_taken: quoteRequest.contact_taken ? "Oui" : "Non",
                    //         quote_sent: quoteRequest.quote_sent ? "Oui" : "Non",
                    //         // STATUS_ACCEPTED = 1
                    //         // STATUS_ASSIGNED = 2
                    //         // STATUS_CANCELLED = 3
                    //         // STATUS_FAILED = 4
                    //         // STATUS_NEW = 5
                    //         // STATUS_REFUSED = 6
                    //         // STATUS_SUCCEEDED = 7
                    //         status: quoteRequest.status,
                    //         date_create: quoteRequest.created_at
                    //     })
                    // );
                    this.filteredItems = e;
                    this.quoteRequestsDisplayed = data;
                    this.quoteRequests = data;
                    EventBus.$emit('activated-filters', this.items);
                    this.$root.$emit('set-loading', false);
                }).catch(error => {
                    console.warn(error);
                });

//                if (value === 0) return this.filteredItems = this.items;
//                this.filteredItems = this.items.filter(item => {
//                    return item.status === value;
//                });
            },
            onSellerFilter: function (e) {
//                let value = +e.target.value;
//                if (value === 0) return this.filteredItems = this.items;
//                this.filteredItems = this.items.filter(item => {
//                    return item.seller_id === value
//                });
                this.$root.$emit('set-loading', true);
                //console.log(e.target.value);


                axios.post(this.getListRequest,{"status":this.status,"search":this.search,"seller":e.target.value} ).then(response=>{
                    let data = response.data;
                    // this.items = data.map(quoteRequest =>
                    //     ({
                    //         id: quoteRequest.id,
                    //         enquirer: quoteRequest.importedUser.firstname + ' ' + quoteRequest.importedUser.lastname,
                    //         project_type: quoteRequest.quoteRequestProject.work_type,
                    //         project_zipcode: quoteRequest.importedUser.zipcode,
                    //         created_at: this.formatDate(quoteRequest.created_at),
                    //         followed_by: quoteRequest.seller ? quoteRequest.seller.lastname+" "+quoteRequest.seller.firstname : '',
                    //         sold_on:(quoteRequest.status===7) ? this.formatDate(quoteRequest.updated_at) : "",
                    //         assigned_to: quoteRequest.client ? quoteRequest.client.name : '',
                    //         client_id: quoteRequest.client ? quoteRequest.client.id : 0,
                    //         seller_id: quoteRequest.seller ? quoteRequest.seller.id : 0,
                    //         description: '',
                    //         contact_taken: quoteRequest.contact_taken ? "Oui" : "Non",
                    //         quote_sent: quoteRequest.quote_sent ? "Oui" : "Non",
                    //         // STATUS_ACCEPTED = 1
                    //         // STATUS_ASSIGNED = 2
                    //         // STATUS_CANCELLED = 3
                    //         // STATUS_FAILED = 4
                    //         // STATUS_NEW = 5
                    //         // STATUS_REFUSED = 6
                    //         // STATUS_SUCCEEDED = 7
                    //         status: quoteRequest.status,
                    //         date_create: quoteRequest.created_at
                    //     })
                    // );
                    this.filteredItems = e;
                    this.quoteRequestsDisplayed = data;
                    this.quoteRequests = this.items;
                    EventBus.$emit('activated-filters', this.items);
                    this.$root.$emit('set-loading', false);
                }).catch(error => {
                    console.warn(error);
                });
            },
            onClientFilter: function (e) {
                let value = +e.target.value;
                if (value === 0) return this.filteredItems = this.items;
                this.filteredItems = this.items.filter(item => {
                    return item.client_id === value
                });
            },
            getSellerInfo: function (sellerCode) {
                if(sellerCode!=undefined)
                {
                    let contact;
                    contact = this.sellers.filter(seller => (seller.code === sellerCode));
                    return {
                        id: contact[0].id,
                        name: contact[0].firstname + ' ' + contact[0].lastname
                    }
                }
            },
            getStatusCount: function (status) {
                return this.items.filter(item => item.status === status).length;
            },
            formatDate: function (date) {
                let d = new Date(date);
                let dd = (d.getUTCDate() < 10 ? '0' : '') + d.getUTCDate();
                let MM = ((d.getUTCMonth() + 1) < 10 ? '0' : '') + (d.getUTCMonth() + 1);
                let HH = (d.getUTCHours() < 10 ? '0' : '') + d.getUTCHours();
                let mm = (d.getUTCMinutes() < 10 ? '0' : '') + d.getUTCMinutes();
                //return dd + '/' + MM + '/' + d.getFullYear() + ' - ' + HH + ':' + mm;
                return d.getFullYear() + '/' + MM + '/' + dd + ' - ' + HH + ':' + mm;
            },
            sendByMailTrigger: function(idCible)
            {
                this.displayModalTrigger = true;
                this.idRequest = idCible;
            },
            sendRequest: function(e)
            {
                axios.post(this.sendUrl, {"email" : this.emailToSend,"idRequest": this.idRequest})
                .then((response) => {
                    if(response.status === 200) {
                        this.hasResponse = true;
                        this.responseData = response.data;
                        if(this.responseData===true)
                            this.errorSend = 1;
                        else
                            this.errorSend = 2;
                    } else {
                        this.errors = response.data.errors;
                    }
                })
                .catch((error) => {
                    console.warn(error)
                })
            },
            onHiddenSend: function()
            {
                this.errorSend = 0;
            }

        }
    }
</script>
