var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "border-0 p-0", attrs: { "data-label": "Action" } },
    [
      _c(
        "b-dropdown",
        {
          staticClass: "project__actions-toggle",
          attrs: {
            id: "item_" + _vm.rowIndex,
            variant: "link",
            "no-caret": "",
            right: "",
          },
        },
        [
          _c("template", { slot: "button-content" }, [
            _c("i", {
              staticClass: "responsive-table__icon icon-menu-dropdown",
            }),
          ]),
          _vm._v(" "),
          _vm.audience === "projectItem"
            ? [
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(0)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon-magnifier" }),
                    _vm._v(" " + _vm._s(_vm.labels[0]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(1)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon-shopping-cart" }),
                    _vm._v(" " + _vm._s(_vm.labels[1]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(2)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon-duplicate-image" }),
                    _vm._v(" " + _vm._s(_vm.labels[2]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(3)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon-waste-bin" }),
                    _vm._v(" " + _vm._s(_vm.labels[3]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(4)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon-verified" }),
                    _vm._v(" " + _vm._s(_vm.labels[4]) + " "),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.audience === "productItem"
            ? [
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(0)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-duplicate-image" }),
                    _vm._v(" " + _vm._s(_vm.labels[6]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(1)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-waste-bin" }),
                    _vm._v(" " + _vm._s(_vm.labels[5]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(2)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-file" }),
                    _vm._v(" " + _vm._s(_vm.labels[7]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(3)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-file" }),
                    _vm._v(" " + _vm._s(_vm.labels[12]) + " "),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.audience === "quoteItem"
            ? [
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(0)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-duplicate-image" }),
                    _vm._v(" " + _vm._s(_vm.labels[6]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(1)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-waste-bin" }),
                    _vm._v(" " + _vm._s(_vm.labels[5]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(2)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-file" }),
                    _vm._v(" " + _vm._s(_vm.labels[7]) + " "),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.audience === "archiveItem"
            ? [
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(0)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-next" }),
                    _vm._v(" " + _vm._s(_vm.labels[8]) + " "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(1)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon icon-waste-bin" }),
                    _vm._v(" " + _vm._s(_vm.labels[9]) + " "),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.audience === "orderItem"
            ? [
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(12)
                      },
                    },
                  },
                  [_vm._v("Télécharger ARC ")]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(13)
                      },
                    },
                  },
                  [_vm._v("Télécharger xxxxx ")]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(5)
                      },
                    },
                  },
                  [_vm._v("Télécharger plan de réservation ")]
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.sendAction(14)
                      },
                    },
                  },
                  [_vm._v("Annuler la commande ")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }