<template>
  <div id="main-loader" class="loader" :class="{ 'loader--active': displayLoader}">
    <div class="cssload-loader">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      <div class="loader__label"></div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'AppLoader',
        props: {

        },
        data: function () {
          return {
            displayLoader: false
          }
        },
        created: function () {

        },
        mounted: function () {
            this.$root.$on('set-loading', this.listenToLoaderState);
        },
        beforeDestroy: function () {
            this.$root.$off('set-loading', this.listenToLoaderState);
        },
        methods: {
            listenToLoaderState: function (event) {
                this.displayLoader = event;
            }
        }
    }
</script>
