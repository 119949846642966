<script>
    import { EventBus } from '../../../event';
    import AppPagination from '../../common/AppPagination';
    import axios from 'axios';

    export default {
        name: 'AppNewsList',
        props: {
            newsCategories: {
                type: Array
            },
            newsArticles: {
                type: Array
            },
            headers: {
                type: Object
            },
        },
        components: { AppPagination },
        data: function () {
            return {
                confirmDeleteModal: false,
                deleteApiUrl: '',
                deleteArticleId: 0,
                displayCount: 50,
                fields: [],
                items: [],
                postPaginationItems: [],
                searchTerm: ''
            }
        },
        created: function () {
            this.postPaginationItems = this.newsArticles;
        },
        mounted: function () {
            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
        },
        watch: {
            searchTerm: function (val) {
                let tempArray = this.newsArticles.filter((article) => {
                    return article.title.toLowerCase().includes(val.toLowerCase());
                });
                this.items = tempArray.map(article =>
                    ({
                        id: article.id,
                        category: article.category.label,
                        article_title: article.title,
                        publication_date: (article.published_at) ? this.formatDate(article.published_at) : "",
                        expiry_date: (article.expired_at) ? this.formatDate(article.expired_at) : "",
                        created_at: this.formatDate(article.created_at),
                        status: article.status === 0 ? 0 : (article.expired_at && (this.compareDate(article.expired_at))) ? 3 : ((this.compareDate(article.published_at) ? 1 : 2))
                    })
                );
                this.postPaginationItems = tempArray;
            },
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push(
                        {
                            key: key,
                            label: this.headers[key],
                            sortable: (key !== 'action') ? true : false
                        }
                    )
                }
            },
            populateTableItems: function (e) {
                this.items = e.map(article =>
                    ({
                        id: article.id,
                        category: article.category.label,
                        article_title: article.title,
                        publication_date: (article.published_at) ? this.formatDate(article.published_at) : "",
                        expiry_date: (article.expired_at) ? this.formatDate(article.expired_at) : "",
                        created_at: this.formatDate(article.created_at),
                        status: article.status === 0 ? 0 : (article.expired_at && (this.compareDate(article.expired_at))) ? 3 : ((this.compareDate(article.published_at) ? 1 : 2))
                    })
                )
            },
            compareDate: function(date) {
                let today = Math.floor(new Date() / 1000);
                return (Math.floor(new Date(date) /1000)) < today;
            },
            onCategoryFilter: function (e) {
                let value = +e.target.value;
                if(value > -1) {
                    let tempArticleArray = this.newsArticles.filter((filter) => {
                        return filter.category.id === value;
                    });
                    this.items = tempArticleArray.map(article =>
                        ({
                            id: article.id,
                            category: article.category.label,
                            article_title: article.title,
                            publication_date: (article.published_at) ? this.formatDate(article.published_at) : "",
                            expiry_date: (article.expired_at) ? this.formatDate(article.expired_at) : "",
                            created_at: this.formatDate(article.created_at),
                            status: article.status === 0 ? 0 : (article.expired_at && (this.compareDate(article.expired_at))) ? 3 : ((this.compareDate(article.published_at) ? 1 : 2))
                        })
                    );
                    this.postPaginationItems = tempArticleArray;
                } else {
                    this.populateTableItems(this.newsArticles);
                }
            },
            toggleConfirmDeleteModal: function(apiUrl, id) {
                this.deleteApiUrl = apiUrl;
                this.deleteArticleId = id;
                this.confirmDeleteModal = !this.confirmDeleteModal;
            },
            checkBeforeDelete: function() {
                axios.delete(this.deleteApiUrl + '/' + this.deleteArticleId)
                    .then(()=> {
                        let indexOfMediaDeleted = this.items.findIndex((item) => item.id === +this.deleteArticleId);
                        this.items.splice(indexOfMediaDeleted, 1);
                    })
                    .finally(() => {
                        this.confirmDeleteModal = false;
                        this.deleteApiUrl = "";
                        this.deleteArticleId = 0;
                    })
            },
            formatFilter: function (itemFilters) {
                let array = [];
                let filter;
                array.push(itemFilters.label);
                let isLast = false;
                while(!isLast) {
                    if (itemFilters.hasOwnProperty('parent_filter')) {
                        let obj = itemFilters['parent_filter'];
                        if(obj.lvl > 0) {
                            array.push(obj.label);
                        }
                        itemFilters = obj;
                    } else {
                        isLast = true;
                    }
                }
                filter = array.reverse();
                return filter;
            },
            formatDate: function (date) {
                let d = new Date(date);
                let dd = (d.getUTCDate() < 10 ? '0' : '') + d.getUTCDate();
                let MM = ((d.getUTCMonth() + 1) < 10 ? '0' : '') + (d.getUTCMonth() + 1);
                return dd + '/' + MM + '/' + d.getFullYear();
            }
        }
    }
</script>