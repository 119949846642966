<script>
    import axios from 'axios';

    export default {
        name: 'AppPhotolibraryForm',
        data: function () {
            return {
                errors: {},
                hasResponse: false,
                responseData: '',
                contentHeight: ''
            }
        },
        mounted: function () {
          
        },
        beforeDestroy: function () {

        },
        methods: {
            checkBeforeSubmit: function (e) {
                let data = new FormData();

                for(let d of e.target) {
                    if (d.name.split('[').pop().split(']')[0] === 'gdpr') {
                        if (+d.value === 1 && d.checked) {
                            data.append('gdpr', +d.value);
                        } else if (+d.value === 0 && d.checked){
                            data.append('gdpr', +d.value);
                        }
                    } else if(d.name.split('[').pop().split(']')[0] === 'newsletter') {
                        data.append('newsletter', +d.checked);
                    } else if(d.name.split('[').pop().split(']')[0] === 'status') {
                        data.append('status', '0');
                    } else {
                        data.append(d.name.split('[').pop().split(']')[0], d.value);
                    }
                }

                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };
                console.log(data);
                axios.post(e.target.action, data, config)
                    .then((response) => {
                        if(response.status === 200) {
                            this.hasResponse = true;
                            this.responseData = response.data;

                        } else {
                            this.errors = response.data.errors;
                        }
                    })
                    .catch((error) => {
                        console.warn(error)
                    })
            }
        }
    }
</script>
