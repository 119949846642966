var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "row pt-4 new-project__content__body",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.postData.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "col-md h-100 d-none d-md-block" },
        [_vm._t("headerProject")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md h-100 d-none d-md-block" },
        [_vm._t("headerClient")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md h-100 d-none d-md-block" },
        [_vm._t("headerNoteToSelf")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-100 mb-3" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md" },
        [
          _c(
            "div",
            { staticClass: "d-block d-md-none" },
            [_vm._t("headerProjectMobile")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _vm._t("reference"),
              _vm._v(" "),
              _vm.isErrorSyntaxeItem
                ? _c(
                    "span",
                    { class: { "text-danger": _vm.isErrorSyntaxeItem } },
                    [_vm._v("non valide.")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.reference,
                    expression: "reference",
                  },
                ],
                staticClass: "form-control",
                staticStyle: { "text-transform": "uppercase" },
                attrs: {
                  type: "text",
                  id: "project_reference",
                  maxlength: "20",
                  required: "required",
                },
                domProps: { value: _vm.reference },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.reference = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _vm._t("comment"),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.comment,
                    expression: "comment",
                  },
                ],
                staticClass: "form-control",
                attrs: { rows: "7", id: "project_commentaire" },
                domProps: { value: _vm.comment },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.comment = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("required"),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md" }, [
        _c(
          "div",
          { staticClass: "d-block d-md-none mt-4" },
          [_vm._t("headerClientMobile")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm._t("clientLastname"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.clientLastname,
                  expression: "clientLastname",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "project_nomClient" },
              domProps: { value: _vm.clientLastname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.clientLastname = $event.target.value
                },
              },
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm._t("clientFirstname"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.clientFirstname,
                  expression: "clientFirstname",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "project_prenomClient" },
              domProps: { value: _vm.clientFirstname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.clientFirstname = $event.target.value
                },
              },
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm._t("clientAddress"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.clientAddress,
                  expression: "clientAddress",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "project_adresseClient" },
              domProps: { value: _vm.clientAddress },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.clientAddress = $event.target.value
                },
              },
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md form-group" },
            [
              _vm._t("clientCity"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.clientCity,
                    expression: "clientCity",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "project_villeClient" },
                domProps: { value: _vm.clientCity },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.clientCity = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md form-group" },
            [
              _vm._t("clientZipcode"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.clientZipcode,
                    expression: "clientZipcode",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "project_cpClient" },
                domProps: { value: _vm.clientZipcode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.clientZipcode = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md form-group" },
            [
              _vm._t("clientMail"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.clientMail,
                    expression: "clientMail",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "email", id: "project_mailClient" },
                domProps: { value: _vm.clientMail },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.clientMail = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md form-group" },
            [
              _vm._t("clientPhone"),
              _vm._v(" "),
              _vm._t("clientPhoneFormat"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.clientPhone,
                    expression: "clientPhone",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "tel",
                  pattern: "[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}",
                  id: "project_telClient",
                },
                domProps: { value: _vm.clientPhone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.clientPhone = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md" }, [
        _c(
          "div",
          { staticClass: "d-block d-md-none mt-4" },
          [_vm._t("headerNoteToSelfMobile")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm._t("delay"),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "project_delay" } },
              _vm._l(_vm.delays, function (delay) {
                return _c(
                  "div",
                  {
                    staticClass:
                      "form-check minco-radio minco-radio--inline mr-2 mb-2",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.chosenDelay,
                          expression: "chosenDelay",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: { type: "radio", id: "project_delay_" + delay.id },
                      domProps: {
                        value: delay.id,
                        checked: _vm._q(_vm.chosenDelay, delay.id),
                      },
                      on: {
                        change: function ($event) {
                          _vm.chosenDelay = delay.id
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "project_delay_" + delay.id },
                      },
                      [_c("span", [_vm._v(_vm._s(delay.label))])]
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm._t("materials"),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "project_materials" } },
              _vm._l(_vm.materials, function (material) {
                return _c(
                  "div",
                  {
                    staticClass:
                      "form-check minco-checkbox minco-checkbox--inline mr-2 mb-2",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.chosenMaterials,
                          expression: "chosenMaterials",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        id: "project_materials_" + material.id,
                      },
                      domProps: {
                        value: material.id,
                        checked: Array.isArray(_vm.chosenMaterials)
                          ? _vm._i(_vm.chosenMaterials, material.id) > -1
                          : _vm.chosenMaterials,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.chosenMaterials,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = material.id,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.chosenMaterials = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.chosenMaterials = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.chosenMaterials = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "project_materials_" + material.id },
                      },
                      [_c("span", [_vm._v(_vm._s(material.label))])]
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm._t("customerRequirements"),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "project_customerRequirements" } },
              _vm._l(_vm.customerRequirements, function (customerRequirement) {
                return _c(
                  "div",
                  {
                    staticClass:
                      "form-check minco-checkbox minco-checkbox--inline mr-2 mb-2",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.chosenCustomerRequirements,
                          expression: "chosenCustomerRequirements",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        id:
                          "project_customerRequirements_" +
                          customerRequirement.id,
                      },
                      domProps: {
                        value: customerRequirement.id,
                        checked: Array.isArray(_vm.chosenCustomerRequirements)
                          ? _vm._i(
                              _vm.chosenCustomerRequirements,
                              customerRequirement.id
                            ) > -1
                          : _vm.chosenCustomerRequirements,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.chosenCustomerRequirements,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = customerRequirement.id,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.chosenCustomerRequirements = $$a.concat([
                                  $$v,
                                ]))
                            } else {
                              $$i > -1 &&
                                (_vm.chosenCustomerRequirements = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.chosenCustomerRequirements = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: {
                          for:
                            "project_customerRequirements_" +
                            customerRequirement.id,
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(customerRequirement.label))])]
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row align-items-center" }, [
          _c(
            "div",
            { staticClass: "col-12 form-group" },
            [_vm._t("carpentryNumber")],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.carpentryNumber,
                  expression: "carpentryNumber",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "number",
                lang: "en-150",
                id: "project_nombreMenuiseries",
              },
              domProps: { value: _vm.carpentryNumber },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.carpentryNumber = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md form-group" },
            [
              _vm._t("quantitySurveyor"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.quantitySurveyor,
                    expression: "quantitySurveyor",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "project_metreur" },
                domProps: { value: _vm.quantitySurveyor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.quantitySurveyor = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md form-group" },
            [
              _vm._t("salesperson"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.salesperson,
                    expression: "salesperson",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "project_commercial" },
                domProps: { value: _vm.salesperson },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.salesperson = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _vm._t("personalComment"),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.personalComment,
                  expression: "personalComment",
                },
              ],
              staticClass: "form-control",
              attrs: { rows: "7", id: "project_commentairePersonnel" },
              domProps: { value: _vm.personalComment },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.personalComment = $event.target.value
                },
              },
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.status == 0
          ? _c("div", { staticClass: "form-group text-right mt-5 mr-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-rounded btn-rounded--yellow px-4",
                  attrs: { type: "submit" },
                },
                [_vm._v(_vm._s(_vm.saveLabel))]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }