<script>
    import axios from 'axios';

    export default {
        name: 'AppQuoteRequestShow',
        props: {
            quoteRequest: {
                type: Object
            },
            urlAddCommentary:  {
                type: String
            },
            isAdmin: {
                type: String
            },
        },
        data: function () {
            return {
                openAssignModal: false,
                openDelegateModal: false,
                openCancelModal: false,
                openUnassignModal: false,
                openAcceptModal: false,
                openDeclineModal: false,
                openSuccessfulSaleModal: false,
                openFailedSaleModal: false,
                openSwitchActionConfirmModal: false,
                selectedClient: 0,
                selectedSeller: 0,
                currentReason: '',
                selectReason :'',
                sellerDelegated: {},
                clientDelegated: {},
                currentStatus: 0,
                hasBeenUnassigned: 0,
                salesperson: {},
                quoteRequestLogs: [],
                seller: {},
                selectedContactTaken: false,
                selectedQuoteSent: false,
                existingCancelReason: '',
                currentOrderReference: '',
                quoteRequestData: {},
                targetModal: -1,
                switchClicked: 0,
                missingOrderReference: 0,
                missingReason: 0,
                unassigned:0,
                openCommentaryModal:false,
                commentary:'',
                missingCommentary: 0,

            }
        },
        mounted: function() {
            this.populateQuoteRequestWithProps();
            console.log(this.quoteRequestData);
        },
        methods: {
            getStatus: function () {
                this.currentStatus = this.quoteRequestData.status;
            },
            populateQuoteRequestWithProps: function () {
                this.quoteRequestData = this.quoteRequest;
                this.sellerDelegated = this.quoteRequest.seller;
                this.clientDelegated = this.quoteRequest.client;
                this.selectedContactTaken = this.quoteRequest.contact_taken;
                this.selectedQuoteSent = this.quoteRequest.quote_sent;
                for(let log of this.quoteRequest.quote_request_logs) {
                    this.existingCancelReason = (log.action === 3 && log.reason) ? log.reason : null;
                }
                this.currentOrderReference = this.quoteRequest.sale_order_ref ? this.quoteRequest.sale_order_ref : null;
                this.getStatus();
                this.getSalespersonInfo();
                this.getRequestLogs(this.quoteRequestData.quote_request_logs);
                console.log(this.clientDelegated);
            },
            getRequestLogs: function (array) {
                for(let item of array) {
                    this.existingCancelReason = (item.action === 3 && item.reason) ? item.reason : null;
                }
                this.quoteRequestLogs = array.sort(function(a, b){
                    return new Date(b.created_at) - new Date(a.created_at);
                });
            },
            getSalespersonInfo: function () {
                if (this.quoteRequestData.client) {
                    let client = this.quoteRequestData.client;
                    client.contacts.filter((contact) => {
                        if(contact.code === client.commercial_code) {
                            this.salesperson = contact
                        }
                        if(contact.code === client.seller_code) {
                            this.seller = contact
                        }
                    });
                }
            },
            onFormSubmit: function(status, modalId, type = null) {

                let form = document.getElementsByName('quote-request-assign-form')[0];
                let data = {};
                for (let key in this.quoteRequestData) {
                    if(this.quoteRequestData.hasOwnProperty(key)) {
                        let formattedKey = key.replace(/(_)./g, s => s.slice(-1).toUpperCase());
                        data[formattedKey] = this.quoteRequestData[key];
                    }
                }

                data.status = status;
                data.importedUser = this.quoteRequestData.imported_user.id;
                data.createdAt = this.formatDatePHP(this.quoteRequestData.created_at);
                data.updatedAt = this.formatDatePHP(0);

                if (status === 2) {
                    // On assign
                    if (this.selectedClient === 0 && this.selectedSeller === 0) return;
                    (type === 1) ? (data.clientId = +this.selectedClient) : (data.sellerId = +this.selectedSeller);
                } else {
                    // On accept, cancel, decline, unassign, success or fail
                    if(this.switchClicked > 0) {
                        // On contact taken or quote sent
                        data.actionFromClient = 1;
                        if(this.switchClicked === 1) {
                            data['contactActionFromClient'] = 1;
                            data.contactTaken = this.selectedContactTaken;
                        } else {
                            data['sentActionFromClient'] = 1;
                            data.quoteSent = this.selectedQuoteSent;
                        }
                    }
                    data.client = null;
                    data.seller = null;
                    if(status === 1 || status === 4 || status === 7) {
                        // on accept, fail or success
                        if(this.clientDelegated)
                            data.clientId = this.clientDelegated.id;
                        else
                            data.clientId = null;
                        data.sellerId = this.sellerDelegated.id;
                        if(status === 7) {
                            // on success
                            this.missingOrderReference = this.currentOrderReference.length === 0;
                            if(this.missingOrderReference) return;
                            data.reason = this.currentReason;
                            data.saleOrderRef = this.currentOrderReference;
                        }
                        if(status === 4) {
                            // on fail
                            this.missingReason = this.currentReason.length === 0;
                            if(this.missingReason) return;
                            data.reason = this.currentReason;
                            data.type_reason = this.selectReason;
                        }
                    } else {
                        // on cancel, decline or unassign
                        this.missingReason = this.currentReason.length === 0;
                        if(this.missingReason) return;
                        data.reason = this.currentReason;
                        if (status === 5) {
                            // on unassign
                            data.unassign = 1;
                            data.companyName = this.quoteRequestData.client.name;
                            data.companyId = this.quoteRequestData.client.id;
                        }
                        if (status === 6) {
                            // on decline
                            data.sellerEmail = this.quoteRequestData.seller.email;
                            data.companyName = this.quoteRequestData.client.name;
                            data.companyId = this.quoteRequestData.client.id;
                        }
                    }
                }

                const config = {
                    headers: { 'content-type': 'application/json' }
                };

                axios.put(form.action, data, config)
                    .then((response) => {
                        console.log(response);
                        this.quoteRequestData = response.data;
                        this.currentStatus = response.data.status;
                        if(status === 1 || status === 2 || status === 4 || status === 7) {
                            this.selectedSeller = 0;
                            if(response.data.seller) {
                                this.sellerDelegated = response.data.seller;
                            }
                            if(response.data.client) {
                                this.clientDelegated = response.data.client;
                            }
                            if(status === 2) {
                                for(let log of response.data.quote_request_logs) {
                                    this.existingCancelReason = (log.action === 3 && log.reason) ? log.reason : null;
                                }
                            } else {
                                this.curentReason = "";
                                if (status === 7) {
                                    this.currentOrderReference = response.data.sale_order_ref;
                                }
                            }
                        } else if (status === 3 || status === 5 || status === 6) {
                            this.curentReason = "";
                            if (status === 3) this.existingCancelReason = response.data.reason;
                            if (status !== 6) {
                                this.sellerDelegated = null;
                                this.clientDelegated = null;
                                if(status==5)
                                    this.unassigned = 1;
                            }
                        }
                        this.getRequestLogs(response.data.quote_request_logs);
                        if(modalId > 0) this.toggleModal(modalId);
                        if(modalId === 0) this.openSwitchActionConfirmModal = !this.openSwitchActionConfirmModal;
                    })
                    .catch((error) => {
                        console.warn(error);
                    });
            },
            submitCommentary: function(modalId){
                console.log(this.quoteRequestData);
                let status = this.quoteRequestData["status"];
                let data = {};
                for (let key in this.quoteRequestData) {
                    if(this.quoteRequestData.hasOwnProperty(key)) {
                        let formattedKey = key.replace(/(_)./g, s => s.slice(-1).toUpperCase());
                        data[formattedKey] = this.quoteRequestData[key];
                    }
                }

                data.status = status;
                data.importedUser = this.quoteRequestData.imported_user.id;
                data.createdAt = this.formatDatePHP(this.quoteRequestData.created_at);
                data.updatedAt = this.formatDatePHP(0);
                data.reason = this.commentary;

                this.missingCommentary = this.commentary.length === 0;
                const config = {
                    headers: { 'content-type': 'application/json' }
                };

                console.log(this.urlAddCommentary);

                axios.post(this.urlAddCommentary, data, config)
                    .then((response) => {
                        console.log(response);
                        this.quoteRequestData = response.data;
                        this.currentStatus = response.data.status;
                        this.getRequestLogs(response.data.quote_request_logs);
                        if(modalId > 0) this.toggleModal(modalId);
                        if(modalId === 0) this.openSwitchActionConfirmModal = !this.openSwitchActionConfirmModal;

                    })
                    .catch((error) => {
                        console.warn(error);
                    });

            },
            onFailedSale: function() {
                let form = document.getElementsByName('quote-request-assign-form')[0];
                let data = {};
                for (let key in this.quoteRequestData) {
                    if(this.quoteRequestData.hasOwnProperty(key)) {
                        let formattedKey = key.replace(/(_)./g, s => s.slice(-1).toUpperCase());
                        data[formattedKey] = this.quoteRequestData[key];
                    }
                }

                data.actionFromClient = 1;
                data.client = null;
                data.seller = null;
                data.clientId = this.clientDelegated.id;
                data.sellerId = this.sellerDelegated.id;
                data.companyName = this.quoteRequestData.client.name;
                data.companyId = this.quoteRequestData.client.id;
                data.importedUser = this.quoteRequestData.imported_user.id;
                data.createdAt = this.formatDatePHP(this.quoteRequestData.created_at);
                data.updatedAt = this.formatDatePHP(0);
                if(this.switchClicked === 1) {
                    data['contactActionFromClient'] = 1;
                    data.contactTaken = this.selectedContactTaken;
                } else {
                    data['sentActionFromClient'] = 1;
                    data.quoteSent = this.selectedQuoteSent;
                }

                const config = {
                    headers: { 'content-type': 'application/json' }
                };

                axios.put(form.action, data, config)
                    .then((response) => {
                        this.quoteRequestData = response.data;
                        this.selectedSeller = 0;
                        if (response.data.seller) {
                            this.sellerDelegated = response.data.seller;
                        }
                        if (response.data.client) {
                            this.clientDelegated = response.data.client;
                        }
                        this.openSwitchActionConfirmModal = !this.openSwitchActionConfirmModal;
                        this.getRequestLogs(response.data.quote_request_logs);
                    })
                    .catch((error) => {
                        console.warn(error);
                    })
            },
            toggleModal: function (modalId) {
                this.selectedSeller = 0;
                this.selectedClient = 0;
                switch (modalId) {
                    case (0):
                        this.openUnassignModal = !this.openUnassignModal;
                        break;
                    case (1):
                        this.openAssignModal = !this.openAssignModal;
                        break;
                    case (2):
                        this.openCancelModal = !this.openCancelModal;
                        break;
                    case (3):
                        this.openAcceptModal = !this.openAcceptModal;
                        break;
                    case (4):
                        this.openDeclineModal = !this.openDeclineModal;
                        break;
                    case (5):
                        this.missingOrderReference = false;
                        this.openSuccessfulSaleModal = !this.openSuccessfulSaleModal;
                        break;
                    case (6):
                        this.openFailedSaleModal = !this.openFailedSaleModal;
                        break;
                    case (7):
                        this.openDelegateModal = !this.openDelegateModal
                        break;
                    case (8):
                        this.openCommentaryModal = !this.openCommentaryModal
                        break;
                    default:
                }
            },
            onSwitchClick: function(item) {
                this.switchClicked  = item;
                this.openSwitchActionConfirmModal = !this.openSwitchActionConfirmModal;
            },
            resetSwitch:  function() {
                if(this.switchClicked === 1) {
                    this.selectedContactTaken = false;
                } else {
                    this.selectedQuoteSent = false;
                }
                this.openSwitchActionConfirmModal = !this.openSwitchActionConfirmModal;
            },
            formatDatePHP: function (date) {
                let d = new Date(date);
                return (d / 1000);
            },
            formatDateJS: function (timestamp) {
                let date = timestamp === 0 ? new Date.now() : new Date(timestamp);
                let dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
                let MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth()+1);
                let HH = (date.getHours() < 10 ? '0' : '') + date.getHours();
                let ii = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
                return dd + '/' + MM + '/' + date.getFullYear() + ' ' + HH + ':' + ii;
            },
        }
    }
</script>