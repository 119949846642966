<script>
    import axios from 'axios';
    export default {
        name: 'AppAccountContributor',
        components: {

        },
        props:{
            apiUpdateUrl :
            {
                type: String
            },
            apiUpdateRoleUrl:
            {
              type: String
            },
            apiAddContributor :
            {
                type: String
            },
            apiResendPassword:
            {
              type: String
            },
            newsUser :
            {
                type: String
            },
            apiSuspendUser :
            {
                type: String
            },
            apiUrlSendService:
            {
                type : String
            },
            jsonCollaborateur:
                {
                    type: Array
                }
        },
        data: function () {
            return {
                contactId : 0,
                newsletter : 0,
                openUpdateModal:false,
                openSendModal:false,
                errorSave: -1,
                errorSend: -1,
                openAddModal:false,
                idNewContritbutor: 0,
                civilityNewContributor: "",
                nameNewContributor: "",
                firstNameNewContributor: "",
                emailNewContributor:"",
                confirmationEmailNewContributor:"",
                adressNewContributor: "",
                additionnnalAdressNewContributor: "",
                zipCodeNewContributor: "",
                cityNewContributor: "",
                legalConfirmation: false,
                phoneNewContributor: "",
                mobileNewContributor: "",
                errorSuspend: -1,
                openSuspendModal: false,
                arrayCollaborateur: [],
                modalItemId: 0,
                openModalSendRight: false,
                errorSendRight: -1

            }
        },
        created: function () {
            this.arrayCollaborateur = this.jsonCollaborateur;
        },
        computed: {

        },
        methods : {
            addContributor: function()
            {
                this.civilityNewContributor = "";
                this.nameNewContributor = "";
                this.firstNameNewContributor = "";
                this.emailNewContributor = "";
                this.confirmationEmailNewContributor = "";
                this.phoneNewContributor = "";
                this.mobileNewContributor = "";
                this.adressNewContributor = "";
                this.additionnnalAdressNewContributor = "";
                this.zipCodeNewContributor = "";
                this.cityNewContributor = "";
                this.idNewContritbutor = 0;
                this.legalConfirmation = false;

                this.openAddModal=true;
            },
            onHiddenAdd: function()
            {
                this.openAddModal=false;
            },
            onHiddenUpdate: function()
            {
                this.openUpdateModal=false;
                window.location.reload();
            },
            onHiddenSend: function()
            {
                this.openSendModal=false;
                this.errorSend =-1;
            },
            onHiddenSuspend: function()
            {
                this.openSuspendModal=false;
                this.errorSuspend =-1;
            },
            onHiddenSendRights: function()
            {
                this.openModalSendRight = false;
                this.errorSendRight=-1;
            },
            saveContributor: function()
            {

                axios.post(this.apiAddContributor,{

                    civility: this.civilityNewContributor,
                    name: this.nameNewContributor,
                    firstname: this.firstNameNewContributor,
                    email: this.emailNewContributor,
                    address: this.adressNewContributor,
                    address2: this.additionnnalAdressNewContributor,
                    zipCode: this.zipCodeNewContributor,
                    city: this.cityNewContributor,
                    phone:this.phoneNewContributor,
                    mobile: this.mobileNewContributor,
                    id: this.idNewContritbutor

                }).then(response => {
                        console.log(response.data);
                    if(response.data.id) {
                        if (+response.data.id > 0) {
                            let index = this.arrayCollaborateur.findIndex((item) => item.id === +response.data.id);
                            console.log(index);
                            if (index == -1) {
                                this.errorSave = 0;
                                this.arrayCollaborateur.push(response.data);

                            }
                            else {

                                this.errorSave = 2;
                                this.arrayCollaborateur[index] = response.data;
                            }
                        }
                        else {
                            this.errorSave = 1;
                        }
                        console.log(this.errorSave);
                    }
                    else
                    {
                        this.errorSave = response.data;
                    }
                    this.openUpdateModal = true;
                });

            },
            changeService: function(idContact,service,event)
            {
                console.log(this.$refs);
                console.log(idContact,service,this.$refs[service+idContact].checked);

                axios.post(this.apiUpdateUrl, {
                    collaborateurId: idContact,
                    service: service,
                    serviceValue : this.$refs[service+idContact].checked
                }).then(response => {
                    this.errorSave = response.data;
                    if(this.errorSave==1)
                    {
                        this.openUpdateModal = true;
                    }
                });

            },
            changeRole: function(idContact,role)
            {

                axios.post(this.apiUpdateRoleUrl, {
                    collaborateurId: idContact,
                    role: role,
                    roleValue : this.$refs[role+idContact].checked
                }).then(response => {
                    this.errorSave = response.data;
                    if(this.errorSave==1)
                    {
                        this.openUpdateModal = true;
                    }
                });
            },
            sendPassword: function(idContact)
            {
                axios.post(this.apiResendPassword,{
                    collaborateurId: idContact
                }).then(response => {
                    this.errorSend = response.data;
                    this.openSendModal = true;
                });

            },
            toggleSuspendModal: function (idContact) {
                this.openSuspendModal = true;
                this.modalItemId = idContact;
            },
            toggleSendRightsModal: function(idContact)
            {
                this.openModalSendRight = true;
                this.modalItemId = idContact;
            },
            suspendUser: function(idContact)
            {
                axios.post(this.apiSuspendUser,{
                    collaborateurId: idContact
                }).then(response => {
                    this.errorSuspend = response.data;
                    this.openSuspendModal = true;
                    this.getStatusByContactId(idContact);
                    this.arrayCollaborateur[this.arrayCollaborateur.findIndex((item)=> item.id === idContact)].status = this.errorSuspend;
                })
            },
            getStatusByContactId: function (idContact) {
              return this.arrayCollaborateur[this.arrayCollaborateur.findIndex((item)=> item.id === idContact)].status;
            },
            updateUser: function(idContact)
            {
                let ficheContact = this.arrayCollaborateur[this.arrayCollaborateur.findIndex((item)=> item.id === idContact)]
                console.log(ficheContact);

                this.civilityNewContributor = ficheContact.civility;
                this.nameNewContributor = ficheContact.lastname;
                this.firstNameNewContributor = ficheContact.firstname;
                this.emailNewContributor = ficheContact.email;
                this.confirmationEmailNewContributor = ficheContact.email;
                this.phoneNewContributor = ficheContact.phone;
                this.mobileNewContributor = ficheContact.mobile;
                this.adressNewContributor = ficheContact.address;
                this.additionnnalAdressNewContributor = ficheContact.additionnal_address;
                this.zipCodeNewContributor = ficheContact.zipcode;
                this.cityNewContributor = ficheContact.city;
                this.idNewContritbutor = ficheContact.id;
                this.legalConfirmation = true;

                this.openAddModal = true;

            },
            sendRightUser: function(idContact)
            {
                console.log(idContact);
                axios.post(this.apiUrlSendService,{
                    collaborateurId: idContact
                }).then(response => {
                    this.errorSendRight = response.data;
                    this.openModalSendRight = true;
                });
            }
        }
    }
</script>
