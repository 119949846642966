<script>
    import Datepicker from 'vuejs-datepicker';
    import { en, fr } from 'vuejs-datepicker/dist/locale';
    import VueTimepicker from 'vue2-timepicker';

    import AppActionDropdown from '../common/AppActionDropdown';
    import { EventBus } from '../../event';
    import axios from 'axios';

    export default {
        name: 'AppProjectListArchive',
        props: {
            tableHeaderLabels: {
                type: Array
            },
            projects: {
                type: Object
            },
            test: {
                type: Object
            },
            deleteProjectUrl:
            {
                type:String
            },
            activeProjectUrl:
            {
                type:String
            },
            collaboratorUrl:
            {
                type: String
            }
        },
        components: {
            Datepicker: Datepicker,
            VueTimepicker: VueTimepicker,
            AppActionDropdown: AppActionDropdown
        },
        data: function () {
            return {
                sortBy: 'date',
                sortDesc: false,
                refFilter : "",
                dateDebut : null,
                dateFin : null,
                projectQuotes: [],
                fields: [],
                fr: fr,
                en: en,
                openDeleteModal: false,
                modalItemId: 0,
                openArchiveModal: false,
                listProjectDisplayer : [],
                openDeleteMessageModal: false,
                errorDelete: -1,
                openArchiveMessageModal:false,
                collSearch: 0
            }
        },
        created: function () {
            this.getData();
        },
        mounted: function() {
            this.populateFields();
            EventBus.$on('send-action', this.setActionOnItem);
        },
        methods: {
            populateFields: function () {
                this.fields.push(
                    {key: 'date', label: this.tableHeaderLabels[0],
                      formatter: value => {
                        return this.formatTime(value)
                      },
                      sortable: true
                    },
                    // {key: 'version', label: this.tableHeaderLabels[1], sortable: false},
                    {key: 'reference', label: this.tableHeaderLabels[1], sortable: true},
                    {key: 'totalPurchaseExTax', label: this.tableHeaderLabels[2], sortable: true},
                    {key: 'totalSaleExTax', label: this.tableHeaderLabels[3], sortable: true},
                    {key: 'marginExTax', label: this.tableHeaderLabels[4], sortable: true},
                    {key: 'state', label: this.tableHeaderLabels[5], sortable: true},
                    {key: 'action', label: this.tableHeaderLabels[6], sortable: false}
                );
            },
            getData: function () {
                for(let index in this.projects) {
                    let reference = this.projects[index].reference;
                    let status = this.projects[index].status;
                    if(this.projects[index].quotes) {
                        let quotes = this.projects[index].quotes;
                        for(let i = 0 ; i < quotes.length ; i++) {
                            if(this.projects[index].status==1 || this.projects[index].status==3){
                                this.projectQuotes.push({
                                    id: quotes[i].id,
                                    projectId: this.projects[index].id,
                                    date: quotes[i].updated.timestamp,
                                    // version: (quotes[i].version > 0) ? 2 : 1,
                                    reference: reference,
                                    totalPurchaseExTax: quotes[i].totalHTPurchasePrice,
                                    totalSaleExTax: quotes[i].totalSale,
                                    marginExTax: quotes[i].totalMargin,
                                    state: status
                                });
                            }
                        }
                    }
                }
                this.listProjectDisplayer = this.projectQuotes;
            },
            toggleDeleteModal: function (id) {
                this.openDeleteModal = true;
                this.modalItemId = id;
            },
            onHiddenDelete: function()
            {
                this.errorDelete = -1;
                this.openDeleteMessageModal=false;
            },
            deleteProject: function (id) {
                this.openDeleteModal = false;
                axios.delete(this.deleteProjectUrl + id).then(response=>{
                    //console.log(response.data);
                    let projectsList = response.data;
                    let error = response.data;

                    if(error===0)
                    {
                        var indexToDelete = this.projectQuotes.findIndex((projectQuote)=>{
                            return projectQuote.projectId === +id;
                        })

                        //console.log(id,JSON.parse(JSON.stringify( this.projectQuotes)),indexToDelete);
                        this.projectQuotes.splice(indexToDelete,1);
                        this.openDeleteMessageModal = true;
                    }
//                    this.projectQuotes = [];
//                    for(let index in projectsList) {
//                        let reference = projectsList[index].reference;
//                        let status = projectsList[index].status;
//                        if(projectsList[index].quotes) {
//                            let quotes = projectsList[index].quotes;
//                            for(let i = 0 ; i < quotes.length ; i++) {
//                                if(projectsList[index].status==1) {
//                                    this.projectQuotes.push({
//                                        id: quotes[i].id,
//                                        projectId: projectsList[index].id,
//                                        date: this.formatTimeJS(new Date(quotes[i].updated).getTime()),
//                                        version: (quotes[i].version > 0) ? 2 : 1,
//                                        reference: reference,
//                                        totalPurchaseExTax: quotes[i].totalHTPurchasePrice,
//                                        totalSaleExTax: quotes[i].totalSale,
//                                        marginExTax: quotes[i].totalMargin,
//                                        state: status
//                                    });
//                                }
//                            }
//                        }
//                    }
                    this.listProjectDisplayer = this.projectQuotes;
                }).catch(error => {
                    console.warn(error);
                });
            },
            toggleActiveModal: function (id) {
                this.openArchiveModal = true;
                this.modalItemId = id;
            },
            onHiddenArchive: function()
            {
                this.openArchiveMessageModal = false;
            },
            archiveProject: function (id) {
                this.openArchiveModal = false;
                axios.put(this.activeProjectUrl + id).then(response=>{
                    //console.log(response.data);
//                    let projectsList = response.data;
//                    this.projectQuotes = [];
//                    for(let index in projectsList) {
//                        let reference = projectsList[index].reference;
//                        let status = projectsList[index].status;
//                        if(projectsList[index].quotes) {
//                            let quotes = projectsList[index].quotes;
//                            for(let i = 0 ; i < quotes.length ; i++) {
//                                if(projectsList[index].status==1)
//                                {
//                                    this.projectQuotes.push({
//                                        id: quotes[i].id,
//                                        projectId: projectsList[index].id,
//                                        date: this.formatTimeJS(new Date(quotes[i].updated).getTime()),
//                                        version: (quotes[i].version > 0) ? 2 : 1,
//                                        reference: reference,
//                                        totalPurchaseExTax: quotes[i].total_h_t_purchase_price,
//                                        totalSaleExTax: quotes[i].total_sale,
//                                        marginExTax: quotes[i].total_margin,
//                                        state: status
//                                    });
//                                }
//                            }
//                        }
//                    }
                    let error = response.data;

                    if(error===0)
                    {
                        var indexToDelete = this.projectQuotes.findIndex((projectQuote)=>{
                            return projectQuote.projectId === +id;
                        })

                        //console.log(id,JSON.parse(JSON.stringify( this.projectQuotes)),indexToDelete);
                        this.projectQuotes.splice(indexToDelete,1);
                        this.openArchiveMessageModal = true;
                    }
                    this.listProjectDisplayer = this.projectQuotes;
                }).catch(error => {
                    console.warn(error);
                });

            },
            setActionOnItem: function (value) {
               if (value.action === 1) {
                    this.toggleDeleteModal(value.itemId);
                    //this.deleteProject(value.itemId);
                } else {
                    this.toggleActiveModal(value.itemId);

                }
            },
            formatTime: function (timestamp) {
                let date = new Date(timestamp * 1000);
                let dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
                let MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
                return dd + '/' + MM + '/' + date.getFullYear();
            },
            formatTimeJS: function (timestamp) {
                let date = new Date(timestamp);
                let dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
                let MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth()+1);
                return dd + '/' + MM + '/' + date.getFullYear();
            },
            filterByRef : function()
            {
                this.dateDebut = "";
                this.dateFin ="";
                let refSearch = this.refFilter;
                if(refSearch!="")
                {
                    // console.log(this.projectQuotes);
                    this.listProjectDisplayer = this.projectQuotes.filter((project)=>{
                        //console.log(project.reference," - ",refSearch);
                        return project.reference.toLowerCase().includes(refSearch.toLowerCase());
                    });
                }
                else
                {
                    this.listProjectDisplayer = this.projectQuotes;
                }
                //console.log(this.listProjectDisplayer );
            },
            filterByDate : function()
            {

                this.refFilter ="";

                let dateDebut = (this.dateDebut) ? this.dateDebut.setHours(0,0,0,0) : 0;
                let dateFin = (this.dateFin) ? this.dateFin.setHours(0,0,0,0) : new Date().getTime();



                this.listProjectDisplayer = this.projectQuotes.filter((project)=>{
                    let projectDate = project.date.split("/");
                    //console.log(projectDate);
                    projectDate = new Date(projectDate[2],projectDate[1]-1,projectDate[0]).setHours(0,0,0,0);

                    //console.log(projectDate,dateDebut,dateFin);
                    return (projectDate >= dateDebut && projectDate <= dateFin);
                });
            },
            customFormatter(date) {
                return momentDate(date).format('YYYY-MM-DD');
            },
            filtreByColl: function()
            {
                console.log(this.collSearch);
                let data = new FormData();
                data.append("idColl", this.collSearch);
                axios.post(this.collaboratorUrl ,data).then(response=>{
                    //console.log(response.data);
                    console.log(response);
                    //let retourProject = JSON.parse(response.data);
                    let retourProject = response.data;
                    this.projectQuotes = [];

                    for(let index in retourProject) {

                        let reference = retourProject[index].reference;
                        let status = retourProject[index].status;
                        if(retourProject[index].quotes) {
                            let quotes = retourProject[index].quotes;
                            for(let i = 0 ; i < quotes.length ; i++) {
                                if(retourProject[index].status==1 || retourProject[index].status==3){
                                    this.projectQuotes.push({
                                        id: quotes[i].id,
                                        projectId: retourProject[index].id,
                                        date:  Date.parse(quotes[i].updated) / 1000,
                                        // version: (quotes[i].version > 0) ? 2 : 1,
                                        reference: reference,
                                        totalPurchaseExTax: quotes[i].total_h_t_purchase_price,
                                        totalSaleExTax: quotes[i].total_margin,
                                        marginExTax: quotes[i].total_margin,
                                        state: status
                                    });
                                }
                            }
                        }
                    }
                    console.log(this.projectQuotes);
                    this.listProjectDisplayer = this.projectQuotes;
                }).catch(error => {
                    console.warn(error);
                });
            }
        }
    }

</script>
