import '@babel/polyfill';
import 'url-search-params-polyfill';

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import axios from "axios";
import AppAddArticle from './components/admin/news/AppAddArticle';
import AppCatalogRequestList from './components/admin/catalog/AppCatalogRequestList'
import AppInfosRequestList from './components/admin/infos/AppInfosRequestList.vue'
import AppIncompleteOrderList from './components/admin/incomplete-order/AppIncompleteOrderList.vue'
import AppInfosRequestShow from './components/admin/infos/AppInfosRequestShow.vue'
import AppQuoteRequestList from './components/quote-request/AppQuoteRequestList';
import AppQuoteRequestShow from './components/quote-request/AppQuoteRequestShow';
import AppLibraryImaged from './components/library/AppLibraryImaged';
import AppLibraryListed from './components/library/AppLibraryListed';
import AppLibraryList from './components/admin/library/AppLibraryList';
import AppNewsList from './components/admin/news/AppNewsList';
import AppLibraryAddEdit from './components/admin/library/AppLibraryAddEdit';
import AppLibraryCommonModal from './components/admin/library/AppLibraryCommonModal';
import AppListNews from './components/news/AppListNews';
import AppProjectNavbar from './components/project/AppProjectNavbar';
import AppMenuToggle from './components/common/AppMenuToggle';
import AppNavTabs from './components/homepage/AppNavTabs';
import AppHomepageSwiper from './components/homepage/AppHomepageSwiper';
import AppProjectAccordeon from './components/project/AppProjectAccordeon';
import AppProjectAccordeonItemOne from './components/project/AppProjectAccordeonItemOne';
import AppProjectAccordeonItemTwo from './components/project/AppProjectAccordeonItemTwo';
import AppProjectAccordeonItemThree from './components/project/AppProjectAccordeonItemThree';
import AppProjectAccordeonItemFour from './components/project/AppProjectAccordeonItemFour';
import AppProjectList from './components/project/AppProjectList';
import AppProjectListArchive from './components/project/AppProjectListArchive';
import AppQuoteTemplateForm from './components/project/AppQuoteTemplateForm';
import AppRegistrationAdd from './components/registration/AppRegistrationAdd';
import AppRegistrationList from './components/admin/registration/AppRegistrationList';
import AppRegistrationShow from './components/admin/registration/AppRegistrationShow';
import AppSidebar from './components/common/AppSidebar';
import AppSwiper from './components/common/AppSwiper';
import AppOrdersList from './components/orders/AppOrdersList';
import AppAccountData from './components/account/AppAccountData';
import AppAccountServices from './components/account/AppAccountServices';
import AppAccountContributor from './components/account/AppAccountContributor';
import AppClientList from './components/admin/client/AppClientList';
import AppConfigurationList from './components/admin/configuration/AppConfigurationList';
import AppClientShow from './components/admin/client/AppClientShow';
import AppLoader from './components/common/AppLoader';
import AppPhotolibraryForm from './components/photolibrary/AppPhotolibraryForm';

import Datepicker from 'vuejs-datepicker';
import { en, fr } from 'vuejs-datepicker/dist/locale';

import VueTimepicker from 'vue2-timepicker';
import Draggable from 'vuedraggable';

require('./../scss/main.scss');
// require('./inactivity/inactivity.js');

window.Sortable = require('sortablejs');

Vue.use(BootstrapVue);
Vue.use(VueAwesomeSwiper);
Vue.use(Datepicker);
Vue.use(VueTimepicker);
Vue.use(Draggable);

Vue.config.ignoredElements = ['x-trans'];

Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('formatPrice', function (value) {
    if (!value) return '';
    value = value.toString();
    value = value.replace(/\./g, ',');
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return value;
});

Vue.filter('toFixed', function (value) {
    if (!value) return '';
    value = parseFloat(value);
    value = Math.round(value*100)/100;
    return value.toFixed(2);
});

Vue.filter('formatDate', function (value) {
    return new Date(value).toLocaleDateString();
});

Vue.filter('sanitize', function (value) {
    let map = {
        '-' : ' |_',
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|É|È|Ê',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
    };
    if (!value) return '';
    value = value.toLowerCase();
    for(let pattern in map) {
        value = value.replace(new RegExp(map[pattern], 'g'), pattern);
    }
    value = value.replace(/\s/g, '-');
    return value;
});

new Vue({
    el: '#app',
    delimiters: ['${', '}'],
    components: {
        AppAddArticle: AppAddArticle,
        AppCatalogRequestList:AppCatalogRequestList,
        AppQuoteRequestList: AppQuoteRequestList,
        AppQuoteRequestShow: AppQuoteRequestShow,
        AppLibraryImaged: AppLibraryImaged,
        AppLibraryListed: AppLibraryListed,
        AppLibraryList: AppLibraryList,
        AppNewsList: AppNewsList,
        AppLibraryAddEdit: AppLibraryAddEdit,
        AppLibraryCommonModal: AppLibraryCommonModal,
        AppListNews: AppListNews,
        AppProjectNavbar: AppProjectNavbar,
        AppMenuToggle: AppMenuToggle,
        AppNavTabs: AppNavTabs,
        AppHomepageSwiper: AppHomepageSwiper,
        AppProjectAccordeon: AppProjectAccordeon,
        AppProjectAccordeonItemOne: AppProjectAccordeonItemOne,
        AppProjectAccordeonItemTwo: AppProjectAccordeonItemTwo,
        AppProjectAccordeonItemThree: AppProjectAccordeonItemThree,
        AppProjectAccordeonItemFour: AppProjectAccordeonItemFour,
        AppProjectList: AppProjectList,
        AppProjectListArchive: AppProjectListArchive,
        AppQuoteTemplateForm: AppQuoteTemplateForm,
        AppRegistrationAdd: AppRegistrationAdd,
        AppRegistrationList: AppRegistrationList,
        AppRegistrationShow: AppRegistrationShow,
        AppSidebar: AppSidebar,
        AppSwiper: AppSwiper,
        Datepicker: Datepicker,
        VueTimepicker: VueTimepicker,
        AppOrdersList: AppOrdersList,
        AppAccountData: AppAccountData,
        AppAccountServices: AppAccountServices,
        AppAccountContributor: AppAccountContributor,
        Draggable: Draggable,
        AppClientList: AppClientList,
        AppClientShow: AppClientShow,
        AppLoader: AppLoader,
        AppPhotolibraryForm: AppPhotolibraryForm,
        AppInfosRequestList: AppInfosRequestList,
        AppInfosRequestShow: AppInfosRequestShow,
        AppConfigurationList: AppConfigurationList,
        AppIncompleteOrderList: AppIncompleteOrderList
    },
    data:  {
        fr: fr,
        en: en
    },
    mounted: function () {
        window.addEventListener('load', this.calculateTopPageSpacing);
        window.addEventListener('resize', this.calculateTopPageSpacing);

        let activityTimeOut;
        let resetTimeOut = () => {
            if(activityTimeOut)
            {
                window.clearTimeout(activityTimeOut);
            }

            activityTimeOut = window.setTimeout(function(){

                location.href = "/connexion?message=unactivity";
            },3600000);
        } ;
        resetTimeOut();
        axios.interceptors.response.use((response)=>{
            resetTimeOut();
            return response;
        },(error)=>{ return error; });
    },
    beforeDdestroy: function() {
        window.removeEventListener('load', this.calculateTopPageSpacing);
        window.removeEventListener('resize', this.calculateTopPageSpacing);
    },
    methods: {
        calculateTopPageSpacing: function () {
            let header = document.querySelector(".minco-header");
            let body = document.getElementById("minco-body");
            let sidebar = document.getElementById("minco-sidebar");
            if (header) {
                body.style.paddingTop = header.clientHeight + "px";
            }
            if (header && sidebar) {
                sidebar.style.paddingTop = header.clientHeight + "px";
            }
        }
    }
});
