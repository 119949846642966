var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col-auto menu-toggle",
      class: { "is-active": _vm.openMenu },
      on: {
        click: function ($event) {
          return _vm.toggleMenu()
        },
      },
    },
    [
      _c("span", { staticClass: "menu-toggle__line" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-toggle__line" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-toggle__line" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }