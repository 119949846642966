<template>
    <form class="row pt-4 new-project__content__body" @submit.prevent="postData" v-cloak>
        <div class="col-md h-100 d-none d-md-block">
            <slot name="headerProject"></slot>
        </div>
        <div class="col-md h-100 d-none d-md-block">
            <slot name="headerClient"></slot>
        </div>
        <div class="col-md h-100 d-none d-md-block">
            <slot name="headerNoteToSelf"></slot>
        </div>
        <div class="w-100 mb-3"></div>
        <div class="col-md">
            <div class="d-block d-md-none">
                <slot name="headerProjectMobile"></slot>
            </div>
            <div class="form-group">
                <slot name="reference"></slot> <span v-bind:class=" { 'text-danger': isErrorSyntaxeItem }" v-if="isErrorSyntaxeItem" >non valide.</span>
                <input type="text" id="project_reference" maxlength="20" style="text-transform: uppercase" v-model="reference" required="required" class="form-control">
            </div>
            <div class="form-group">
                <slot name="comment"></slot>
                <textarea rows="7" id="project_commentaire" v-model="comment" class="form-control"></textarea>
            </div>
            <!--<div class="form-group">-->
                <!--<slot name="opportunity"></slot>-->
                <!--<div id="project_opportunity">-->
                    <!--<div class="form-check-inline mr-2 mb-2">-->
                        <!--<input type="radio" :id="'project_oppopportunity_1'" v-model="chosenOpportunity" :value="1" class="form-check-input">-->
                        <!--<label :for="'project_oppopportunity_1'" class="form-check-label">-->
                            <!--<span>{{ yesLabel }}</span>-->
                        <!--</label>-->
                    <!--</div>-->
                    <!--<div class="form-check-inline mr-2 mb-2">-->
                        <!--<input type="radio" :id="'project_oppopportunity_0'" v-model="chosenOpportunity" :value="0" class="form-check-input">-->
                        <!--<label :for="'project_oppopportunity_0'" class="form-check-label">-->
                            <!--<span>{{ noLabel }}</span>-->
                        <!--</label>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <slot name="required"></slot>
        </div>
        <div class="col-md">
            <div class="d-block d-md-none mt-4">
                <slot name="headerClientMobile"></slot>
            </div>
            <div class="form-group">
                <slot name="clientLastname"></slot>
                <input type="text" id="project_nomClient" v-model="clientLastname" class="form-control">
            </div>
            <div class="form-group">
                <slot name="clientFirstname"></slot>
                <input type="text" id="project_prenomClient" v-model="clientFirstname" class="form-control">
            </div>
            <div class="form-group">
                <slot name="clientAddress"></slot>
                <input type="text" id="project_adresseClient" v-model="clientAddress" class="form-control">
            </div>
            <div class="row">
                <div class="col-md form-group">
                    <slot name="clientCity"></slot>
                    <input type="text" id="project_villeClient" v-model="clientCity" class="form-control">
                </div>
                <div class="col-md form-group">
                    <slot name="clientZipcode"></slot>
                    <input type="text" id="project_cpClient" v-model="clientZipcode" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-md form-group">
                    <slot name="clientMail"></slot>
                    <input type="email" id="project_mailClient" v-model="clientMail" class="form-control">
                </div>
                <div class="col-md form-group">
                    <slot name="clientPhone"></slot> <slot name="clientPhoneFormat"></slot>
                    <input type="tel" pattern="[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{2}" id="project_telClient" v-model="clientPhone" class="form-control">
                </div>
            </div>
        </div>
        <div class="col-md">
            <div class="d-block d-md-none mt-4">
                <slot name="headerNoteToSelfMobile"></slot>
            </div>
            <div class="form-group">
                <slot name="delay"></slot>
                <div id="project_delay">
                    <div class="form-check minco-radio minco-radio--inline mr-2 mb-2" v-for="delay in delays">
                        <input type="radio" :id="'project_delay_' + delay.id" v-model="chosenDelay" :value="delay.id" class="form-check-input">
                        <label :for="'project_delay_' + delay.id" class="form-check-label">
                            <span>{{ delay.label }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <slot name="materials"></slot>
                <div id="project_materials">
                    <div class="form-check minco-checkbox minco-checkbox--inline mr-2 mb-2" v-for="material in materials">
                        <input type="checkbox" :id="'project_materials_' + material.id" v-model="chosenMaterials" :value="material.id" class="form-check-input">
                        <label :for="'project_materials_' + material.id" class="form-check-label">
                            <span>{{ material.label }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <slot name="customerRequirements"></slot>
                <div id="project_customerRequirements">
                    <div class="form-check minco-checkbox minco-checkbox--inline mr-2 mb-2" v-for="customerRequirement in customerRequirements">
                        <input type="checkbox" :id="'project_customerRequirements_' + customerRequirement.id" v-model="chosenCustomerRequirements" :value="customerRequirement.id" class="form-check-input">
                        <label :for="'project_customerRequirements_' + customerRequirement.id" class="form-check-label">
                            <span>{{ customerRequirement.label }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-12 form-group">
                    <slot name="carpentryNumber"></slot>
                </div>
                <div class="col-6 form-group">
                    <input type="number" lang="en-150" id="project_nombreMenuiseries" v-model="carpentryNumber" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-md form-group">
                    <slot name="quantitySurveyor"></slot>
                    <input type="text" id="project_metreur" v-model="quantitySurveyor" class="form-control">
                </div>
                <div class="col-md form-group">
                    <slot name="salesperson"></slot>
                    <input type="text" id="project_commercial" v-model="salesperson" class="form-control">
                </div>
            </div>
            <div class="form-group">
                <slot name="personalComment"></slot>
                <textarea rows="7" id="project_commentairePersonnel" v-model="personalComment" class="form-control"></textarea>
            </div>
            <div class="form-group text-right mt-5 mr-3" v-if="status == 0">
              <button type="submit" class="btn btn-rounded btn-rounded--yellow px-4">{{ saveLabel }}</button>
            </div>
        </div>
    </form>
</template>

<script>
    import axios from 'axios';
    import { EventBus } from '../../event';

    export default {
        name: 'AppProjectAccordeonItemOne',
        props: {
            apiProjectUrl: {
                type: String
            },
            apiQuoteUrl: {
                type: String
            },
            saveLabel: {
                type: String
            },
            inputLabels: {
                type: Array
            },
            yesLabel: {
                type: String
            },
            noLabel: {
                type: String
            },
            delays: {
                type: Array
            },
            materials: {
                type: Array
            },
            customerRequirements: {
                type: Array
            },
            project: {
                type: Object
            }
        },
        data: function () {
            return {
                reference: '',
                comment: '',
                clientLastname: '',
                clientFirstname: '',
                clientAddress: '',
                clientCity: '',
                clientZipcode: '',
                clientMail: '',
                clientPhone: '',
                carpentryNumber: null,
                quantitySurveyor: '',
                salesperson: '',
                personalComment: '',
                chosenDelay: null,
                chosenOpportunity: 0,
                chosenMaterials: [],
                chosenCustomerRequirements: [],
                version: '0',
                id: 0,
                status: 0,
                quoteId: 0,
                isErrorSyntaxeItem: false,
            }
        },
        mounted: function() {
            if(this.project["id"] !== null)
            {
                this.id = this.project["id"];
                this.status = this.project["status"];
                this.reference = this.project["reference"];
                this.comment = this.project["comment"];
                // this.chosenOpportunity = this.project["opportunity"];
                this.clientLastname = this.project["clientLastname"];
                this.clientFirstname = this.project["clientFirstname"];
                this.clientAddress = this.project["clientAddress"];
                this.clientCity = this.project["clientCity"];
                this.clientZipcode = this.project["clientZipcode"];
                this.clientMail = this.project["clientMail"];
                this.clientPhone = this.project["clientPhone"];
                this.carpentryNumber = this.project["carpentryCount"];
                this.quantitySurveyor = this.project["quantitySurveyor"];
                this.salesperson = this.project["salesperson"];
                this.personalComment = this.project["personalComment"];
                this.chosenDelay = this.project["delay"] ? this.project["delay"]["id"] : this.project["delay"];
                this.project["materials"].filter( response => {
                    this.chosenMaterials.push(response.id);
                });
                this.project["customerRequirements"].filter( response => {
                    this.chosenCustomerRequirements.push(response.id);
                });
                this.quoteId = this.project["quotes"][0]['id'];
            }
        },
        methods: {
            postData: function () {
                this.$root.$emit('set-loading', true);
                if(this.project["id"] === null) {
                    let _repereRegex = /^[A-Z0-9 ]{1,20}$/
                    this.isErrorSyntaxeItem = false;
                    if(this.reference !== "" && _repereRegex.test(this.reference.toUpperCase()) === true) {
                        axios.post(this.apiProjectUrl, {
                            clientLastname: this.clientLastname,
                            clientFirstname: this.clientFirstname,
                            clientAddress: this.clientAddress,
                            clientCity: this.clientCity,
                            clientZipcode: this.clientZipcode,
                            clientMail: this.clientMail,
                            clientPhone: this.clientPhone,
                            reference: this.reference.toUpperCase(),
                            comment: this.comment,
                            opportunity: this.chosenOpportunity,
                            carpentryCount: this.carpentryNumber,
                            quantitySurveyor: this.quantitySurveyor,
                            salesperson: this.salesperson,
                            personalComment: this.personalComment,
                            customerRequirements: this.chosenCustomerRequirements,
                            materials: this.chosenMaterials,
                            delay: this.chosenDelay
                        })
                            .then(response => {
                                this.$root.$emit('set-loading', false);
                                this.id = response.data.id;
                                this.status = response.data.status;
                                // EventBus.$emit('project-id', this.id);
                                EventBus.$emit("header-button-action", { step: 'numbering', state: true });
                                EventBus.$emit('project-details', response.data);
//                            axios.post(this.apiQuoteUrl, {
//                                project: this.id,
//                                version: 0,
//                                clientDiscount: 0
//                            })
//                                .then(response=>{
//                                    this.quoteId = response.data.id;
//                                    EventBus.$emit('project-details', response.data);
//                                })
//                                .catch(error=>{
//                                    console.warn(error);
//                                })
                            })
                            .catch(error => {
                                this.$root.$emit('set-loading', false);
                                console.warn(error);
                            })
                    }
                    else
                    {
                        this.$root.$emit('set-loading', false);
                        this.isErrorSyntaxeItem = true;

                    }


                } else {
                    let _repereRegex = /^[A-Z0-9 ]{1,20}$/
                    this.isErrorSyntaxeItem = false;
                    if(this.reference !== "" && _repereRegex.test(this.reference.toUpperCase()) === true) {
                        axios.put(this.apiProjectUrl + '/' + this.id, {
                            clientLastname: this.clientLastname,
                            clientFirstname: this.clientFirstname,
                            clientAddress: this.clientAddress,
                            clientCity: this.clientCity,
                            clientZipcode: this.clientZipcode,
                            clientMail: this.clientMail,
                            clientPhone: this.clientPhone,
                            reference: this.reference.toUpperCase(),
                            comment: this.comment,
                            opportunity: this.chosenOpportunity,
                            carpentryCount: this.carpentryNumber,
                            quantitySurveyor: this.quantitySurveyor,
                            salesperson: this.salesperson,
                            personalComment: this.personalComment,
                            customerRequirements: this.chosenCustomerRequirements,
                            materials: this.chosenMaterials,
                            delay: this.chosenDelay
                        })
                            .then(response => {
                                this.$root.$emit('set-loading', false);

                                // EventBus.$emit('project-id', this.id);
                                // EventBus.$emit('quote-id', this.quoteId);
                                EventBus.$emit('project-details', response.data);
                                EventBus.$emit("header-button-action", {step: 'numbering', state: true});
                            })
                            .catch(error => {
                                this.$root.$emit('set-loading', false);
                                console.warn(error);
                            })
                    }
                    else
                    {

                        this.$root.$emit('set-loading', false);
                        this.isErrorSyntaxeItem = true;
                    }
                }
            }
        }
    }
</script>
