<script>
    import Datepicker from 'vuejs-datepicker';
    import { en, fr } from 'vuejs-datepicker/dist/locale';
    import VueTimepicker from 'vue2-timepicker'
    import axios from 'axios';
    import { EventBus } from '../../event';
    import AppActionDropdown from '../common/AppActionDropdown';
    import moment from 'moment';
    import AppPagination from '../common/AppPagination';

    export default {
      name: 'AppOrdersList',
      components: {
        AppPagination,
        Datepicker: Datepicker,
        VueTimepicker: VueTimepicker,
        AppActionDropdown: AppActionDropdown
      },
      props: {
        apiGetUrl: {
          type: String
        },
        listOrdersFound: {
          type: Array
        },
        listMediaFound: {
          type: Array
        },
        urlResa: {
          type: String
        },
        urlArc: {
          type: String
        },
        apiUrlMailMedia: {
          type: String
        },
        apiGetByClientUrl: {
          type: String
        },
        canBpa: {
          type: Boolean
        },
        urlAcceptBpa :{
          type: String
        },
        urlDeclineBpa :{
          type: String
        },
        urlAcceptReserveBpa:{
          type: String
        },
        urlDeclineOrder :{
          type: String
        },
        urlChangeDeliveryWeek:{
          type: String
        },
        urlGetCrenaux:{
          type: String
        },
        urlDeclencherLivraison:{
          type: String
        },
        urlChangeLivraisonInfo:{
          type: String
        },
        urlAutreDemande:{
          type:String
        },
        NumCommande:{
          type:String
        }

      },
      data: function () {
        return {
          displayCount: 25,
          refFilter : "",
          numFilter :"",
          dateDebut : null,
          dateFin : null,
          fr: fr,
          en: en,
          listOrders : [],
          listOrdersDisplayed : [],
          postPaginationItems: [],
          countDeclenchement : 0,
          fields: [],
          fieldsMedias: [],
          displayModalMedia: false,
          mediaDisplay: [],
          mediaToSend: [],
          destinataire:"",
          codeClientFilter: "",
          weekFilter: "",
          categoryFilter: "",
          statusFilter: "",
          displayModalGlossary:false,
          selectedOrder: '',
          acceptType: 'accept-all',
          acceptModifyType: 'now',
          bpaFiles: [],
          declencheFiles: [],
          missingComment: false,
          currentComment: '',
          docFileName: '',
          docFile: '',
          deliveryDate : null,
          deliveryTime : '08:00',
          deliveryWeek: null,
          disabledDates: {},
          enabledDates:{},
          deliveryCart: false,
          deliveryHoist: false,
          deliveryRemorque: false,
          deliveryPorteur:false,
          deliveryAddress: null,
          declineError: false,
          deliveryError: false,
          deliveryWeekError: false,
          canceledOrder: false,
          deliveryHours:{},
          deliveryWeekOrder: [],
          currentNumberWeek:0,
          currentYear:0,
          sortBy: 'orderDate',
          sortDesc: true,
        }
      },
      watch: {
        searchTerm: function (val) {
          //console.log(val);
          this.postPaginationItems = this.listOrdersFound;
        },
      },
      created: function () {
        this.postPaginationItems = this.listOrdersFound;

      },
      mounted: function () {
        //this.getListOrder();
        this.populateFields();

        EventBus.$on('send-action', this.setActionOnItem);

        let arrayMedia = [];

        this.listMediaFound.forEach(function(element) {
          let mediaArray = [];
          mediaArray["name"] = element.name;
          mediaArray["file"] = element.files[0].filename;
          mediaArray["path"] = element.files[0].path;
          mediaArray["id"] = element.files[0].id;


          arrayMedia.push(mediaArray);

        });
        this.mediaDisplay = arrayMedia;

        var date = Date.now();
        this.currentNumberWeek = this.dateWeek(date)
        this.currentYear = new Date().getFullYear();

        if(this.NumCommande !== "")
        {
          this.listOrdersDisplayed = this.listOrders.filter((order) => {
            if (order.documentNo.toLowerCase().includes(this.NumCommande.toLowerCase())) {
              return order;
            }

          });
        }
        EventBus.$on('filtered-data', this.getListOrder);
      },
      beforeDestroy: function () {
        EventBus.$off('filtered-data', this.getListOrder);
      },
      computed: {
        // un accesseur (getter) calculé
        reversedMessage: function () {
          // `this` pointe sur l'instance vm
          return this.message.split('').reverse().join('')
        }
      },
      methods : {
        populateFields: function () {
          this.fields.push(
              {key: 'show_details', label: "", sortable: false},
              {key: 'orderDate', label: "Date", sortable: true,sortDirection: 'desc'},
              {key: 'orderReference', label: "Référence chantier", sortable: true},
              {key: 'documentNo', label: "Numéro de commande", sortable: true},
              {key: 'orderCategory', label: "Catégorie", sortable: true},
              {key: 'Etat', label: "Statut Commande", sortable: true},
              {key: 'expectedDeliveryWeek', label: "Semaine de livraison", sortable: true},
              {key: 'amount', label: "Montant Total € HT", sortable: true},
              {key: 'action', label: "Actions", sortable: false}
          );
          this.fieldsMedias.push(
              {key: 'name', label:'Nom', sortable: true},
              {key: 'file', label:'Fichier', sortable: true},
              {key: 'toSend', label:'Envoyer', sortable: false}
          )
        },
        rowClass(item, type) {
          if (item && type === 'row') {
            // DEMO
            var dateNow = new Date();
            var oneJan = new Date(dateNow.getFullYear(), 0, 1);
            var numberOfDays = Math.floor((dateNow - oneJan) / (24 * 60 * 60 * 1000));
            var result = Math.ceil((dateNow.getDay() + 1 + numberOfDays) / 7);
            var semaineCourante = result+"."+dateNow.getFullYear();

            if(item.promisedDeliveryWeek && item.shipmentMethod && item.RequestedDeliverySlot)
              return ((item.promisedDeliveryWeek.split('.')[1] <= dateNow.getFullYear() && item.promisedDeliveryWeek.split('.')[0] < result ) && item.orderComplete===true && item.shipmentMethod=="ATTENTE" && (item.RequestedDeliverySlot===null || item.requestedDeliverySlot=='0001-01-01T00:00:00' || item.requestedDeliverySlot=='0001-01-01T01:00:00' ))  ? 'b-table-warning' : '';

            // if (item.Alerte && item.Alerte.length > 0) {
            //     return 'b-table-warning';
            // } else {
            //     return '';
            // }
          } else {
            return null
          }
        },
        getFormattedDate(date) {
          return moment(date).format('DD/MM/YYYY');
        },
        sendInfo(item) {
          this.deliveryWeekOrder = [];

          var start = +item.expectedDeliveryWeek.split(".")[0]+1;
          var end = start+10;

          while(start <= end)
          {
            this.deliveryWeekOrder.push(start);
            start++;
          }
          this.selectedOrder = item;
        },
        openGlossaryModal:function () {
          this.displayModalGlossary = true;
        },
        getListOrder : function(e) {
          if (e != null && e.length > 0) {
            this.listOrders = e.map((order)=>{
              //console.log(order);
              //order._showDetails = false;
              // let dateArray = order.receiptDate.split('T')[0].split('-');
              // order.Date = order.receiptDate.split('T')[0];
              // order.timestamp = new Date(dateArray[0],dateArray[1]-1,dateArray[2]).getTime();
              if(order.shipmentMethod && order.shipmentMethod == "ATTENTE")
                this.countDeclenchement = this.countDeclenchement +1;

              if (order.SemaineLivraison && order.SemaineLivraison[0] != "") {
                let SLArray = order.SemaineLivraison[0].split('.');
                order.semaineLivraison = "S "+ SLArray[0]+" " +SLArray[1]
              }
              if(order.shippedQty == order.orderQty)
                order.Etat="Expédiée complète";
              else if(order.shippedQty >0 && order.shippedQty < order.orderQty)
                order.Etat="Expédiée partielle";
              else if(order.orderComplete == true && order.shipmentMethod=="ATTENTE" && order.promisedDeliverySlot!="" && order.promisedDeliverySlot!='0001-01-01T00:00:00' && order.promisedDeliverySlot!='0001-01-01T01:00:00' && order.promisedDeliverySlot!=null)
                order.Etat="Demande de livraison confirmée";
              else if(order.orderComplete == true && order.shipmentMethod=="ATTENTE" && order.requestedDeliverySlot!="" && order.requestedDeliverySlot!='0001-01-01T00:00:00' && order.requestedDeliverySlot!='0001-01-01T01:00:00' && order.requestedDeliverySlot!=null)
                order.Etat="Déclenchement de livraison en attente de confirmation";
              else if((order.firmPlannedDate && order.firmPlannedDate !='' && order.firmPlannedDate !=null) && order.orderAgreementDate !=null)
                order.Etat="En cours de fabrication";
              else if((order.firmPlannedDate && order.firmPlannedDate !='' && order.firmPlannedDate !=null) && order.orderAgreementDate !=null && order.orderComplete==true)
                order.Etat="Commande fabriquée";
              else if((order.firmPlannedDate && order.firmPlannedDate !='' && order.firmPlannedDate !=null) && order.orderAgreementDate !=null && order.newDeliveryWeek !='')
                order.Etat="Fabrication en cours - En retard";
              else if(order.orderAgreementDate != null && this.canBpa == 1 && (!order.documentNo.startsWith('S') && !order.documentNo.startsWith('W')))
                order.Etat="Commande confirmée - BPA validé";
              else if(order.orderAgreementDate != null && this.canBpa == 0)
                order.Etat="Commande confirmée";
              else if(order.orderConfirmationPrint!=null && order.orderConfirmationPrint!='' && this.canBpa == 1 && (!order.documentNo.startsWith('S') && !order.documentNo.startsWith('W')))
                order.Etat="Commande confirmée - En attente validation ARC";
              else if(order.orderConfirmationPrint!=null && order.orderConfirmationPrint!='' && this.canBpa == 0 || (order.orderConfirmationPrint!=null && order.orderConfirmationPrint!='' && (order.documentNo.startsWith('S') || order.documentNo.startsWith('W'))))
                order.Etat="Commande confirmée";
              else if(order.orderDate && order.orderDate!="")
                order.Etat="Commande reçue";

              if(order.receiptDate && order.receiptDate!="")
              {
                //console.log(order);
                order.orderDate = order.receiptDate;
              }
              if(order.newDeliveryWeek !='')
                order.promisedDeliveryWeek = order.newDeliveryWeek;

              return order;
            });

            this.listOrdersDisplayed = this.listOrders;
          }

        },
        filterByRef : function() {
          this.numFilter="";
          this.dateDebut = "";
          this.dateFin ="";
          let refSearch = this.refFilter;
          //console.log(refSearch);
          if (refSearch!="") {
            this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
              //console.log(order.orderReference.toLowerCase());
              if (order.orderReference.toLowerCase().includes(refSearch.toLowerCase())) {
                // if (order.SemaineLivraison[0] != "") {
                //     let SLArray = order.SemaineLivraison[0].split('.');
                //     order.semaineLivraison = "S "+ SLArray[0]+" " +SLArray[1]
                // }
                return order;
              }

            })
            //console.log(this.listOrdersDisplayed);
            this.postPaginationItems = this.listOrdersDisplayed;
            EventBus.$on('filtered-data', this.getListOrder);
          } else {
            this.listOrdersDisplayed = this.listOrdersFound;
            this.postPaginationItems = this.listOrdersDisplayed;
            EventBus.$on('filtered-data', this.getListOrder);
          }
        },
        filterByNum : function() {
          this.refFilter ="";
          this.dateDebut = "";
          this.dateFin ="";
          let numSearch = this.numFilter;
          if(numSearch!="") {
            this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
              if (order.No.toLowerCase().includes(numSearch.toLowerCase())) {
                if (order.SemaineLivraison[0]  != "") {
                  let SLArray = order.SemaineLivraison[0].split('.');
                  order.semaineLivraison = "S "+ SLArray[0]+" " +SLArray[1]
                }

                return order;
              }

            });
            this.postPaginationItems = this.listOrdersDisplayed;
            EventBus.$on('filtered-data', this.getListOrder);
          } else {
            this.listOrdersDisplayed = this.listOrdersFound;
            this.postPaginationItems = this.listOrdersDisplayed;
            EventBus.$on('filtered-data', this.getListOrder);
          }
        },
        filterByDate: function() {
          this.refFilter ="";
          this.numFilter="";

          let dateDebut = (this.dateDebut) ? this.dateDebut.setHours(0,0,0,0) : 0;
          let dateFin = (this.dateFin) ? this.dateFin.setHours(0,0,0,0) : new Date().getTime();

          this.listOrdersDisplayed = this.listOrders.filter((order) => {
            if ((order.timestamp >= dateDebut && order.timestamp <= dateFin)) {
              if(order.SemaineLivraison[0] != "") {
                let SLArray = order.SemaineLivraison[0].split('.');
                order.semaineLivraison = "S "+ SLArray[0]+" " +SLArray[1]
              }

              return order;
            }

          });
        },
        filterByWeek: function() {
          let week = this.weekFilter;
          if(week !="")
          {
            this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {

              if (order.expectedDeliveryWeek.includes(week)) {
                // if (order.SemaineLivraison[0] != "") {
                //     let SLArray = order.SemaineLivraison[0].split('.');
                //     order.semaineLivraison = "S "+ SLArray[0]+" " +SLArray[1]
                // }
                return order;
              }

            });
            this.postPaginationItems = this.listOrdersDisplayed;
            EventBus.$on('filtered-data', this.getListOrder);
          } else {
            this.listOrdersDisplayed = this.listOrdersFound;
            this.postPaginationItems = this.listOrdersDisplayed;
            EventBus.$on('filtered-data', this.getListOrder);
          }
        },
        filterByCategory: function() {
          let category = this.categoryFilter;
          if (category!="") {
            this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
              if(category=="PIECDETA"){
                if ((order.orderNature  && order.orderNature.includes(category) )|| (order.orderCategory && order.orderCategory.includes("Pièces détachées"))) {
                  // if (order.SemaineLivraison[0] != "") {
                  //     let SLArray = order.SemaineLivraison[0].split('.');
                  //     order.semaineLivraison = "S "+ SLArray[0]+" " +SLArray[1]
                  // }
                  return order;
                }
              }
              else
              {
                if (order.orderCategory && order.orderCategory.includes("Produit fini")) {
                  // if (order.SemaineLivraison[0] != "") {
                  //     let SLArray = order.SemaineLivraison[0].split('.');
                  //     order.semaineLivraison = "S "+ SLArray[0]+" " +SLArray[1]
                  // }
                  return order;
                }
              }


            });
            this.postPaginationItems = this.listOrdersDisplayed;
            EventBus.$on('filtered-data', this.getListOrder);
          } else {
            this.listOrdersDisplayed = this.listOrdersFound;
            this.postPaginationItems = this.listOrdersDisplayed;
            EventBus.$on('filtered-data', this.getListOrder);
          }
        },
        filterByStatus: function() {
          let status = this.statusFilter;

          switch(+status){
            case 1:
              // console.log("satut:1");
              this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
                if (order.Etat=="Commande reçue") {

                  return order;
                }
              });
              break;
            case 2:
              this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
                if (order.Etat=="Commande confirmée") {

                  return order;
                }
              });
              break;
            case 3:
              this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
                if (order.Etat=="Commande confirmée - En attente validation ARC") {

                  return order;
                }
              });
              break;
            case 4 :
              this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
                if (order.Etat == "Commande confirmée - BPA validé") {

                  return order;
                }
              });
              break;
            case 5 :
              this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
                if (order.Etat == "En cours de fabrication") {

                  return order;
                }
              });
              break;
            case 6 :
              this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
                if (order.Etat == "En cours de fabrication - En retard") {

                  return order;
                }
              });
              break;
            case 7 :
              this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
                if (order.Etat == "Commande fabriquée") {

                  return order;
                }
              });
              break;
            case 8 :
              this.listOrdersDisplayed = this.listOrdersFound.filter((order) => {
                if (order.Etat == "Expédiée complète" || order.Etat=="Expédiée partielle") {

                  return order;
                }
              });

              break;
            default:

              this.listOrdersDisplayed = this.listOrdersFound;
              break;
          }
          this.postPaginationItems = this.listOrdersDisplayed;
          EventBus.$on('filtered-data', this.getListOrder);

        },
        openCloseModalMedia: function () {
          this.displayModalMedia = true;
        },
        setActionOnItem: function (value) {
          if (value === 5) {
            // window.location = this.urlResa;
            window.open(this.urlResa, '_blank');
          } else if (value === 6) {
            this.displayModalMedia = true;
          }
        },
        dlARC: function(codeClient,NumCom)
        {
          console.log(codeClient+" / "+NumCom);

          var xhr = new XMLHttpRequest()


          var donnees = this.urlArc+"/"+codeClient+"/"+NumCom;
          window.open(donnees,"_blank");
          console.log(donnees);

          axios.post(this.urlArc+"/"+codeClient+"/"+NumCom , "") .then(response => {
            this.$root.$emit('set-loading', false);
            var datas = JSON.parse(response.data);
            console.log(datas.code);
            if(datas.code=="Success")
            {
              console.log('haha');
              window.open("data:application/pdf," + encodeURI(datas.content),"_blank");
            }
            else
              console.log(response.data);

          }).catch(error =>  {
            console.log(error);
            this.$root.$emit('set-loading', false);
          });
        },
        onHiddenGlossary: function() {
          this.displayModalGlossary = false;
        },
        filterByCodeClient: function() {
          axios.post(this.apiGetByClientUrl,{
            codeClient: this.codeClientFilter
          })
              .then(response=>{
                this.listOrders=response.data.Commande;
                this.listOrders = response.data.Commande.map((order)=>{
                  order._showDetails = false;
                  let dateArray = order.Date.split('-');
                  new Date();
                  order.timestamp = new Date(dateArray[0],dateArray[1]-1,dateArray[2]).getTime();
                  if(order.SemaineLivraison[0]!="") {
                    let SLArray = order.SemaineLivraison[0].split('.');
                    order.semaineLivraison = "S "+ SLArray[0]+" " +SLArray[1]
                  }
                  return order;
                });
                this.listOrdersDisplayed = this.listOrders;
              })
              .catch(error=>{
                console.warn(error);
              });
        },
        onFileInput: function(index, event) {
          if (event.target.files[0]) {
            this.$refs['docFile'+index][0].value = event.target.files[0].name;
            this.bpaFiles[index].docFile = event.target.files[0];
            this.bpaFiles[index].docFileName = event.target.files[0].name;

          }
        },
        onDeclencheFileInput: function(index,event)
        {
          if (event.target.files[0]) {
            this.$refs['docFile'+index][0].value = event.target.files[0].name;
            this.declencheFiles[index].docFile = event.target.files[0];
            this.declencheFiles[index].docFileName = event.target.files[0].name;

          }
        },
        acceptOrder: function() {
          console.log('Bon pour accord', this.selectedOrder);
          console.log(this.acceptType);
          let data = new FormData();
          data.append('id', this.selectedOrder.documentNo);
          data.append('codeClient', this.selectedOrder.customerNo);
          data.append('refChantier', this.selectedOrder.orderReference);
          if (this.acceptType == 'accept-all') {
            //envoi direct à BC
            axios.post(this.urlAcceptBpa , data) .then(response => {
              this.$root.$emit('set-loading', false);
              console.log(response);
              if(response.data == "OK")
              {
                this.$bvModal.hide('bpaModal');
                this.$bvModal.show('acceptConfirmModal');
                this.docFile = null;
                this.docFileName = '';
                this.currentComment = '';
                this.acceptType = 'accept-all';
              }
              else
              {
                //erreur
                console.log("toto");
              }

            });

          } else if (this.acceptType == 'accept-modify') {
            let fileCount = 0
            for (let file of this.bpaFiles) {
              if (file.docFile) {
                fileCount++;
                data.append('docFiles[]', file.docFile, file.docFileName);
                data.append('docNames[]', file.docFileName);
              }
            }

            if (fileCount == 0) {
              data.append('docFiles', "");
              data.append('docNames', "");
            }

            data.append('comment', this.currentComment);

            axios.post(this.urlAcceptReserveBpa , data) .then(response => {
              this.$root.$emit('set-loading', false);
              console.log(response);
              if(response.data == "OK")
              {
                this.$bvModal.hide('bpaModal');
                this.$bvModal.show('acceptConfirmModal');
                this.docFile = null;
                this.docFileName = '';
                this.currentComment = '';
                this.acceptType = 'accept-modify';
              }
              else
              {
                //erreur
              }

            });
          }

          // this.$root.$emit('set-loading', true);
          // axios.post('' , data) .then(response => {
          //     this.$root.$emit('set-loading', false);
          //
          //     this.$bvModal.hide('acceptModal');
          //     this.$bvModal.show('acceptConfirmModal');
          //     this.docFile = null;
          //     this.docFileName = '';
          //     this.currentComment = '';
          //     this.acceptType = 'accept-all';
          // });
        },
        declineOrder: function() {
          this.declineError = false;
          if (!this.currentComment || this.currentComment.length == 0) {
            this.declineError = true;
          }
          if (!this.declineError) {
            console.log('Refus Bon pour accord', this.selectedOrder);

            let data = new FormData();
            data.append('id', this.selectedOrder.documentNo);
            data.append('codeClient', this.selectedOrder.customerNo);
            data.append('refChantier', this.selectedOrder.orderReference);

            let fileCount = 0
            for (let file of this.bpaFiles) {
              if (file.docFile) {
                fileCount++;
                data.append('docFiles[]', file.docFile, file.docFileName);
                data.append('docNames[]', file.docFileName);
              }
            }

            if (fileCount == 0) {
              data.append('docFiles', "");
              data.append('docNames', "");
            }

            data.append('comment', this.currentComment);

            this.$root.$emit('set-loading', true);
            axios.post(this.urlDeclineBpa , data) .then(response => {
              this.$root.$emit('set-loading', false);

              this.$bvModal.hide('bpaModal');
              this.$bvModal.show('declineConfirmModal');

              this.docFile = null;
              this.docFileName = '';
              this.currentComment = '';
            }).catch(error =>  {
              console.log(error);
              this.$root.$emit('set-loading', false);
            });
          }
        },
        cancelOrder: function(){

          console.log('Annulation de commande', this.selectedOrder);

          let data = new FormData();
          data.append('id', this.selectedOrder.documentNo);
          data.append('codeClient', this.selectedOrder.customerNo);
          data.append('refChantier', this.selectedOrder.orderReference);
          data.append('comment', this.currentComment);
          this.$root.$emit('set-loading', true);
          axios.post(this.urlDeclineOrder , data) .then(response => {
            this.$root.$emit('set-loading', false);

            this.$bvModal.hide('cancelOrderModal');
            this.$bvModal.show('cancelOrderModalConfirm');
            this.docFile = null;
            this.docFileName = '';
            this.currentComment = '';
            var btChange = document.querySelector("span[data-order-documentNo="+this.selectedOrder.documentNo+"]");
            btChange.innerHTML="Commande annulée";
          }).catch(error =>  {
            console.log(error);
            this.$root.$emit('set-loading', false);
          });

        },
        autreDemande: function(){
          console.log('Autre demande pour la commande ', this.selectedOrder);

          let data = new FormData();
          data.append('id', this.selectedOrder.documentNo);
          data.append('codeClient', this.selectedOrder.customerNo);
          data.append('refChantier', this.selectedOrder.orderReference);
          data.append('comment', this.currentComment);
          this.$root.$emit('set-loading', true);

          axios.post(this.urlAutreDemande , data) .then(response => {
            this.$root.$emit('set-loading', false);

            this.$bvModal.hide('autreDemandeModal');
            this.$bvModal.show('autreDemandeModalConfirm');
            this.docFile = null;
            this.docFileName = '';
            this.currentComment = '';
          }).catch(error =>  {
            console.log(error);
            this.$root.$emit('set-loading', false);
          });

        },
        triggerDeliveryWeek: function(item) {
          this.selectedOrder = item;

          this.deliveryDate = null;
          this.deliveryHours= null;
          this.deliveryTime = null;
          // Get availability
          let data = new FormData();
          data.append('id', item.documentNo);
          data.append('territoryCode', item.territoryCode);
          this.$root.$emit('set-loading', true);
          axios.post(this.urlGetCrenaux, data).then(response => {
            var arrayDate = [];
            var arrayTime=[];
            response.data.forEach((element)=>{

              var dateDelivery = (element["deliveryDate"]) ? element["deliveryDate"].split("T")[0].split("-") : new Date();
              var heureDelivery = (element["deliverySlotStart"]) ? element["deliverySlotStart"].split("T")[1].split(":") : new Date();
              var endDelivery = (element["deliverySlotEnd"]) ? element["deliverySlotEnd"].split("T")[1].split(":") : new Date();
              console.log(dateDelivery);
              console.log(heureDelivery);
              console.log(dateDelivery[0],dateDelivery[1],dateDelivery[2]);

              arrayDate.push(new Date(dateDelivery[0],dateDelivery[1]-1,dateDelivery[2]));
              var verif = arrayTime.find((e)=>{
                if(e.crenau == heureDelivery[0]+":"+heureDelivery[1] && e.date == dateDelivery[0]+"-"+dateDelivery[1]+"-"+dateDelivery[2])
                  return true;
                else
                  return false;
              });
              console.log(verif);
              if(verif == undefined)
              {
                arrayTime.push({crenau:heureDelivery[0]+":"+heureDelivery[1],date:dateDelivery[0]+"-"+dateDelivery[1]+"-"+dateDelivery[2],time:heureDelivery[0],creneauEnd:endDelivery[0]+":"+endDelivery[1]});
              }
            });
            this.deliveryHours = arrayTime.sort((a, b) => a.time - b.time);
            console.log(this.deliveryHours);
            this.$root.$emit('set-loading', false);


            this.disabledDates = {
              to: new Date(),
              customPredictor: function(date) {
                // disables the date if not in arrayDate
                return (arrayDate.find((enabledDate) => enabledDate.getFullYear() === date.getFullYear() && enabledDate.getMonth() === date.getMonth() && enabledDate.getDate() === date.getDate())) == undefined;
              }
            };

            this.$bvModal.show('triggerDeliveryModal');
          }).catch(error =>  {
            console.log(error);
            this.$root.$emit('set-loading', false);
          });
        },
        sendDelivery: function() {

          this.deliveryError = false;
          if (!this.deliveryTime || !this.deliveryDate) {
            this.deliveryError = true;
          }
          if (!this.deliveryError) {
           // console.log('Déclencher livraison', this.selectedOrder);
            let errorFile = false;
            let data = new FormData();
            data.append('id', this.selectedOrder.documentNo);
            data.append('refChantier', this.selectedOrder.orderReference);
            data.append('CodeCLient', this.selectedOrder.customerNo);
            data.append('ModeLivraison', this.selectedOrder.shipmentMethodDescription);
            data.append('PrestaLivraison', this.selectedOrder.shippingAgentServiceDescription);
            data.append('city', this.selectedOrder.shipToCity);
            data.append('date', moment(this.deliveryDate).format('YYYY-MM-DD'));
            data.append('time', this.deliveryTime);
            data.append('comment', this.currentComment);
            let fileCount = 0
            for (let file of this.declencheFiles) {
              if (file.docFile) {
                if(file.docFile.size > 5242880 || file.docFile.type != "image/jpg" && file.docFile.type != "image/png" && file.docFile.type !="image/jpeg")
                {
                  this.errorFile= true;
                  this.$bvModal.show("deliveryErrorFileModal");

                }
                fileCount++;
                data.append('docFiles[]', file.docFile, file.docFileName);
                data.append('docNames[]', file.docFileName);
              }
            }

            if (fileCount == 0) {
              data.append('docFiles', "");
              data.append('docNames', "");
            }
            if(!this.errorFile) {
              this.$root.$emit('set-loading', true);
              axios.post(this.urlDeclencherLivraison, data).then(response => {
                console.log(response);
                this.$root.$emit('set-loading', false);

                this.$bvModal.hide('triggerDeliveryModal');
                if (response.data && response.data == "OK")
                  this.$bvModal.show('deliveryConfirmModal');
                else {
                  if (response.data != 2 && response.data != "OK")
                    this.$bvModal.show("deliveryErrorModal");
                  else
                    this.$bvModal.show("deliveryErrorFileModal");
                }

                this.currentComment = '';
                this.selectedOrder.requestedDeliverySlot = moment(this.deliveryDate).format('YYYY-MM-DD') + "T" + this.deliveryTime;
                this.listOrdersDisplayed = this.listOrdersFound;

                this.postPaginationItems = this.listOrdersDisplayed.map((item2) => {
                  if(item2.id === this.selectedOrder.id){
                    this.selectedOrder._showDetails = true;
                    this.selectedOrder.requestedDeliverySlot = String(Mydate)+"T"+this.deliveryTime.split(" - ")[0]+":00";
                    return this.selectedOrder;
                  }
                  return item2;
                });

                this.deliveryDate = null;
                this.deliveryTime = null;
                //console.log(this.postPaginationItems);
                EventBus.$on('filtered-data', this.getListOrder);

              }).catch(error => {
                console.log(error);
                this.$root.$emit('set-loading', false);
              });
            }
            else
            {
              this.errorFile= false;
            }
          }
        },
        changeDeliveryWeek: function() {

          this.deliveryWeekError = false;
          if (!this.deliveryWeek) {
            this.deliveryWeekError = true;
          }
          if (!this.deliveryWeekError) {
            //console.log('Modifier semaine livraison', this.selectedOrder);

            let data = new FormData();
            data.append('id', this.selectedOrder.documentNo);

            data.append('deliveryWeek', this.deliveryWeek);
            data.append('codeClient', this.selectedOrder.customerNo);
            data.append('refChantier', this.selectedOrder.orderReference);
            this.$root.$emit('set-loading', true);
            axios.post(this.urlChangeDeliveryWeek , data) .then(response => {
              this.$root.$emit('set-loading', false);

              this.$bvModal.hide('changeDeliveryWeekModal');
              this.$bvModal.show('deliveryWeekConfirmModal');

              this.deliveryWeek = null;
              var btChange = document.querySelector("button[data-order-documentNo="+this.selectedOrder.documentNo+"]");
              // console.log(btChange);
              // console.log("button['data-order-documentNo'='"+this.selectedOrder.documentNo+"']");
              if(btChange)
                btChange.classList.add("d-none");

            }).catch(error =>  {
              console.log(error);
              this.$root.$emit('set-loading', false);
            });
          }
        },
        triggerDeliveryConditions: function(item) {
          this.selectedOrder = item;
          this.deliveryCart = true;
          this.deliveryWeekOrder = item.promisedDeliveryWeek.split(".")[0];
          console.log(item.promisedDeliveryWeek,this.deliveryWeekOrder);
          this.$bvModal.show('changeDeliveryModal');
        },
        changeDeliveryConditions: function() {
          console.log('Modifier conditions livraison', this.selectedOrder);

          let data = new FormData();
          data.append('id', this.selectedOrder.documentNo);
          data.append('codeClient', this.selectedOrder.customerNo);
          data.append('refChantier', this.selectedOrder.orderReference);

          data.append('deliveryAddress', this.deliveryAddress);
          data.append('deliveryCart', this.deliveryCart);
          data.append('deliveryHoist', this.deliveryHoist);
          data.append('deliveryPorteur', this.deliveryPorteur);
          data.append('deliveryRemorque', this.deliveryRemorque);
          data.append('comment', this.currentComment);

          this.$root.$emit('set-loading', true);
          console.log(data);
          axios.post(this.urlChangeLivraisonInfo , data) .then(response => {
            this.$root.$emit('set-loading', false);

            this.$bvModal.hide('changeDeliveryModal');
            if(response.data=="OK")
              this.$bvModal.show('deliveryConditionsConfirmModal');

            this.deliveryWeek = null;
          }).catch(error =>  {
            console.log(error);
            this.$root.$emit('set-loading', false);
          });
        },
        initDeclencheData: function() {
          this.acceptType = 'accept-all';
          this.acceptModifyType = 'now';
          this.declencheFiles = [{
            docFile:null,
            docFilename:null
          }];
        },
        initBPAData: function() {
          this.acceptType = 'accept-all';
          this.acceptModifyType = 'now';
          this.bpaFiles = [{
            docFile:null,
            docFilename:null
          }];
        },
        addBPAFile: function(event) {
                event.preventDefault();

                for (let file of this.bpaFiles) {
                    if (file.docFile == null) {
                        return;
                    }
                }

                this.bpaFiles.push({
                    docFile:null,
                    docFilename:null
                });
            },
        removeBPAFile: function(event, index) {
                event.preventDefault();

                if (this.bpaFiles.length <= 1) {
                  this.bpaFiles = [{
                    docFile:null,
                    docFilename:null
                  }];
                  this.$refs['docFile'+index][0].value = null;
                } else {
                  this.bpaFiles.splice(index, 1);

                  for (let i = 0; i < this.bpaFiles.length; i++) {
                    this.$refs['docFile'+i][0].value = this.bpaFiles[i].docFile.name;
                  }
                }

            },
        removeDeclencheFile: function(event,index)
        {
          event.preventDefault();

          if (this.declencheFiles.length <= 1) {
            this.declencheFiles = [{
              docFile:null,
              docFilename:null
            }];
            this.$refs['docFile'+index][0].value = null;
          } else {
            this.declencheFiles.splice(index, 1);

            for (let i = 0; i < this.declencheFiles.length; i++) {
              this.$refs['docFile'+i][0].value = this.declencheFiles[i].docFile.name;
            }
          }
        },
        majCrenau: function(item)
        {
          var date = item.getFullYear() + '-' + ('0' + (item.getMonth()+1)).slice(-2) +  '-'+ ('0' + item.getDate()).slice(-2);

          var listCreneaux = document.querySelectorAll("#deliveryTime option");
          listCreneaux.forEach((element) => {
            element.setAttribute("hidden",true);
          });
          var listCreneaux = document.querySelectorAll("#deliveryTime option[data-date='"+date+"']");

          listCreneaux.forEach((element) => {
            element.removeAttribute("hidden");
          });
        },
        changeEquipement: function(item)
        {
          var idCible = item.target.getAttribute("id");
          //console.log(item.target.getAttribute("id"));
          switch(idCible)
          {
            case "deliveryCart":
              this.deliveryHoist = false;
              this.deliveryPorteur = false;
              break;
            case "deliveryHoist":
              this.deliveryCart = false;
              this.deliveryPorteur= false;
              this.deliveryRemorque = false;
              break;
            case "deliveryRemorque":
              this.deliveryPorteur= false;
              this.deliveryHoist = false;
              break;
            case "deliveryPorteur" :
              this.deliveryCart = false;
              this.deliveryRemorque = false;
              this.deliveryHoist = false;
              break;
          }
        },
        dateWeek: function(a)
        {
          var d = a ? new Date(a) : new Date();
          d.setHours(0,0,0,0);
          d.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7);
          var w = new Date(d.getFullYear(), 0, 4);
          return ('0' + (1 + Math.round(((d.getTime() - w.getTime()) / 86400000 - 3 + (w.getDay() + 6) % 7) / 7))).slice(-2);
        },
        sortingChanged: function(e)
        {
          // console.log(e);
          // console.log(e.sortBy);
          let key = e.sortBy;
          // console.log(this.listOrdersFound);
          this.listOrdersDisplayed = this.listOrdersFound;

          this.listOrdersDisplayed.sort((a, b) =>
          {
            let res = 0;
            if (a[e.sortBy] < b[e.sortBy])
              res = -1;
            if (a[e.sortBy] > b[e.sortBy])
              res = 1;
            return e.sortDesc ? -res : res; });

          this.postPaginationItems = this.listOrdersDisplayed;
          EventBus.$on('filtered-data', this.getListOrder);
        },
        toggleRowDetails(item) {

          this.listOrdersDisplayed = this.listOrdersFound;

          item._showDetails = !item._showDetails;
          //chercher dans postPaginationItems


          this.postPaginationItems = this.listOrdersDisplayed.map((item2) => {
            if(item2.id === item.id){
              return item;
            }
            return item2;
          });

          // console.log(this.postPaginationItems);
          EventBus.$on('filtered-data', this.getListOrder);
        },
        toggleDetails: function(e)
        {
          // console.log(e);
          if(e._showDetails === false)
          {
            e._showDetails = true;
          }
          else
            e._showDetails = false;
        }
      }
    }
</script>
