<script>
    import Datepicker from 'vuejs-datepicker';
    import { fr } from 'vuejs-datepicker/dist/locale';
    import VueTimepicker from 'vue2-timepicker';
    import axios from 'axios';

    export default {
        name: 'AppLibraryAddEdit',
        props: {
            libraryMedia: {
                type: Object
            },
            libraryFilters: {
                type: Array
            }
        },
        components: {
            Datepicker: Datepicker,
            VueTimepicker: VueTimepicker
        },
        data: function () {
            return {
                fr: fr,

                confirmationModal: false,
                newMedia: true,
                selectedFilter: 0,
                selectedName: "",
                selectedDescription: "",
                selectedQuantity: 0,
                selectedPlan: "",
                selectedOrderStatus: "",
                selectedEmailStatus: "",
                selectedPublicStatus: "",
                creationDate: "",
                errors: {},
                publicationDate: null,
                monitoringDate: null,
                expiryDate: null,
                hasPublicationDate: true,
                hasExpiryDate: true,
                hasMonitoringDate: true,
                mediaFiles: [],
                addFile: false,
                mediaFile: "",
                mediaFilename: "",
                hasPlan: false,
                showEmptyFilesAlert: false
            }
        },
        watch: {
            selectedFilter: function (val) {
                let mainFilter = this.libraryFilters.find((filter) => (filter.id === +val));
                let filter = mainFilter;
                for(let i = mainFilter.lvl ; i > 0 ; i--) {
                    if(mainFilter.parent_filter) {
                        filter = mainFilter.parent_filter;
                        this.hasPlan = (filter.label ===  "Réservations de Maçonneries");
                    }
                }
            },
        },
        mounted: function() {
            if(this.libraryMedia.id) {
                this.newMedia = false;
                this.populateFormWithExistingMedia();
            }
            else
            {
                this.publicationDate = new Date();
            }
        },
        methods: {
            populateFormWithExistingMedia: function () {
                let filter = this.libraryMedia.filter;
                for(let i = this.libraryMedia.filter.lvl ; i > 0 ; i--) {
                    if(this.libraryMedia.filter.parent_filter) {
                        filter = this.libraryMedia.filter.parent_filter;
                        this.hasPlan = (filter.label ===  "Réservations de Maçonneries");
                    }
                }
                if(this.libraryMedia.id) {
                    this.selectedFilter = this.libraryMedia.filter.id;
                    this.selectedName = this.libraryMedia.name;
                    this.selectedDescription = this.libraryMedia.description;
                    this.selectedQuantity = this.libraryMedia.quantity ? this.libraryMedia.quantity : null;
                    this.selectedPlan = this.libraryMedia.plan ? this.libraryMedia.plan : "";
                    this.selectedOrderStatus = this.libraryMedia.can_be_ordered;
                    this.selectedEmailStatus = this.libraryMedia.can_be_sent_by_mail;
                    this.selectedPublicStatus = this.libraryMedia.is_public;

                    for(let file of this.libraryMedia.files) {
                        this.mediaFiles.push({
                            id: file.id,
                            filename: file.filename,
                            path: file.path
                        });
                    }
//                    var _pDate = this.libraryMedia.publication_date.split("-");
                    this.publicationDate = this.libraryMedia.publication_date;
                    //this.publicationDate = _pDate[2]+"/"+_pDate[1]+"/"+_pDate[0];
                    //if(this.libraryMedia.monitoring_date) {
                        this.monitoringDate = this.libraryMedia.monitoring_date;
                    //}

                    //if(this.libraryMedia.expiry_date) {
                        this.expiryDate = this.libraryMedia.expiry_date;
                    //}

                    this.creationDate = this.formatJSDate(this.libraryMedia.created_at);
                }
            },
            postData(e) {
                let data = new FormData();

                data.append('filter', this.selectedFilter);
                data.append('name', this.selectedName);
                if(this.selectedDescription) {
                    data.append('description', this.selectedDescription);
                }
                data.append('quantity', (this.selectedQuantity) ? +this.selectedQuantity : 0);
                if(this.selectedPlan) {
                    data.append('plan', this.selectedPlan);
                }
                data.append('can_be_ordered', this.selectedOrderStatus);
                data.append('can_be_sent_by_mail', this.selectedEmailStatus);
                data.append('is_public', this.selectedPublicStatus);
                data.append('publication_date', this.formatTime(this.publicationDate));
                console.log(this.publicationDate);
                console.log(this.monitoringDate);
                if(this.monitoringDate) {
                    data.append('monitoring_date', this.formatTime(this.monitoringDate));
                }
                if(this.expiryDate) {
                    data.append('expiry_date', this.formatTime(this.expiryDate));
                }
                for(let i = 0 ; i < this.mediaFiles.length ; i++) {
                    if(this.mediaFiles[i] != null) {
                        data.set("document" + i, this.mediaFiles[i].file);
                        data.append('documentName' + i, this.mediaFiles[i].filename);
                    }
                }

                const url = e.target.action + (!this.libraryMedia.id ? "" : "/" + this.libraryMedia.id);

                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };

                axios.post(url, data, config)
                    .then((response) => {
                        console.log(response.data);
                        if(response.status === 201) {
                            this.confirmationModal = true;
                            let files = response.data.files;
                            this.mediaFiles = [];
                            for(let file of files) {
                                this.mediaFiles.push({
                                    id: file.id,
                                    filename: file.filename,
                                    path: file.path
                                });
                            }
                        } else {
                            this.errors = response.data.errors;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            },
            onFileInput: function () {
                if (this.$refs.mediaFileInput.files[0]) {
                    this.$refs.mediaFile.value = this.$refs.mediaFileInput.files[0].name;
                    this.mediaFile = this.$refs.mediaFileInput.files[0];
                    this.mediaFilename = this.$refs.mediaFileInput.files[0].name;
                }
            },
            addFileToMedia: function () {
                if(this.$refs.mediaFileInput.files[0]) {
                    this.mediaFiles.push({
                        id: 0,
                        file: this.$refs.mediaFileInput.files[0],
                        filename: this.$refs.mediaFileInput.files[0].name
                    });
                    this.addFile = false;
                }
                this.$refs.mediaFile.value = "";
            },
            deleteSelectedFile: function(event, filename, id) {
                let index = this.mediaFiles.map((file)=> file.filename).indexOf(filename);
                this.mediaFiles.splice(index, 1);
                if(id > 0) {
                    axios.delete(event.target.action)
                        .catch((error) => {
                            console.error(error);
                        });
                }
            },
            mediaCheckFileExtension: function (filename) {
                return filename.split('.').pop();
            },
            resetForm: function() {
                this.selectedFilter = 0;
                this.selectedName = "";
                this.selectedDescription = "";
                this.selectedQuantity = 0;
                this.selectedPlan = "";
                this.selectedOrderStatus = "";
                this.selectedEmailStatus = "";
                this.selectedPublicStatus = "";
                this.creationDate = "";
                this.publicationDate = null;
                this.monitoringDate = null;
                this.expiryDate = null;
                this.mediaFiles= [];
                this.mediaFile= "";
                this.mediaFilename= "";
                this.confirmationModal = false;
            },
            formatJSDate: function (date) {
                console.log(date);
                let d = new Date(date);
                let dd = (d.getDate() < 10 ? '0' : '') + d.getDate();
                let MM = ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
                console.log(dd + '/' + MM + '/' + d.getFullYear());
                return dd + '/' + MM + '/' + d.getFullYear();
            },
            formatTime: function (date) {
                console.log(date);
                let d = new Date(date);
                console.log(d);
                console.log(d/1000);
                return (d / 1000);
            }

        }
    }
</script>