<script>
    import axios from 'axios';

    export default {
        name: 'AppQuoteTemplateForm',
        props: {
            token: {
                type: String
            },
            quoteTemplate:
                {
                    type:String
                },
        },
        data: function () {
            return {
                logoFileName: '',
                hasFacebook: '',
                urlFacebook:'',
                hasLinkedin: '',
                urlLinkedin:'',
                hasTwitter: '',
                urlTwitter:'',
                hasWebsite: '',
                urlWebsite:'',
                hasInstagram: '',
                urlInstagram:'',
                companyName:'',
                businessAgency:'',
                address:'',
                additionalAddress:'',
                zipCode:'',
                city:'',
                tel:'',
                email:'',
                shareCapital:'',
                siret:'',
                vatNumber:'',
                ape:'',
                insurance:'',
                heading:'',
                paymentTerms:'',
                validity:'',
                certifications:[],
                id:0,
                dirty : false,
                submitted : false,
                error:0,
                openMessageModal: false
            }
        },
        mounted:function(){
            let obj = JSON.parse(this.quoteTemplate);

            if(obj!=null)
            {
                if(obj.id !==0)
                {
                    this.id = obj.id;
                    //this.$refs.logoFile.value = obj.logo;
                    //this.$refs.logoInput.files[0] = obj.logo;
                    this.hasFacebook= (obj.hasFacebook == 1) ? true : '';
                    this.urlFacebook= obj.urlFacebook == "null" ? '' : obj.urlFacebook;
                    this.hasLinkedin= (obj.hasLinkedin==1) ? true : '';
                    this.urlLinkedin= obj.urlLinkedin == "null" ? '' : obj.urlLinkedin;
                    this.hasTwitter= (obj.hasTwitter ==1) ? true : '';
                    this.urlTwitter= obj.urlTwitter == "null" ? '' : obj.urlTwitter;
                    this.hasWebsite= (obj.hasWebsite ==1) ? true : '';
                    this.urlWebsite= obj.urlWebsite == "null" ? '' : obj.urlWebsite;
                    this.hasInstagram= (obj.hasInstagram==1) ? true : '';
                    this.urlInstagram= obj.urlInstagram == "null" ? '' : obj.urlInstagram;
                    this.companyName= obj.companyName;
                    this.businessAgency= obj.businessAgency;
                    this.address= obj.address;
                    this.additionalAddress= obj.additionalAddress;
                    this.zipCode= obj.zipCode;
                    this.city= obj.city;
                    this.tel= obj.tel;
                    this.email= obj.email;
                    this.shareCapital= obj.shareCapital;
                    this.siret= obj.siret;
                    this.vatNumber= obj.vatNumber;
                    this.ape= obj.ape;
                    this.insurance= obj.insurance;
                    this.heading= obj.heading;
                    this.paymentTerms= obj.paymentTerms;
                    this.validity= obj.validity;
                    for(let certification of obj.certifications)
                    {
                        this.certifications.push(certification.label) ;
                    }
                }
            }

        }
        ,
        methods : {
            onFileInput:function () {
                if (this.$refs.logoInput.files[0]) {
                    this.$refs.logoFile.value = this.$refs.logoInput.files[0].name;
                    this.logoFile = this.$refs.logoInput.files[0];
                    this.logoFileName = this.$refs.logoInput.files[0].name;

                    if (this.$refs.logoPreview) {
                      var reader = new FileReader();
                      reader.onload = (e) => {
                        this.$refs.logoPreview.src = e.target.result;
                      };
                      reader.readAsDataURL(this.logoFile);
                    }
                }
            },
            validate: function() {
                return (
                    (this.id === 0 && this.validInput(this.logoFileName, this.$refs.logoInput) || this.id !== 0)
                    && this.validInput(this.companyName, this.$refs.companyNameInput)
                    && this.validInput(this.address, this.$refs.addressInput)
                    && this.validZip(this.zipCode, 'fr', this.$refs.zipCodeInput)
                    && this.validInput(this.city, this.$refs.cityInput)
                    && this.validPhone(this.tel, this.$refs.telInput)
                    && this.validEmail(this.email, this.$refs.emailInput)
                    && this.validInput(this.shareCapital, this.$refs.shareCapitalInput)
                    && this.validInput(this.siret, this.$refs.siretInput)
                    && this.validInput(this.vatNumber, this.$refs.vatNumberInput)
                    && this.validInput(this.ape, this.$refs.apeInput)
                    && this.validInput(this.insurance, this.$refs.insuranceInput)
                    && this.validInput(this.heading, this.$refs.headingInput)
                    && this.validInput(this.paymentTerms, this.$refs.paymentTermsInput)
                    && this.validInput(this.validity, this.$refs.validityInput)
                );
            },
            validEmail: function(value, input) {
                var re = /(.+)@(.+){2,}\.(.+){2,}/;
                let valid = (!this.dirty || this.dirty && re.test(value.toLowerCase()));
                if (!valid && input) {
                    window.scrollTo(0, input.getBoundingClientRect().top - document.body.getBoundingClientRect().top - document.querySelector(".bouvet-header").clientHeight - 40);
                }
                return valid;
            },
            validInput: function(value, input) {
                let valid =  (!this.dirty || this.dirty && value != "");
                if (!valid && input) {
                    window.scrollTo(0, input.getBoundingClientRect().top - document.body.getBoundingClientRect().top - document.querySelector(".bouvet-header").clientHeight - 40);
                }

                return valid;
            },
            validPhone: function(value, format, input) {
                let valid =  (!this.dirty || this.dirty && value != "");
                if (!valid && input) {
                    window.scrollTo(0, input.getBoundingClientRect().top - document.body.getBoundingClientRect().top - document.querySelector(".bouvet-header").clientHeight - 40);
                }
                return valid;
            },
            validZip: function(value, format, input) {
                let valid =  (!this.dirty || this.dirty && value != "");
                if (!valid && input) {
                    window.scrollTo(0, input.getBoundingClientRect().top - document.body.getBoundingClientRect().top - document.querySelector(".bouvet-header").clientHeight - 40);
                }
                return valid;
            },
            postData: function(event)
            {
                this.dirty = true;

                if(this.validate()){
                    let data = new FormData();
                    for (var key in this.$data) {
                        data.append(key, this.$data[key]);
                    }

                    if (this.logoFile) {
                        data.set('logoFile', this.logoFile, this.logoFile.name);
                        data.append('logoName', this.logoFileName);
                    }

                    data.append('quotation_tempate', '');
                    data.append('_token', this.token);

                    const config = {
                        headers: { 'content-type': 'multipart/form-data' }
                    }

                    let url = event.target.action + (this.id===0 ? '' : '/' + this.id);
                    data.append('_method', this.id===0 ? 'post' : 'patch');

                    this.$root.$emit('set-loading', true);
                    axios.post(url, data, config)
                        .then(response=>{
                            if(response.request.responseURL.includes("connexion"))
                            {
                                location.href = response.request.responseURL;
                            }
                            else
                            {
                                this.$root.$emit('set-loading', false);
                                this.error = response.data;
                                this.openMessageModal = true;
                                //this.id=response.data.id;
                            }
                        })
                        .catch(error=>{
                            this.$root.$emit('set-loading', false);
                            console.warn(error);
                        });

                    this.submitted = true;
                }
            }
        }
    }
</script>
