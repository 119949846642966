var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row pt-4 justify-content-center new-project__content__body",
    },
    [
      _c(
        "div",
        { staticClass: "col-md" },
        [
          _vm.projectStatus == 0
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "row align-items-end" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-5 h-100 form-group" },
                      [
                        _vm._t("landmark"),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class: { "text-danger": _vm.isErrorSyntaxeItem },
                            staticStyle: { "font-size": "12px" },
                          },
                          [
                            _vm._v(
                              "6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace."
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.importedLandmark,
                              expression: "importedLandmark",
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: {
                            width: "37%",
                            "text-transform": "uppercase",
                          },
                          attrs: {
                            type: "text",
                            id: "project_imported-landmark",
                            placeholder: _vm.inputPlaceholder,
                            required: "required",
                          },
                          domProps: { value: _vm.importedLandmark },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.importedLandmark = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.isDuplicateLandmark
                          ? _c(
                              "span",
                              {
                                staticClass: "text-danger",
                                staticStyle: { "font-size": "12px" },
                              },
                              [_vm._v("Le nom du repère doit être unique")]
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row align-items-end" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-3 col-lg-2 h-100 form-group" },
                      [
                        _vm._t("carpentry"),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.importedCarpentry,
                                expression: "importedCarpentry",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "importedCarpentry",
                              id: "project_imported-carpentry",
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.importedCarpentry = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.selectGamme()
                                },
                              ],
                            },
                          },
                          _vm._l(
                            _vm.filteredCarpentryLabels,
                            function (carpentry) {
                              return _c(
                                "option",
                                { domProps: { value: carpentry } },
                                [_vm._v(_vm._s(carpentry))]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.importedGammeLabels.length > 0
                      ? _c(
                          "div",
                          { staticClass: "col-md-4 col-lg-2 h-100 form-group" },
                          [
                            _vm._t("gamme"),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.importedGamme,
                                    expression: "importedGamme",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "importedGamme",
                                  id: "project_imported-gamme",
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.importedGamme = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(_vm.importedGammeLabels, function (gamme) {
                                return _c(
                                  "option",
                                  { domProps: { value: gamme } },
                                  [_vm._v(_vm._s(gamme))]
                                )
                              }),
                              0
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-auto h-100 form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "arrowed-link-rigth btn btn-rounded btn-rounded--dark-blue px-4",
                          on: {
                            click: function ($event) {
                              return _vm.toggleItemModal()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configureProductLabel))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-3" },
                      [_vm._t("requiredBis")],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row align-items-end" },
                    [
                      _vm._t("nonConfigurable"),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "col-12",
                          class: { "text-danger": _vm.isErrorSyntaxeItemBis },
                          staticStyle: { "font-size": "12px" },
                        },
                        [
                          _vm._v(
                            "6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace."
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mt-3 mt-md-0 col-md-2 h-100 form-group",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.nonConfigurableLandmark,
                                expression: "nonConfigurableLandmark",
                              },
                            ],
                            staticClass: "form-control",
                            staticStyle: { "text-transform": "uppercase" },
                            attrs: {
                              type: "text",
                              id: "project_landmark",
                              placeholder: _vm.landmarkPlaceholder,
                              required: "required",
                            },
                            domProps: { value: _vm.nonConfigurableLandmark },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.nonConfigurableLandmark =
                                  $event.target.value
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-2 h-100 form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.nonConfigurableImportedQuote,
                              expression: "nonConfigurableImportedQuote",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "project_quote",
                            placeholder: _vm.quoteNumberPlaceholder,
                            required: "required",
                          },
                          domProps: { value: _vm.nonConfigurableImportedQuote },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.nonConfigurableImportedQuote =
                                $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col h-100 form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.nonConfigurableCarpentry,
                              expression: "nonConfigurableCarpentry",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "project_carpentry",
                            placeholder: _vm.carpentryPlaceholder,
                            required: "required",
                          },
                          domProps: { value: _vm.nonConfigurableCarpentry },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.nonConfigurableCarpentry = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-2 h-100 form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.nonConfigurablePurchasingPriceExTax,
                              expression: "nonConfigurablePurchasingPriceExTax",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            lang: "en-150",
                            id: "project_purchase_price",
                            placeholder: _vm.purchasePriceExTaxPlaceholder,
                            required: "required",
                          },
                          domProps: {
                            value: _vm.nonConfigurablePurchasingPriceExTax,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.nonConfigurablePurchasingPriceExTax =
                                $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-auto h-100 form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "arrowed-link-rigth btn btn-rounded btn-rounded--dark-blue px-4",
                            on: {
                              click: function ($event) {
                                return _vm.addNonConfigurableItem()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.addToQuoteLabel))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 mb-3" },
                        [
                          _vm.isDuplicateLandmarkNonConfigurated
                            ? _c(
                                "span",
                                {
                                  staticClass: "text-danger",
                                  staticStyle: { "font-size": "12px" },
                                },
                                [_vm._v("Le nom du repère doit être unique")]
                              )
                            : _vm._e(),
                          _c("br"),
                          _vm._v(" "),
                          _vm._t("required"),
                        ],
                        2
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "row align-items-end" }, [
                    _c(
                      "div",
                      { staticClass: "col form-group" },
                      [
                        _vm._t("customService"),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.customLandmark,
                              expression: "customLandmark",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "project_customService",
                            placeholder: _vm.inputPlaceholder,
                            required: "required",
                          },
                          domProps: { value: _vm.customLandmark },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.customLandmark = $event.target.value
                            },
                          },
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-auto h-100 form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "arrowed-link-rigth btn btn-rounded btn-rounded--dark-blue px-4",
                          on: {
                            click: function ($event) {
                              return _vm.addCustomItem()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.addToQuoteLabel))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._t("myCarpentry"),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-table", {
            directives: [
              {
                name: "sortable",
                rawName: "v-sortable",
                value: _vm.sortableOptions,
                expression: "sortableOptions",
              },
            ],
            staticClass: "project-table simple-table mb-0",
            attrs: {
              stacked: "md",
              hover: "",
              id: "listItems",
              items: _vm.quoteItems,
              fields: _vm.fields,
              "primary-key": "id",
            },
            on: { change: _vm.goToTop },
            scopedSlots: _vm._u([
              {
                key: "cell(id)",
                fn: function (row) {
                  return [
                    _c("i", {
                      staticClass: "d-none d-lg-inline icon icon-expand",
                    }),
                  ]
                },
              },
              {
                key: "cell(landmark)",
                fn: function (row) {
                  return [
                    row.item.origin !== 2
                      ? _c("div", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(row.item.landmark) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(carpentry)",
                fn: function (row) {
                  return [
                    row.item.origin < 1
                      ? _c(
                          "div",
                          { staticClass: "project-table__small" },
                          [
                            _vm._l(
                              row.item.summaryConfiguration,
                              function (desc) {
                                return _c("span", [
                                  desc.mandatory == true
                                    ? _c("span", [
                                        _c("b", [_vm._v(_vm._s(desc.label))]),
                                        _vm._v(" : " + _vm._s(desc.value)),
                                        _c("br"),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            row.item.origin === 1
                              ? _c("span", { staticClass: "small" }, [
                                  _vm._v(_vm._s(row.item.importedQuote)),
                                ])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    row.item.origin === 2
                      ? _c("div", { staticClass: "input-group" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(row.item.landmark) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.item.origin === 1
                      ? _c("div", { staticClass: "input-group" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(row.item.carpentry)
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                        " +
                              _vm._s(row.item.importedQuote) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(quantity)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "input-group input-group__increment" },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                row.item.quantity === 0
                                  ? (row.item.quantity = 0)
                                  : row.item.quantity--
                                _vm.updateOnQuantityChange(row.item)
                              },
                            },
                          },
                          [_vm._v("-")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: row.item.quantity,
                              expression: "row.item.quantity",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            min: "1",
                            lang: "en-150",
                            disabled: _vm.projectStatus != 0,
                          },
                          domProps: { value: row.item.quantity },
                          on: {
                            blur: function ($event) {
                              return _vm.updateOnQuantityBlur(row.item)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                row.item,
                                "quantity",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                row.item.quantity++
                                _vm.updateOnQuantityChange(row.item)
                              },
                            },
                          },
                          [_vm._v("+")]
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(purchasingPrice)",
                fn: function (row) {
                  return [
                    row.item.origin < 2
                      ? _c("div", { staticClass: "project-table__value" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatPrice")(
                                  _vm._f("toFixed")(
                                    row.item.purchasingPriceExTax
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(ratio)",
                fn: function (row) {
                  return [
                    row.item.origin < 2
                      ? _c(
                          "div",
                          { staticClass: "input-group input-group__increment" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    row.item.marginRatio === 0
                                      ? (row.item.marginRatio = 0)
                                      : (row.item.marginRatio =
                                          Math.round(
                                            (+row.item.marginRatio - 0.1) * 10
                                          ) / 10)
                                    _vm.updateOnMarginChange()
                                  },
                                },
                              },
                              [_vm._v("-")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: row.item.marginRatio,
                                  expression: "row.item.marginRatio",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                lang: "en-150",
                                step: "0.1",
                                min: "1",
                                disabled: _vm.projectStatus != 0,
                              },
                              domProps: { value: row.item.marginRatio },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      row.item,
                                      "marginRatio",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.updateOnMarginChange()
                                  },
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    row.item.marginRatio =
                                      Math.round(
                                        (+row.item.marginRatio + 0.1) * 10
                                      ) / 10
                                    _vm.updateOnMarginChange()
                                  },
                                },
                              },
                              [_vm._v("+")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(laying)",
                fn: function (row) {
                  return [
                    row.item.origin < 2
                      ? _c(
                          "div",
                          { staticClass: "input-group input-group__increment" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    row.item.laying === 0
                                      ? (row.item.laying = 0)
                                      : row.item.laying--
                                    _vm.updateOnLayingChange()
                                  },
                                },
                              },
                              [_vm._v("-")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: row.item.laying,
                                  expression: "row.item.laying",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                lang: "en-150",
                                step: "10",
                                min: "0",
                                disabled: _vm.projectStatus != 0,
                              },
                              domProps: { value: row.item.laying },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      row.item,
                                      "laying",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.updateOnLayingChange()
                                  },
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    row.item.laying++
                                    _vm.updateOnMarginChange()
                                  },
                                },
                              },
                              [_vm._v("+")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(sellingPrice)",
                fn: function (row) {
                  return [
                    row.item.origin < 2
                      ? _c("div", { staticClass: "project-table__value" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm._f("formatPrice")(
                                  _vm._f("toFixed")(
                                    _vm.calculateItemSellingPrice(
                                      row.item.purchasingPriceExTax,
                                      row.item.marginRatio,
                                      row.item.laying
                                    )
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    row.item.origin === 2
                      ? _c(
                          "div",
                          { staticClass: "input-group input-group--small" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: row.item.sellingPrice,
                                  expression: "row.item.sellingPrice",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                lang: "en-150",
                                step: "10",
                                disabled: _vm.projectStatus != 0,
                              },
                              domProps: { value: row.item.sellingPrice },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      row.item,
                                      "sellingPrice",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    return _vm.updateOnSellingPriceChange()
                                  },
                                ],
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _c("br"),
                  ]
                },
              },
              {
                key: "cell(vat)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "input-group input-group--small" },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: row.item.vat,
                                expression: "row.item.vat",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              name: "vat",
                              id: "vat",
                              disabled: _vm.projectStatus != 0,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    row.item,
                                    "vat",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.calculateTotalClientVAT()
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.vat, function (v) {
                            return _c(
                              "option",
                              { domProps: { value: v.value } },
                              [_vm._v(_vm._s(v.label))]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _vm.projectStatus == 0
                      ? _c(
                          "div",
                          { staticClass: "text-left text-lg-center" },
                          [
                            _c("app-action-dropdown", {
                              attrs: {
                                "row-index": row.index,
                                "item-id": row.item.id,
                                audience:
                                  row.item.origin == 0
                                    ? "productItem"
                                    : "quoteItem",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "row justify-content-end no-gutters bg-white-three",
            },
            [
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "simple-table__element-container" },
                  [
                    _vm._t("purchaseLabel"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-inline-block d-lg-block ml-2 ml-lg-0 mt-lg-2",
                      },
                      [
                        _vm.totalClientPriceExTax > 0
                          ? [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("formatPrice")(
                                      _vm._f("toFixed")(
                                        _vm.totalClientPriceExTax
                                      )
                                    )
                                  ) +
                                  " " +
                                  _vm._s(_vm.euroExtax) +
                                  "\n                            "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.totalClientPriceExTax) +
                                  " " +
                                  _vm._s(_vm.euroExtax) +
                                  "\n                            "
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "simple-table__element-container" },
                  [
                    _vm._t("marginLabel"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-inline-block d-lg-block ml-2 ml-lg-0 mt-lg-2",
                      },
                      [
                        _vm.totalMarginRatio > 0
                          ? [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("formatPrice")(
                                      _vm._f("toFixed")(_vm.totalMarginRatio)
                                    )
                                  ) +
                                  " " +
                                  _vm._s(_vm.euroExtax) +
                                  "\n                            "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.totalMarginRatio) +
                                  " " +
                                  _vm._s(_vm.euroExtax) +
                                  "\n                            "
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "simple-table__element-container" },
                  [
                    _vm._t("layingLabel"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-inline-block d-lg-block ml-2 ml-lg-0 mt-lg-2",
                      },
                      [
                        _vm.totalLaying > 0
                          ? [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("formatPrice")(
                                      _vm._f("toFixed")(_vm.totalLaying)
                                    )
                                  ) +
                                  " " +
                                  _vm._s(_vm.euroExtax) +
                                  "\n                            "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.totalLaying) +
                                  " " +
                                  _vm._s(_vm.euroExtax) +
                                  "\n                            "
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "simple-table__element-container" },
                  [
                    _vm._t("discountLabel"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-inline-block ml-2 ml-lg-0 mt-lg-2 align-middle text-nowrap",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "input-group",
                            staticStyle: { width: "4.4em" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.clientDiscount,
                                  expression: "clientDiscount",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                lang: "en-150",
                                min: "0",
                                step: "10",
                                disabled: _vm.projectStatus != 0,
                              },
                              domProps: { value: _vm.clientDiscount },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.clientDiscount = $event.target.value
                                  },
                                  function ($event) {
                                    return _vm.calculateTotalClientExTax()
                                  },
                                ],
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {}, [_vm._v(" " + _vm._s(_vm.euroExtax))]),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3 bg-dark-blue" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "simple-table__element-container simple-table__element-container--white",
                  },
                  [
                    _vm._t("totalLabel"),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "mt-2" },
                      [
                        _vm.totalClientExTaxPrice > 0
                          ? [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("formatPrice")(
                                      _vm._f("toFixed")(
                                        _vm.totalClientExTaxPrice
                                      )
                                    )
                                  ) +
                                  " " +
                                  _vm._s(_vm.euroExtax) +
                                  "\n                            "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.totalClientExTaxPrice) +
                                  " " +
                                  _vm._s(_vm.euroExtax) +
                                  "\n                            "
                              ),
                            ],
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _vm.totalClientPriceVAT > 0
                          ? [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("formatPrice")(
                                      _vm._f("toFixed")(_vm.totalClientPriceVAT)
                                    )
                                  ) +
                                  " " +
                                  _vm._s(_vm.euroTax) +
                                  "\n                            "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.totalClientPriceVAT) +
                                  " " +
                                  _vm._s(_vm.euroTax) +
                                  "\n                            "
                              ),
                            ],
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _vm.totalClientIncTaxPrice > 0
                          ? [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("formatPrice")(
                                      _vm._f("toFixed")(
                                        _vm.totalClientIncTaxPrice
                                      )
                                    )
                                  ) +
                                  " " +
                                  _vm._s(_vm.euroInctax) +
                                  "\n                            "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.totalClientIncTaxPrice) +
                                  " " +
                                  _vm._s(_vm.euroInctax) +
                                  "\n                            "
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.projectStatus == 0
            ? _c("div", { staticClass: "text-right my-4" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "arrowed-link-rigth btn btn-rounded btn-rounded--dark-blue px-4",
                    on: {
                      click: function ($event) {
                        return _vm.saveQuote()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.saveLabel))]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.projectStatus == 0 ? _vm._t("bonuses") : _vm._e(),
          _vm._v(" "),
          _vm.projectStatus == 0
            ? _c("div", { staticClass: "row text-center" }, [
                _c("div", { staticClass: "col-md my-2 my-md-0" }, [
                  _c("div", { staticClass: "bg-dark-blue p-2 p-md-3 h-100" }, [
                    _c("div", { staticClass: "row h-100" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 align-self-start mb-3" },
                        [_vm._t("unfinishedQuoteLabel")],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 align-self-end" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "arrowed-link-rigth btn btn-rounded btn-rounded--white px-3",
                            on: {
                              click: function ($event) {
                                return _vm.toggleDevisIncomplet()
                              },
                            },
                          },
                          [_vm._t("unfinishedQuoteButton")],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-md my-2 my-md-0",
                    staticStyle: { display: "none" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "bg-dark-blue p-2 p-md-3 h-100" },
                      [
                        _c("div", { staticClass: "row h-100" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 align-self-start mb-3" },
                            [_vm._t("alternativeQuoteLabel")],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 align-self-end" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "arrowed-link btn btn-rounded btn-rounded--white px-3",
                              },
                              [_vm._t("alternativeQuoteButton")],
                              2
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md my-2 my-md-0" }, [
                  _c("div", { staticClass: "bg-dark-blue p-2 p-md-3 h-100" }, [
                    _c("div", { staticClass: "row h-100" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 align-self-start mb-3" },
                        [
                          _c(
                            "span",
                            { staticClass: "text-white" },
                            [_vm._t("alertLabel")],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 align-self-end" }, [
                        _vm.errorAlert === -1
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "arrowed-link-rigth btn btn-rounded btn-rounded--white px-3",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleAlert()
                                  },
                                },
                              },
                              [_vm._t("alertButton")],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.errorAlert === 0
                          ? _c("div", { staticClass: "text-white" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                                        Une alerte a été créée pour"
                                ),
                                _c("br"),
                                _vm._v(
                                  " le " +
                                    _vm._s(_vm.chosenDate) +
                                    ".\n                                    "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md my-2 my-md-0" }, [
                  _c("div", { staticClass: "bg-dark-blue p-2 p-md-3 h-100" }, [
                    _c("div", { staticClass: "row h-100" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 align-self-start mb-3" },
                        [
                          _c(
                            "span",
                            { staticClass: "text-white" },
                            [_vm._t("sheetLabel")],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 align-self-end" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "arrowed-link-rigth btn btn-rounded btn-rounded--white px-3",
                            on: {
                              click: function ($event) {
                                return _vm.toggleSheetModal()
                              },
                            },
                          },
                          [_vm._t("sheetButton")],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          staticClass: "iframe",
          attrs: {
            centered: "",
            "hide-footer": "",
            size: "xl",
            title: _vm.modalTitle,
            "no-enforce-focus": "",
            id: "new-project-config",
            "no-close-on-backdrop": true,
            "no-close-on-esc": true,
          },
          on: { hide: _vm.clearConfigurationFrame },
          model: {
            value: _vm.addItemModal,
            callback: function ($$v) {
              _vm.addItemModal = $$v
            },
            expression: "addItemModal",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "row justify-content-center",
              staticStyle: { height: "100%" },
            },
            [
              _c("iframe", {
                staticClass: "col-12",
                staticStyle: { display: "inline-block" },
                attrs: { height: "90%", id: "configurationFrame", src: "" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "md" },
          on: { hidden: _vm.onHiddenDelete },
          model: {
            value: _vm.openDeleteModal,
            callback: function ($$v) {
              _vm.openDeleteModal = $$v
            },
            expression: "openDeleteModal",
          },
        },
        [
          _vm.errorDelete === -1
            ? _c("div", [
                _c(
                  "p",
                  { staticClass: "text-center" },
                  [
                    _vm._t("confirmDelete"),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.modalItemId,
                          expression: "modalItemId",
                        },
                      ],
                      attrs: { type: "hidden" },
                      domProps: { value: _vm.modalItemId },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.modalItemId = $event.target.value
                        },
                      },
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorDelete === 0
            ? _c("div", [
                _c(
                  "p",
                  { staticClass: "text-center" },
                  [_vm._t("deleteCarpentryMessage")],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorDelete === -1
            ? _c(
                "div",
                { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-rounded btn-rounded--white border-black px-3 mr-2",
                      on: {
                        click: function ($event) {
                          return _vm.deleteItem(_vm.modalItemId)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.yesLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-rounded btn-rounded--black px-3",
                      on: {
                        click: function ($event) {
                          _vm.openDeleteModal = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.noLabel))]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorDelete === 0
            ? _c("div", {
                attrs: { slot: "modal-footer" },
                slot: "modal-footer",
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "lg" },
          model: {
            value: _vm.openUpdateModal,
            callback: function ($$v) {
              _vm.openUpdateModal = $$v
            },
            expression: "openUpdateModal",
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.modalItemId,
                expression: "modalItemId",
              },
            ],
            attrs: { type: "hidden" },
            domProps: { value: _vm.modalItemId },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.modalItemId = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _vm.currentItemOrigin === 0
            ? [
                _c("label", [_vm._v(_vm._s(_vm.landmarkPlaceholder) + " :")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class: { "text-danger": _vm.isErrorSyntaxeItem },
                    staticStyle: { "font-size": "12px" },
                  },
                  [
                    _vm._v(
                      "6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace."
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  ref: "modalInputLandmark",
                  staticClass: "form-control",
                  staticStyle: { "text-transform": "uppercase" },
                  attrs: { type: "text", required: "required", maxlength: "6" },
                  domProps: { value: _vm.modalItem.landmark },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.currentItemOrigin === 1
            ? _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "form-group col-md-4" }, [
                  _c("label", [_vm._v(_vm._s(_vm.landmarkPlaceholder) + " :")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      class: { "text-danger": _vm.isErrorSyntaxeItem },
                      staticStyle: { "font-size": "12px" },
                    },
                    [
                      _vm._v(
                        "6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace."
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    ref: "modalInputLandmark",
                    staticClass: "form-control",
                    staticStyle: { "text-transform": "uppercase" },
                    attrs: { type: "text", required: "required" },
                    domProps: { value: _vm.modalItem.landmark },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-md-4" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.quoteNumberPlaceholder) + " :"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "modalInputImportedQuote",
                    staticClass: "form-control",
                    attrs: { type: "text", required: "required" },
                    domProps: { value: _vm.modalItem.importedQuote },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-md-8" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.carpentryPlaceholder) + " :"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "modalInputCarpentry",
                    staticClass: "form-control",
                    attrs: { type: "text", required: "required" },
                    domProps: { value: _vm.modalItem.carpentry },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w-100" }),
                _vm._v(" "),
                _c("div", { staticClass: "form-group col-md-4" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.purchasePriceExTaxPlaceholder) + " :"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "modalInputPurchasingExTax",
                    staticClass: "form-control",
                    attrs: {
                      type: "number",
                      lang: "en-150",
                      required: "required",
                    },
                    domProps: { value: _vm.modalItem.purchasingPriceExTax },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentItemOrigin === 2
            ? _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "form-group col-md-8" }, [
                  _c("label", [_vm._v(_vm._s(_vm.landmarkPlaceholder) + " :")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      class: { "text-danger": _vm.isErrorSyntaxeItem },
                      staticStyle: { "font-size": "12px" },
                    },
                    [
                      _vm._v(
                        "6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace."
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    ref: "modalInputLandmark",
                    staticClass: "form-control",
                    staticStyle: { "text-transform": "uppercase" },
                    attrs: { type: "text", required: "required" },
                    domProps: { value: _vm.modalItem.landmark },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isDuplicateLandmarkUpdateLandMark
            ? _c(
                "span",
                {
                  staticClass: "text-danger row justify-content-center",
                  staticStyle: { "font-size": "14px" },
                },
                [_vm._v("Le nom du repère doit être unique")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-rounded btn-rounded--white border-black px-3 mr-2",
                on: {
                  click: function ($event) {
                    return _vm.updateItem(_vm.modalItem)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.submitLabel))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-rounded btn-rounded--black px-3",
                on: {
                  click: function ($event) {
                    _vm.openUpdateModal = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.backLabel))]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "lg" },
          on: { hidden: _vm.onHidden },
          model: {
            value: _vm.openDevisIncompletModal,
            callback: function ($$v) {
              _vm.openDevisIncompletModal = $$v
            },
            expression: "openDevisIncompletModal",
          },
        },
        [
          _c("div", { staticClass: "row justify-content-center" }, [
            _vm.error === -1
              ? _c("div", { staticClass: "col-8" }, [
                  _c("h4", [_vm._v("Devis incomplet, transférez-le à Mincco")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        Vous ne pouvez pas chiffrer une menuiserie en raison de sa complexité technique.\n                        Décrivez votre menuiserie ici et joignez éventuelement un document (PDF ou Word)\n                        Votre commercial vous adressera son chiffrage que vous pourrez intégrer à votre devis.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "form-group col-md-12" }, [
                      _c("label", [_vm._v("Précisez votre demande* :")]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.demande,
                            expression: "demande",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "5", required: "required" },
                        domProps: { value: _vm.demande },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.demande = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "form-group col-md-12" }, [
                      _vm._v(
                        "\n                            Joignez un document pdf ou word (poids maximum 2 Mo)"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group mb-2" }, [
                        _c("input", {
                          ref: "docFile",
                          staticClass: "form-control mr-4",
                          attrs: { type: "text", readonly: "" },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "input-group-btn" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                            },
                            [
                              _vm._v(
                                "\n                                       Parcourir "
                              ),
                              _c("input", {
                                ref: "docInput",
                                staticStyle: { display: "none" },
                                attrs: { type: "file", id: "docFileName" },
                                on: { change: _vm.onFileInput },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error === 0
              ? _c("div", { staticClass: "col-8" }, [
                  _c("h4", [_vm._v("Demande de devis incomplet enregistrée")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        Votre demande a bien été adressée. Notre équipe met tout en oeuvre pour vous répondre dans les meilleurs délais."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n                        L’équipe Minco\n                    "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error === 1
              ? _c("div", { staticClass: "col-8" }, [
                  _c("h4", [_vm._v("Demande de devis incomplet")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        Le fichier fourni n'est pas au bon format, .pdf ou .docx attendu.\n                    "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error === 2
              ? _c("div", { staticClass: "col-8" }, [
                  _c("h4", [_vm._v("Demande de devis incomplet")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        Le fichier fourni est trop volumineux, il ne doit pas dépasser 2 Mo.\n                    "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.error === -1
            ? _c(
                "div",
                {
                  staticClass: "row justify-content-between w-100",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c("span", { staticClass: "col-4 pl-5" }, [
                    _vm._v("* Champs obligatoires"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-rounded btn-rounded--black px-3 mr-2",
                        on: {
                          click: function ($event) {
                            return _vm.sendDevisIncomplet()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.submitLabel))]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.error > -1
            ? _c("div", {
                staticClass: "row justify-content-between w-100",
                attrs: { slot: "modal-footer" },
                slot: "modal-footer",
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "lg" },
          model: {
            value: _vm.openAlertModal,
            callback: function ($$v) {
              _vm.openAlertModal = $$v
            },
            expression: "openAlertModal",
          },
        },
        [
          _vm.errorAlert === -1
            ? _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("h4", [_vm._v("Augmentez votre taux de transformation")]),
                  _vm._v(" "),
                  _c("h5", [_vm._v("Optez pour la relance en ligne")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        Minco va encore plus loin et vous aide à augmenter votre taux de transformation\n                        devis. En optant pour la relance en ligne, Minco vous adresse un mail à la date et\n                        l’heure de votre choix pour vous rappeler de relancer vos clients."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n                        Un vrai service en plus !\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-8" },
                  [
                    _c("label", { attrs: { for: "deliverydate" } }, [
                      _vm._v("Sélectionnez une date "),
                    ]),
                    _vm._v(" "),
                    _c("datepicker", {
                      ref: "dateAlert",
                      staticStyle: { width: "32%", display: "inline-block" },
                      attrs: {
                        "input-class": "form-control",
                        language: _vm.fr,
                      },
                      model: {
                        value: _vm.dateAlert,
                        callback: function ($$v) {
                          _vm.dateAlert = $$v
                        },
                        expression: "dateAlert",
                      },
                    }),
                    _vm._v(" "),
                    _c("vue-timepicker", {
                      ref: "heureAlert",
                      staticStyle: { width: "32%", display: "inline-block" },
                      attrs: { format: _vm.yourFormat, "minute-interval": 15 },
                      model: {
                        value: _vm.heureAlert,
                        callback: function ($$v) {
                          _vm.heureAlert = $$v
                        },
                        expression: "heureAlert",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorAlert === 0
            ? _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("h4", [_vm._v("Augmentez votre taux de transformation")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                        Votre demande de relance en ligne a bien été enregistrée. Vous recevrez un e-mail le " +
                        _vm._s(_vm.chosenDate) +
                        ".\n                    "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorAlert === -1
            ? _c(
                "div",
                { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                [
                  _c("span", [_vm._v("* Champs obligatoires")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-rounded btn-rounded--white border-black px-3 mr-2",
                      on: {
                        click: function ($event) {
                          return _vm.sendAlert()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.submitLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-rounded btn-rounded--black px-3",
                      on: {
                        click: function ($event) {
                          _vm.openAlertModal = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.backLabel))]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorAlert > -1
            ? _c("div", {
                attrs: { slot: "modal-footer" },
                slot: "modal-footer",
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "lg" },
          model: {
            value: _vm.openDataSheetModal,
            callback: function ($$v) {
              _vm.openDataSheetModal = $$v
            },
            expression: "openDataSheetModal",
          },
        },
        [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-8" }, [
              _c("h4", [_vm._v("Fiche métré")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                        Vous avez terminé votre devis en fourniture seule ou fourni/posé, mais vous devez vérifier les cotes avant de commander.\n                        Votre chantier a bien été enregistré dans « mes chantiers en cours ».\n                        "
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  "\n                        Téléchargez la fiche métré ci-dessous qui vous guidera dans votre prise de cotes.\n                        Vous pourrez saisir les cotes définitives de votre chantier avant de passer votre commande.\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c(
              "a",
              {
                staticClass:
                  "btn btn-rounded btn-rounded--dark-blue border-black px-3 mr-2",
                attrs: { href: _vm.downloadSheetUrl },
              },
              [_vm._v("Télécharger la fiche métré (Excel)")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "btn btn-rounded btn-rounded--dark-blue border-black px-3 mr-2",
                attrs: { href: _vm.downloadSheetUrlPdf, target: "_blank" },
              },
              [_vm._v("Télécharger la fiche métré (PDF)")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "lg" },
          on: { hidden: _vm.onHiddenSave },
          model: {
            value: _vm.openSaveModal,
            callback: function ($$v) {
              _vm.openSaveModal = $$v
            },
            expression: "openSaveModal",
          },
        },
        [
          _vm.errorSave === 0
            ? _c(
                "p",
                { staticClass: "text-center" },
                [
                  _vm.typeItem === 0
                    ? _vm._t("saveCarpentryMessage")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.typeItem === 1
                    ? _vm._t("saveCarpentryMessage")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.typeItem === 2
                    ? _vm._t("saveCustomCarpentryMessage")
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }