var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "loader",
      class: { "loader--active": _vm.displayLoader },
      attrs: { id: "main-loader" },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cssload-loader" }, [
      _c("div", { staticClass: "lds-ring" }, [
        _c("div"),
        _c("div"),
        _c("div"),
        _c("div"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loader__label" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }