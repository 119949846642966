var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-pagination", {
    staticClass: "col-auto mt-4",
    attrs: {
      size: "md",
      "total-rows": _vm.totalDataCount(),
      "per-page": _vm.dataPerPage,
    },
    on: {
      input: function ($event) {
        return _vm.loadPageData()
      },
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v
      },
      expression: "currentPage",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }