<script>
    import AppPagination from '../../common/AppPagination';
    import {EventBus} from '../../../event';

    export default {
        name: 'AppConfigurationList',
        props: {
            logs: {
                type: Array
            },
            headers: {
                type: Object
            },
        },
        components: { AppPagination },
        data: function () {
            return {
                displayCount: 50,
                fields: [],
                items: [],
                postPaginationItems: [],
                searchTerm: ''
            }
        },
        watch: {
            searchTerm: function (val) {
                let tempArray = this.logs.filter((log) => {

                    return (
                        (log.customerCode.toLowerCase().includes(val.toLowerCase())) );
                });
                this.items = tempArray.map(log => ({
                            id : log.id,
                            status: log.status,
                            error: log.error,
                            data: log.data,
                            code_client: log.customerCode,
                            created: log.created,
                            })
                )
                this.postPaginationItems = tempArray;
            },
        },
        created: function () {
            this.postPaginationItems = this.logs;
        },
        mounted: function () {
            console.log(this.logs);
            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push({
                            key: key,
                            label: this.headers[key],
                            sortable: (key !=='action') ? true : false
                        }
                    )
                }
            },
            populateTableItems: function (e) {
                this.items = e.map(log => ({
                        id : log.id,
                        status: log.status,
                        error: log.error,
                        data: log.data,
                        code_client: log.customerCode,
                        created: log.created,
                    })
                )
            },
            onFilter: function (e) {
                let filters = {};

                filters.name = e.target.name;
                filters.value = e.target.value;

                EventBus.$emit('activated-filters', filters);
            },
            formatSaisieCommande: function(profile) {
                switch(profile) {
                    case 0:
                        return "Sans accès";
                        break;
                    case 2:
                        return "Accès commande en ligne";
                        break;
                    case 1:
                        return "Accès commande en ligne + Winpro";
                        break;
                    default:
                        return"";
                        break;
                }
            },
            formatStatus:function(status) {
                switch(status) {
                    case 0:
                        return "Inactif";
                        break;
                    case 1:
                        return "Actif";
                        break;
                    default:
                        return"";
                        break;
                }
            }
        }
    }
</script>