<script>
    import axios from 'axios';

    export default {
        name: 'AppRegistrationShow',
        props: {
            registration: {
                type: Object
            },
            urlCss: {
                type: String
            }
        },
        data: function () {
            return {
                currentStatus: +this.registration.status
            }
        },
        watch: {
            currentStatus: function (val) {
                if(val !== this.registration.status) {
                    this.sendNewStatus();
                }
            }
        },
        mounted: function () {
        },
        beforeDestroy: function () {
            this.currentStatus.removeEventListener('click', this.sendNewStatus);
        },
        methods: {
            sendNewStatus: function() {
                let form = document.getElementsByName('registration-status-form')[0];
                let data = {};
                for (let key in this.registration) {
                    if (this.registration.hasOwnProperty(key)) {
                        if(key !== 'status') {
                            data[key] = this.registration[key];
                        }
                    }
                }
                data.status = +this.currentStatus;
                const config = {
                    headers: { 'content-type': 'application/json' }
                };
                axios.put(form.action, data, config)
                    .catch((error) => {
                        console.warn(error);
                    })
            },
            printTable: function()
            {
                console.log(this.urlCss);
                var zone = document.getElementById("detailsDemande").innerHTML;

                // Ouverture du popup
                var fen = window.open("", "", "height=768, width=1024,toolbar=0, menubar=0, scrollbars=1, resizable=1,status=0, location=0, left=10, top=10");

                // style du popup
//                fen.document.body.style.color = '#1527cb';
//                fen.document.body.style.backgroundColor = '#FFFFFF';
//                fen.document.body.style.padding = "20px";
//
//
//                // Ajout des données a imprimer
//                fen.document.title = "Imprimer le récapitulatif de la demande";
//                fen.document.head.innerHTML+="<meta charset=\"UTF-8\" />\n" +
//                    "    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0, shrink-to-fit=no\">";
//                fen.document.head.innerHTML+=" <link rel='stylesheet' type='text/css' href='"+this.urlCss+"' media='print' />";
//                fen.document.head.innerHTML+=" <link rel='stylesheet' type='text/css' href='"+this.urlCss+"'  />";
//                fen.document.body.innerHTML += "<h2>Récapitulatif pour la demande d'ouverture de compte pro "+ this.registration.id+"</h2> " + zone + " ";
//
//                // Impression du popup
//                fen.window.print();
//
//                //Fermeture du popup
//                fen.window.close();

                fen.document.writeln('<!DOCTYPE html>');
                fen.document.writeln('<html><head><title></title>');
                fen.document.writeln('<link rel="stylesheet" type="text/css" href="'+this.urlCss+'" media="print">');
                fen.document.writeln('</head><body>');
                fen.document.writeln(zone);
                fen.document.writeln('</body></html>');

                fen.document.close();
                fen.focus();
                fen.print();

                return true;
            }
        }
    }
</script>