<script>
    import { swiper, swiperSlide } from 'vue-awesome-swiper'

    export default {
        name: 'AppHomepageSwiper',
        props: {

        },
        data:function () {
            return {
                swiperOptions: {
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    disabledClass: '.white-chevron-disabled',
                    direction: 'horizontal',
                    noSwiping: false
                },
                window: {
                    width: 0,
                    height: 0
                }
            }

        },
        computed: {
            swiper() {
                return this.$refs.homeSwiper.swiper
            }
        },
        created:function () {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        beforeDestroy:function () {

        },
        destroy:function () {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            }
        }
    }
</script>
