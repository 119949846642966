<script>
    import Datepicker from 'vuejs-datepicker';
    import { en, fr } from 'vuejs-datepicker/dist/locale';
    import VueTimepicker from 'vue2-timepicker';
    import axios from 'axios';

    import 'ckeditor';

    export default {
        name: 'AppAddArticle',
        props: {
            categories: {
                type: Array
            },
            article: { },
            token: {
                type: String
            }
        },
        components: {
            Datepicker: Datepicker,
            VueTimepicker: VueTimepicker
        },
        data: function () {
            return {
                fr: fr,
                en: en,
                selectedNewsCategory: 0,
                articleTitle: "",
                articleStandfirst: "",
                articleStatus: 0,
                publicationDate: null,
                expiryDate: null,
                publicationTime: {
                    HH: "12",
                    mm: "00"
                },
                expiryTime: {
                    HH: "12",
                    mm: "00"
                },
                articleImageFileName: "",
                articleBannerLegend: "",
                id: 0,
                articleDocuments: [],
                articleDocumentTitle: "",
                articleDocumentDescription: "",
                newConfirmationModal: false,
                responseState: 0,
                modalState: 0,
                addDocument: false
            }
        },
        watch: {
            articleStatus: function () {
              if (this.articleStatus != 2) {
                  this.publicationDate = null;
                  this.expiryDate = null;
                  this.publicationTime = {
                      HH: "12",
                      mm: "00"
                  };
                  this.expiryTime = {
                      HH: "12",
                      mm: "00"
                  };
                }
            }
        },
        mounted: function () {
            if(this.article.id) {
                this.id = this.article.id;
                window.addEventListener('load', this.populateForm);
            }
            this.toggleCKEditor();
        },
        beforeDestroy: function () {
            window.removeEventListener('load', this.populateForm);
        },
        methods: {
            toggleCKEditor: function () {
                CKEDITOR.plugins.addExternal( 'justify', 'plugins/justify/', 'plugin.js' );
                CKEDITOR.plugins.addExternal( 'html5video', 'plugins/html5video/', 'plugin.js' );
                CKEDITOR.replace( 'editor', {
                    allowedContent: true,
                    defaultLanguage: 'fr',
                    required: true,
                    extraPlugins: ['justify', 'html5video', 'widget', 'videodetector'],
                    toolbar: [
                        { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Scayt' ] },
                        { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
                        { name: 'insert', items: [ 'Image', 'html5video', 'VideoDetector', 'Table', 'HorizontalRule', 'SpecialChar' ] },
                        { name: 'tools', items: [ 'Maximize' ] },
                        { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source' ] },
                        { name: 'others', items: [ '-' ] },
                        '/',
                        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', '-', 'Subscript', 'Superscript' ] },
                        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                        { name: 'styles', items: [ 'Styles', 'Format' ] },
                        { name: 'about', items: [ 'About' ] }
                    ],
                    filebrowserBrowseUrl: '/admin/kcfinder/browse.php?opener=ckeditor&type=files',
                    filebrowserImageBrowseUrl: '/admin/kcfinder/browse.php?opener=ckeditor&type=images',
                    filebrowserUploadUrl: '/admin/kcfinder/upload.php?opener=ckeditor&type=files',
                    filebrowserImageUploadUrl: '/admin/kcfinder/upload.php?opener=ckeditor&type=images',
                    filebrowserUploadMethod: 'form'
                });
            },
            postData: function (event) {
                let data = new FormData();
                data.append('category', this.selectedNewsCategory);
                data.append('title', this.articleTitle);
                data.append('standfirst', this.articleStandfirst);
                data.append('content', CKEDITOR.instances.editor.getData());
                data.append('status', this.articleStatus);
                if (this.articleImageFile) {
                    data.append('banner', this.articleImageFileName);
                    data.set('bannerFile', this.articleImageFile, this.articleImageFile.name);
                }
                if (this.articleImageFile !== "") {
                    data.append('bannerLegend', this.articleBannerLegend);
                }
                for(let i = 0 ; i < this.articleDocuments.length ; i++) {
                    if(this.articleDocuments[i] != null) {
                        data.set("document" + i, this.articleDocuments[i].file);
                        data.set("document" + i + "Title", this.articleDocuments[i].title);
                        data.set("document" + i + "Description", this.articleDocuments[i].description);
                    }
                }

                if(this.publicationDate != null && this.articleStatus == 2) {
                    console.log(this.publicationDate,this.publicationTime);
                    //console.log(this.formatTime(this.publicationDate, this.publicationTime));
                    data.append('publishedAt', this.formatTime(this.publicationDate, this.publicationTime));
                }
                if(this.expiryDate !== null && this.articleStatus === 2) {
                    data.append('expiredAt', this.formatTime(this.expiryDate, this.expiryTime));
                }
                data.append('article_new', '');
                data.append('_token', this.token);
                let url = event.target.action + (this.id === 0 ? "" : "/" + this.id);
                data.append('_method', this.id===0 ? 'post' : 'patch');

                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };
                console.log(data);
                if(CKEDITOR.instances.editor.getData() != "" && CKEDITOR.instances.editor.getData()!=null && this.articleImageFileName!="" && this.articleImageFileName !=null ) {
                    if (this.id === 0) {
                        this.modalState = 0;
                        axios.post(url, data, config)
                            .then((response) => {
                                console.log(response.status);
                                if (response.status === 500)
                                    this.responseState = 0;
                                else
                                    this.responseState = 1;
                            })
                            .catch((error) => {
                                console.error(error);
                                this.responseState = 0;
                            })
                            .finally(() => {
                                this.toggleModal();
                            })
                    } else {
                        this.modalState = 1;
                        axios.post(url, data, config)
                            .then((response) => {
                                console.log(response);
                                this.id = response.data.id;
                                this.responseState = 1;
                            })
                            .catch((error) => {
                                console.error(error);
                                this.responseState = 0;
                            })
                            .finally(() => {
                                this.toggleModal();
                            })
                    }
                }
                else
                {
                    if( CKEDITOR.instances.editor.getData() === "" || CKEDITOR.instances.editor.getData() ===null)
                        alert("Le contenu de l'article ne peut être vide.");
                    else if(this.articleImageFileName ==="" || this.articleImageFileName ===null)
                        alert("Le visuel est obligatoire.");
                }
            },
            onBannerInput: function () {
                if (this.$refs.articleImageInput.files[0]) {
                    this.$refs.articleImageFile.value = this.$refs.articleImageInput.files[0].name;
                    this.articleImageFile = this.$refs.articleImageInput.files[0];
                    this.articleImageFileName = this.$refs.articleImageInput.files[0].name;
                }
            },
            onDocumentInput: function () {
                if (this.$refs.articleDocumentInput.files[0]) {
                    this.$refs.articleDocumentFile.value = this.$refs.articleDocumentInput.files[0].name;
                    this.articleDocumentFile = this.$refs.articleDocumentInput.files[0];
                    this.articleDocumentFileName = this.$refs.articleDocumentInput.files[0].name;
                }
            },
            addDocumentToArticle: function () {
                if(this.$refs.articleDocumentInput.files[0]) {
                    this.articleDocuments.push({
                        id: 0,
                        file: this.$refs.articleDocumentInput.files[0],
                        filename: this.$refs.articleDocumentInput.files[0].name,
                        title: this.articleDocumentTitle,
                        description: this.articleDocumentDescription
                    });
                    this.addDocument = false;
                }
                this.$refs.articleDocumentFile.value = "";
                this.articleDocumentTitle = "";
                this.articleDocumentDescription = "";
            },
            resetForm: function () {
                this.respondState = 1;
                this.selectedNewsCategory = 0;
                this.articleTitle = "";
                this.articleStandfirst = "";
                this.articleStatus = 0;
                this.articleDocuments = [];
                CKEDITOR.instances.editor.setData("");
                this.publicationDate = null;
                this.publicationTime = {
                    HH: "12",
                    mm: "00"
                };
                this.expiryDate = null;
                this.expiryTime = {
                    HH: "12",
                    mm: "00"
                };
                this.articleBannerLegend = "";
                this.$refs.articleImageFile.value = "";
                this.$refs.articleImageInput.value = "";
            },
            populateForm: function () {
                //console.log(this.article);
                if(this.id > 0) {
                    this.categories.find((category) => {
                        if(category.id === this.article.category.id) {
                            this.selectedNewsCategory = category.id;
                        }
                    });
                    this.articleImageFileName = this.article.banner;
                    this.articleBannerLegend = this.article.banner_legend;
                    this.articleTitle = this.article.title;
                    this.articleStandfirst = this.article.standfirst;
                    CKEDITOR.instances.editor.setData(this.article.content);
                    this.articleStatus = this.article.status;

                    if(this.article.status === 2) {
                        this.publicationDate = new Date(this.article.published_at);
                        console.log(this.publicationDate.getTimezoneOffset());
                        //let pCurrentHours = this.publicationDate.getUTCHours().toLocaleString("fr-FR", {timeZone: "Europe/Paris"});
                        let pCurrentHours = this.publicationDate.getHours();//+(this.publicationDate.getTimezoneOffset()/60)
                        console.log(pCurrentHours);
                        pCurrentHours = ("0" + pCurrentHours).slice(-2);
                        console.log(pCurrentHours);
                        this.publicationTime.HH = pCurrentHours;
                        let pCurrentMinutes = this.publicationDate.getUTCMinutes();
                        pCurrentMinutes = ("0" + pCurrentMinutes).slice(-2);
                        this.publicationTime.mm = pCurrentMinutes;
                        if(this.article.expired_at) {
                            this.expiryDate = new Date(this.article.expired_at);
                            let eCurrentHours = this.expiryDate.getHours();
                            eCurrentHours = ("0" + eCurrentHours).slice(-2);
                            console.log(eCurrentHours);
                            this.expiryTime.HH = eCurrentHours;
                            let eCurrentMinutes = this.expiryDate.getUTCMinutes();
                            eCurrentMinutes = ("0" + eCurrentMinutes).slice(-2);
                            this.expiryTime.mm = eCurrentMinutes;
                        }

                    }

                    for(let document of this.article.documents) {
                        this.articleDocuments.push({
                            id: document.id,
                            filename: document.filename,
                            title: document.title,
                            description: document.description
                        });
                    }
                }
            },
            deleteSelectedDocument: function(event, filename, id) {
                let index = this.articleDocuments.map((document)=> document.filename).indexOf(filename);
                this.articleDocuments.splice(index, 1);
                if(id > 0) {
                    axios.delete(event.target.action)
                        .catch((error) => {
                            console.error(error);
                        });
                }
            },
            toggleModal: function () {
                this.newConfirmationModal = !this.newConfirmationModal;
                if(this.modalState === 0 && this.responseState === 1) this.resetForm();
            },
            formatTime: function (date, time) {
                console.log(date,time);
                let d = new Date(date).setHours(time.HH, time.mm, 0, 0);
                console.log(d/1000);
                return (d / 1000);
            },
            convertUTCDateToLocalDate: function(date) {
                var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

                var offset = date.getTimezoneOffset() / 60;
                var hours = date.getHours();

                newDate.setHours(hours - offset);

                return newDate;
            }
        }
    }
</script>
