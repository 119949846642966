<script>
    import AppPagination from '../../common/AppPagination';
    import {EventBus} from '../../../event';
    import Datepicker from 'vuejs-datepicker';
    import { en, fr } from 'vuejs-datepicker/dist/locale';
    import VueTimepicker from 'vue2-timepicker';
    import axios from 'axios';
    export default {
        name: 'AppClientList',
        props: {
            clients: {
                type: Array
            },
            headers: {
                type: Object
            },
            urlExport: {
                type: String
            }
        },
        components: { AppPagination,
            Datepicker: Datepicker,
            VueTimepicker: VueTimepicker, },
        data: function () {
            return {
                displayCount: 50,
                fields: [],
                items: [],
                filters: [],
                postPaginationItems: [],
                searchTerm: '',
                dateDebut : null,
                dateFin : null,
                commercial: 0,
                fr: fr,
                en: en,
            }
        },
        watch: {
            searchTerm: function (val) {
                let tempArray = this.clients.filter((client) => {
                    return (
                        (client.code.toLowerCase().includes(val.toLowerCase())) ||
                        (client.name.toLowerCase().includes(val.toLowerCase())) ||
//                        (client.seller_firstname.toLowerCase().includes(val.toLowerCase())) ||
//                        (client.seller_lastname.toLowerCase().includes(val.toLowerCase())) ||
//                        (client.commercial_firstname.toLowerCase().includes(val.toLowerCase())) ||
//                        (client.commercial_lastname.toLowerCase().includes(val.toLowerCase())) ||
                        (client.group_name.toLowerCase().includes(val.toLowerCase()))
                    );
                });
                this.items = tempArray.map(client => ({
                    id : client.id,
                    code: client.code,
                    company: client.name,
                    seller: ((client.seller_firstname) ? client.seller_firstname : "") + " " + ((client.seller_lastname) ? client.seller_lastname : "")+" (" +client.sellerCode+")",
                    commercial: ((client.commercial_firstname) ? client.commercial_firstname : "") + " " + ((client.commercial_lastname) ? client.commercial_lastname : "")+" (" + client.commercialCode+")",
                    portal_ref: client.group_name+ ' - ' + this.formatProfile(client.portalRef)+' ('+client.category_reference+')',
                    commande_profil: this.formatSaisieCommande(client.commandProfile),
                    status: this.formatStatus(client.status)
                    })
                )
                this.postPaginationItems = tempArray;
            },
        },
        created: function () {
            this.postPaginationItems = this.clients;
        },
        mounted: function () {
            console.log(this.clients);
            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push({
                            key: key,
                            label: this.headers[key],
                            sortable: (key !=='action') ? true : false
                        }
                    )
                }
            },
            populateTableItems: function (e) {
                this.items = e.map(client => ({
                    id : client.id,
                    code: client.code,
                    company: client.name,
                    seller: ((client.seller_firstname) ? client.seller_firstname : "") + " " + ((client.seller_lastname) ? client.seller_lastname : "")+" (" +client.sellerCode+")",
                    commercial: ((client.commercial_firstname) ? client.commercial_firstname : "") + " " + ((client.commercial_lastname) ? client.commercial_lastname : "")+" (" + client.commercialCode+")",
                    portal_ref: client.group_name+ ' - ' + this.formatProfile(client.portalRef)+' ('+client.category_reference+')',
                    commande_profil: this.formatSaisieCommande(client.commandProfile),
                    status: this.formatStatus(client.status)
                    })
                )
            },
            onFilter: function (e) {
                let filters = {};

                filters.name = e.target.name;
                filters.value = e.target.value;

                EventBus.$emit('activated-filters', filters);
            },
            formatProfile: function(profile) {
                switch(profile) {
                    case 0:
                        return "Non web";
                        break;
                    case 1:
                        return "Suspendu";
                        break;
                    case 2:
                        return "Standard";
                        break;
                    case 3:
                        return "Premium";
                        break;
                    case 4:
                        return "Interne";
                        break;
                    default:
                        return"";
                        break;
                }
            },
            formatSaisieCommande: function(profile) {
                switch(profile) {
                    case 0:
                        return "Sans accès";
                        break;
                    case 2:
                        return "Accès commande en ligne";
                        break;
                    case 1:
                        return "Accès commande en ligne + Winpro";
                        break;
                    default:
                        return"";
                        break;
                }
            },
            formatStatus:function(status) {
                switch(status) {
                    case 0:
                        return "Inactif";
                        break;
                    case 1:
                        return "Actif";
                        break;
                    default:
                        return"";
                        break;
                }
            },
            filterByDate : function()
            {

                let data;
                this.refFilter ="";
                this.numFilter="";

                let dateDebut = (this.dateDebut) ? this.dateDebut.setHours(0,0,0,0) : 0;
                let dateFin = (this.dateFin) ? this.dateFin.setHours(0,0,0,0) : new Date().getTime();
                let commercial =null;

                axios.post(this.urlExport, {
                    dateDebut: this.dateDebut,
                    dateFin: this.dateFin,
                    commercial: this.commercial
                }).then(response => {
                    console.log(response);
                    const anchor = document.createElement('a');
                    anchor.href = window.URL.createObjectURL(new Blob([response.data],  {type:"text/csv"} ));
                    anchor.download = "export_project.csv";
                    anchor.click()
                }).catch(error => {
                    console.warn(error);
                });
            },
        }
    }
</script>