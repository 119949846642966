<script>
    import axios from 'axios';
    export default {
        name: 'AppAccountData',
        components: {

        },
        props:{
            apiUpdateUrl :
            {
                type: String
            },
            apiUpdateNewsletterUrl :
            {
                type: String
            },
            userReceiptOrder:
            {
                type: String
            },
            userReceiptNotification:
            {
                type: String
            },
            newsUser :
            {
                type: String
            }
        },
        data: function () {
            return {
                contactId : 0,
                receiptOrder: 0,
                receiptNotification: 0,
                openUpdateModal:false,
                errorSave: -1,
                newsletter : 0,
            }
        },
        mounted: function () {
            if(this.userReceiptOrder =="1")
                this.receiptOrder = 1;
            if(this.userReceiptNotification == "1")
                this.receiptNotification = 1;

            this.newsletter = this.newsUser!="0";
        },
        computed: {

        },
        methods : {
            updateNewsletter: function()
            {
                let newsLetter = this.newsletter;
                let error = -1;
                console.log(this.newsletter);
                axios.post(this.apiUpdateNewsletterUrl, {
                    contact: this.$refs.contactId.value,
                    newsletter: newsLetter
                }).then(response => {
                  this.errorSave = response.data;
                  if(this.errorSave==0)
                  {
                      this.openUpdateModal = true;
                  }
                });
            },
            changeService: function(event)
            {
                this.contactId= this.$refs.contactId.value;
                axios.post(this.apiUpdateUrl, {
                    contact: this.contactId,
                    service: event.target.name,
                    serviceValue : event.target.checked
                }).then(response => {
                    this.errorSave = response.data;
                    if(this.errorSave==1)
                    {
                        this.openUpdateModal = true;
                    }
                });
            },
            onHiddenSave: function()
            {
                this.errorSave=-1;
            },

        }
    }
</script>
