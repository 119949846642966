var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row pt-4 justify-content-center new-project__content__body",
    },
    [
      _c("div", { staticClass: "col-md-12 quote__order" }, [
        _c(
          "div",
          { staticClass: "row quote__order__intro" },
          [_vm._t("orderIntro")],
          2
        ),
        _vm._v(" "),
        _vm.projectStatus == 0 ||
        _vm.projectStatus == 4 ||
        _vm.projectStatus == 6 ||
        _vm.projectStatus == 8 ||
        _vm.projectStatus == 10
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "my-3 col-12" }, [
                _c("div", { staticClass: "form-check" }, [
                  _c("input", {
                    ref: "chkCGV",
                    staticClass: "form-check-input",
                    attrs: { type: "checkbox", id: "cgv" },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      ref: "lblCGV",
                      staticClass: "form-check-label",
                      attrs: { for: "cgv" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: "/conditions-generales-de-vente/",
                          },
                        },
                        [_vm._t("cgv")],
                        2
                      ),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.projectStatus == 0 ||
        _vm.projectStatus == 4 ||
        _vm.projectStatus == 6 ||
        _vm.projectStatus == 8 ||
        _vm.projectStatus == 10
          ? _c("div", { staticClass: "row text-center" }, [
              _c("div", { staticClass: "col mt-3 mt-md-0" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-25 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                    on: { click: _vm.toggleCommande },
                  },
                  [_vm._t("saveNow")],
                  2
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            size: "lg",
            title: "Récapitulatif de votre commande",
          },
          model: {
            value: _vm.openAskOrderModal,
            callback: function ($$v) {
              _vm.openAskOrderModal = $$v
            },
            expression: "openAskOrderModal",
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col", attrs: { id: "tabListItems" } },
                [
                  _c("b-table", {
                    staticClass: "project-table simple-table mb-0",
                    attrs: {
                      stacked: "md",
                      id: "listItems",
                      items: _vm.quoteItems,
                      fields: _vm.fields,
                      "primary-key": "id",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "landmark",
                          fn: function (row) {
                            return row.item.origin !== 2
                              ? [
                                  row.item.origin !== 2
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(row.item.landmark) +
                                            "\n                          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              : undefined
                          },
                        },
                        {
                          key: "carpentry",
                          fn: function (row) {
                            return row.item.origin !== 2
                              ? [
                                  row.item.origin < 1
                                    ? _c(
                                        "div",
                                        { staticClass: "project-table__small" },
                                        [
                                          _vm._l(
                                            row.item.summaryConfiguration,
                                            function (desc) {
                                              return _c("span", [
                                                _c("span", [
                                                  _c("b", [
                                                    _vm._v(_vm._s(desc.label)),
                                                  ]),
                                                  _vm._v(
                                                    " : " + _vm._s(desc.value)
                                                  ),
                                                  _c("br"),
                                                ]),
                                              ])
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          row.item.origin === 1
                                            ? _c(
                                                "span",
                                                { staticClass: "small" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.item.importedQuote
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.item.origin === 2
                                    ? _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _vm._v(
                                            "\n                              " +
                                              _vm._s(row.item.landmark) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.item.origin === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _vm._v(
                                            "\n                              " +
                                              _vm._s(row.item.carpentry)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                              " +
                                              _vm._s(row.item.importedQuote) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : undefined
                          },
                        },
                        {
                          key: "quantity",
                          fn: function (row) {
                            return row.item.origin !== 2
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input-group input-group--small",
                                    },
                                    [
                                      _vm._v(
                                        "\n                              " +
                                          _vm._s(row.item.quantity) +
                                          "\n                          "
                                      ),
                                    ]
                                  ),
                                ]
                              : undefined
                          },
                        },
                        {
                          key: "purchasingPrice",
                          fn: function (row) {
                            return row.item.origin !== 2
                              ? [
                                  row.item.origin < 2
                                    ? _c("div", {}, [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(
                                              _vm._f("formatPrice")(
                                                _vm._f("toFixed")(
                                                  row.item.purchasingPriceExTax
                                                )
                                              )
                                            ) +
                                            "\n                          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center my-4" }, [
              _c("div", [
                _vm._v(
                  "Ma commande est complète et ne nécessite aucune mise au point particulière"
                ),
                _c("br"),
                _vm._v(
                  "(conformité norme PMR, ajout de châssis techniques, d’accessoires, etc…)"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-md-4 col-lg-3" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                    attrs: { type: "button" },
                    on: { click: _vm.printTable },
                  },
                  [_vm._v(" IMPRIMER ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 col-lg-3" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                    attrs: { type: "button" },
                    on: { click: _vm.openConfirmOrder },
                  },
                  [_vm._v(" OUI ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 col-lg-3" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                    attrs: { type: "button" },
                    on: { click: _vm.showOrderComplements },
                  },
                  [_vm._v(" NON ")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "lg" },
          model: {
            value: _vm.openOrderComplementsModal,
            callback: function ($$v) {
              _vm.openOrderComplementsModal = $$v
            },
            expression: "openOrderComplementsModal",
          },
        },
        [
          _c("div", [
            _c("span", [
              _c("strong", [
                _vm._v(
                  "Indiquez les points à compléter ou à vérifier par votre correspondant ADV"
                ),
              ]),
            ]),
            _c("br"),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("label", { attrs: { for: "chkDelaisSpec" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.chkDelaisSpec,
                        expression: "chkDelaisSpec",
                      },
                    ],
                    attrs: { type: "checkbox", id: "chkDelaisSpec" },
                    domProps: {
                      checked: Array.isArray(_vm.chkDelaisSpec)
                        ? _vm._i(_vm.chkDelaisSpec, null) > -1
                        : _vm.chkDelaisSpec,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.chkDelaisSpec,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.chkDelaisSpec = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.chkDelaisSpec = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.chkDelaisSpec = $$c
                          }
                        },
                        function ($event) {
                          return _vm.checkedAddItem($event)
                        },
                      ],
                    },
                  }),
                  _vm._v("   Délai particulier spécifié : "),
                ]),
                _c("br"),
                _c("br"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-5 col-lg-5" }, [
                _c(
                  "span",
                  {
                    class: {
                      "text-danger": _vm.incompleteFormErrors["txtDelaisSpec"],
                      "has-error": _vm.incompleteFormErrors["txtDelaisSpec"],
                    },
                  },
                  [
                    _vm._v(
                      "\n                      Indiquer le délai souhaité :"
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.txtDelaisSpec,
                          expression: "txtDelaisSpec",
                        },
                      ],
                      attrs: { rows: "3", cols: "35" },
                      domProps: { value: _vm.txtDelaisSpec },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.txtDelaisSpec = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("label", { attrs: { for: "chkPoigneePMR" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.chkPoigneePMR,
                        expression: "chkPoigneePMR",
                      },
                    ],
                    attrs: { type: "checkbox", id: "chkPoigneePMR" },
                    domProps: {
                      checked: Array.isArray(_vm.chkPoigneePMR)
                        ? _vm._i(_vm.chkPoigneePMR, null) > -1
                        : _vm.chkPoigneePMR,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.chkPoigneePMR,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.chkPoigneePMR = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.chkPoigneePMR = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.chkPoigneePMR = $$c
                          }
                        },
                        function ($event) {
                          return _vm.checkedAddItem($event)
                        },
                      ],
                    },
                  }),
                  _vm._v(
                    " Vérifier conformité des hauteurs poignées à la norme PMR "
                  ),
                ]),
                _c("br"),
                _c("br"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("label", { attrs: { for: "chkMisePointTechnique" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chkMisePointTechnique,
                      expression: "chkMisePointTechnique",
                    },
                  ],
                  attrs: { type: "checkbox", id: "chkMisePointTechnique" },
                  domProps: {
                    checked: Array.isArray(_vm.chkMisePointTechnique)
                      ? _vm._i(_vm.chkMisePointTechnique, null) > -1
                      : _vm.chkMisePointTechnique,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.chkMisePointTechnique,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.chkMisePointTechnique = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.chkMisePointTechnique = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.chkMisePointTechnique = $$c
                        }
                      },
                      function ($event) {
                        return _vm.checkedAddItem($event)
                      },
                    ],
                  },
                }),
                _vm._v("   Mise au point Technique"),
              ]),
              _c("br"),
              _c("br"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-lg-3" }, [
              _c(
                "span",
                {
                  class: {
                    "text-danger":
                      _vm.incompleteFormErrors["txtMisePointTechnique"],
                    "has-error":
                      _vm.incompleteFormErrors["txtMisePointTechnique"],
                  },
                },
                [
                  _vm._v("\n                    Précisez:"),
                  _c("br"),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.txtMisePointTechnique,
                        expression: "txtMisePointTechnique",
                      },
                    ],
                    attrs: { rows: "3", cols: "35" },
                    domProps: { value: _vm.txtMisePointTechnique },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.txtMisePointTechnique = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("label", { attrs: { for: "chkNonConfigurable" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chkNonConfigurable,
                      expression: "chkNonConfigurable",
                    },
                  ],
                  attrs: { type: "checkbox", id: "chkNonConfigurable" },
                  domProps: {
                    checked: Array.isArray(_vm.chkNonConfigurable)
                      ? _vm._i(_vm.chkNonConfigurable, null) > -1
                      : _vm.chkNonConfigurable,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.chkNonConfigurable,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.chkNonConfigurable = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.chkNonConfigurable = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.chkNonConfigurable = $$c
                        }
                      },
                      function ($event) {
                        return _vm.checkedAddItem($event)
                      },
                    ],
                  },
                }),
                _vm._v("   Repère(s) non configurable(s)"),
              ]),
              _c("br"),
              _c("br"),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(
            _vm.nonConfigurableItems,
            function (nonConfigurableItem, index) {
              return _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4 col-lg-5" }, [
                  _c(
                    "span",
                    {
                      class: {
                        "text-danger":
                          _vm.incompleteFormErrors[
                            "nomNonConfigurable" + index
                          ],
                        "has-error":
                          _vm.incompleteFormErrors[
                            "nomNonConfigurable" + index
                          ],
                      },
                    },
                    [
                      _vm._v("\n                    Nom du repère* :"),
                      _c("br"),
                      _vm._v(" "),
                      _c("input", {
                        ref: "nomNonConfigurable",
                        refInFor: true,
                        attrs: { type: "text" },
                        domProps: { value: nonConfigurableItem.num },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      class: {
                        "text-danger":
                          _vm.incompleteFormErrors[
                            "txtNonConfigurable" + index
                          ],
                        "has-error":
                          _vm.incompleteFormErrors[
                            "txtNonConfigurable" + index
                          ],
                      },
                    },
                    [
                      _vm._v("\n                      Précisez:"),
                      _c("br"),
                      _vm._v(" "),
                      _c("textarea", {
                        ref: "txtNonConfigurable",
                        refInFor: true,
                        attrs: { rows: "3", cols: "35" },
                        domProps: { value: nonConfigurableItem.txt },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4 col-lg-5" }, [
                  _c(
                    "span",
                    {
                      class: {
                        "text-danger":
                          _vm.incompleteFormErrors[
                            "fileNonConfigurable" + index
                          ],
                        "has-error":
                          _vm.incompleteFormErrors[
                            "fileNonConfigurable" + index
                          ],
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Joindre un pdf (5 Mo maxi):"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("label", { staticClass: "input-group-btn" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                          },
                          [
                            _vm._v(
                              "\n                                   Parcourir "
                            ),
                            _c("input", {
                              ref: "fileNonConfigurable",
                              refInFor: true,
                              staticStyle: { display: "none" },
                              attrs: { type: "file", id: "docFileName" },
                              on: { change: _vm.onFileInput },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1 col-lg-1" }, [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteNonConfigurableItem(index)
                        },
                      },
                    },
                    [_vm._v(" X ")]
                  ),
                ]),
              ])
            }
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-lg-4" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                  on: { click: _vm.toogleAddNonConfigurableItem },
                },
                [
                  _vm._v(
                    "\n                        Ajouter un repère\n                    "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("label", { attrs: { for: "chkDevisComplementaire" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chkDevisComplementaire,
                      expression: "chkDevisComplementaire",
                    },
                  ],
                  attrs: { type: "checkbox", id: "chkDevisComplementaire" },
                  domProps: {
                    checked: Array.isArray(_vm.chkDevisComplementaire)
                      ? _vm._i(_vm.chkDevisComplementaire, null) > -1
                      : _vm.chkDevisComplementaire,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.chkDevisComplementaire,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.chkDevisComplementaire = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.chkDevisComplementaire = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.chkDevisComplementaire = $$c
                        }
                      },
                      function ($event) {
                        return _vm.checkedAddItem($event)
                      },
                    ],
                  },
                }),
                _vm._v(
                  "   Repère(s) ayant fait l'objet d'un devis par commercial et non intégré"
                ),
              ]),
              _c("br"),
              _c("br"),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(
            _vm.devisComplementaires,
            function (devisComplementaire, index) {
              return _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-4 col-lg-5" }, [
                  _c(
                    "span",
                    {
                      class: {
                        "text-danger":
                          _vm.incompleteFormErrors[
                            "nomDevisComplementaire" + index
                          ],
                        "has-error":
                          _vm.incompleteFormErrors[
                            "nomDevisComplementaire" + index
                          ],
                      },
                    },
                    [
                      _vm._v("\n                      N° de Devis* : "),
                      _c("br"),
                      _vm._v(" "),
                      _c("input", {
                        ref: "nomDevisComplementaire",
                        refInFor: true,
                        attrs: { type: "text", required: "required" },
                        domProps: { value: devisComplementaire.num },
                      }),
                      _c("br"),
                      _c("br"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      class: {
                        "text-danger":
                          _vm.incompleteFormErrors[
                            "txtDevisComplementaire" + index
                          ],
                        "has-error":
                          _vm.incompleteFormErrors[
                            "txtDevisComplementaire" + index
                          ],
                      },
                    },
                    [
                      _vm._v("\n                      Précisions"),
                      _c("br"),
                      _vm._v(" "),
                      _c("textarea", {
                        ref: "txtDevisComplementaire",
                        refInFor: true,
                        attrs: { rows: "3", cols: "35" },
                        domProps: { value: devisComplementaire.txt },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4 col-lg-5" }, [
                  _c(
                    "span",
                    {
                      class: {
                        "text-danger":
                          _vm.incompleteFormErrors[
                            "fileDevisComplementaire" + index
                          ],
                        "has-error":
                          _vm.incompleteFormErrors[
                            "fileDevisComplementaire" + index
                          ],
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Joindre un pdf (5 Mo maxi):"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("label", { staticClass: "input-group-btn" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                          },
                          [
                            _vm._v(
                              "\n                                       Parcourir "
                            ),
                            _c("input", {
                              ref: "fileDevisComplementaire",
                              refInFor: true,
                              staticStyle: { display: "none" },
                              attrs: {
                                type: "file",
                                id: "docDevisComplementaire",
                              },
                              on: { change: _vm.onFileInput },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1 col-lg-1" }, [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteDevisComplementaireItem(index)
                        },
                      },
                    },
                    [_vm._v(" X ")]
                  ),
                ]),
              ])
            }
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-lg-4" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                  on: { click: _vm.toogleAddDevisComplementaireItem },
                },
                [
                  _vm._v(
                    "\n                        Ajouter un repère\n                    "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("label", { attrs: { for: "chkAccessoire" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chkAccessoire,
                      expression: "chkAccessoire",
                    },
                  ],
                  attrs: { type: "checkbox", id: "chkAccessoire" },
                  domProps: {
                    checked: Array.isArray(_vm.chkAccessoire)
                      ? _vm._i(_vm.chkAccessoire, null) > -1
                      : _vm.chkAccessoire,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.chkAccessoire,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.chkAccessoire = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.chkAccessoire = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.chkAccessoire = $$c
                        }
                      },
                      function ($event) {
                        return _vm.checkedAddItem($event)
                      },
                    ],
                  },
                }),
                _vm._v("   Accessoire complémentaire à ajouter"),
              ]),
              _c("br"),
              _c("br"),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.accessoireComplementaires, function (accessoire, index) {
            return _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 col-lg-3" }, [
                _c(
                  "span",
                  {
                    class: {
                      "text-danger":
                        _vm.incompleteFormErrors[
                          "accessoireComplementaireNom" + index
                        ],
                      "has-error":
                        _vm.incompleteFormErrors[
                          "accessoireComplementaireNom" + index
                        ],
                    },
                  },
                  [
                    _vm._v("\n                        Désignation* :"),
                    _c("br"),
                    _vm._v(" "),
                    _c("input", {
                      ref: "accessoireComplementaireNom",
                      refInFor: true,
                      attrs: { type: "text" },
                      domProps: { value: accessoire.nom },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3 col-lg-3" }, [
                _vm._v("\n                    Longueur (mm)"),
                _c("br"),
                _c("input", {
                  ref: "accessoireComplementaireLongueur",
                  refInFor: true,
                  staticClass: "w-50",
                  attrs: { type: "number" },
                  domProps: { value: accessoire.longueur },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3 col-lg-2" }, [
                _vm._v("\n                    Largeur (mm)"),
                _c("br"),
                _c("input", {
                  ref: "accessoireComplementaireLargeur",
                  refInFor: true,
                  staticClass: "w-50",
                  attrs: { type: "number" },
                  domProps: { value: accessoire.largeur },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3 col-lg-2" }, [
                _vm._v("\n                    Quantité"),
                _c("br"),
                _c("input", {
                  ref: "accessoireComplementaireQuantite",
                  refInFor: true,
                  staticClass: "w-50",
                  attrs: { type: "number" },
                  domProps: { value: accessoire.qte },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-1 col-lg-1" }, [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteAccessoireItem(index)
                      },
                    },
                  },
                  [_vm._v(" X ")]
                ),
              ]),
            ])
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 col-lg-4" }, [
            _c(
              "button",
              {
                staticClass:
                  "w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                on: { click: _vm.toogleAddAccessoireItem },
              },
              [
                _vm._v(
                  "\n                    Ajouter un accessoire\n                "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("label", { attrs: { for: "chkAutre" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chkAutre,
                      expression: "chkAutre",
                    },
                  ],
                  attrs: { type: "checkbox", id: "chkAutre" },
                  domProps: {
                    checked: Array.isArray(_vm.chkAutre)
                      ? _vm._i(_vm.chkAutre, null) > -1
                      : _vm.chkAutre,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.chkAutre,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.chkAutre = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.chkAutre = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.chkAutre = $$c
                        }
                      },
                      function ($event) {
                        return _vm.checkedAddItem($event)
                      },
                    ],
                  },
                }),
                _vm._v("   Autres points techniques à prendre en compte : "),
              ]),
              _c("br"),
              _c("br"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-5 col-lg-5" }, [
              _c(
                "span",
                {
                  class: {
                    "text-danger": _vm.incompleteFormErrors["txtAutre"],
                    "has-error": _vm.incompleteFormErrors["txtAutre"],
                  },
                },
                [
                  _vm._v("\n                    Précisions"),
                  _c("br"),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.txtAutre,
                        expression: "txtAutre",
                      },
                    ],
                    attrs: { rows: "3", cols: "35" },
                    domProps: { value: _vm.txtAutre },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.txtAutre = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 col-lg-5" }, [
              _c(
                "span",
                {
                  class: {
                    "text-danger": _vm.incompleteFormErrors["docAutre"],
                    "has-error": _vm.incompleteFormErrors["docAutre"],
                  },
                },
                [
                  _vm._v("\n                    Joindre un pdf (5 Mo maxi):"),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "input-group-btn" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                      },
                      [
                        _vm._v("\n                       Parcourir "),
                        _c("input", {
                          ref: "docAutre",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", id: "docAutre" },
                          on: { change: _vm.onFileInput },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 col-lg-3" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                    attrs: { type: "button" },
                    on: { click: _vm.validIncompleteOrder },
                  },
                  [_vm._v(" Valider ")]
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "lg" },
          model: {
            value: _vm.openInitModal,
            callback: function ($$v) {
              _vm.openInitModal = $$v
            },
            expression: "openInitModal",
          },
        },
        [
          _vm.errorIniWinPro.length > 0 && _vm.errorIniWinPro != false
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _vm._l(_vm.errorIniWinPro, function (error) {
                    return _c("span", { staticStyle: { display: "inherit" } }, [
                      _vm._v(_vm._s(error)),
                    ])
                  }),
                  _c("br"),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          (_vm.errorIniWinPro.length == 0 || _vm.errorIniWinPro == false) &&
          _vm.errorValidationOrder == -1
            ? _c("div", { staticClass: "text-center" }, [
                _c("span", { staticStyle: { display: "inherit" } }, [
                  _vm._v("Votre commande a bien été enregistrée."),
                  _c("br"),
                  _vm._v(
                    "Vous recevrez un ARC dans les 24h et vous bénéficierez de 48h pour la modifier."
                  ),
                  _c("br"),
                  _vm._v(
                    " Aucun bon pour accord ne vous sera demandé avant lancement fabrication"
                  ),
                ]),
                _c("br"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorValidationOrder > 0
            ? _c("div", { staticClass: "text-center" }, [
                _vm.errorValidationOrder == 1
                  ? _c("span", { staticStyle: { display: "inherit" } }, [
                      _vm._v("Votre commande a bien été enregistrée."),
                      _c("br"),
                      _vm._v(
                        "Vous recevrez un ARC dans les 24h et vous bénéficierez de 48h pour la modifier."
                      ),
                      _c("br"),
                      _vm._v(
                        " Aucun bon pour accord ne vous sera demandé avant lancement fabrication"
                      ),
                    ])
                  : _vm._e(),
                _c("br"),
                _vm._v(" "),
                _vm.errorValidationOrder == 2
                  ? _c("span", { staticStyle: { display: "inherit" } }, [
                      _vm._v(
                        "Une erreur est survenue dans le processus du passage de commande, veuillez réessayer ultérieurement."
                      ),
                      _c("br"),
                      _vm._v(
                        "Si le problème persiste veuillez contacter l'administrateur."
                      ),
                    ])
                  : _vm._e(),
                _c("br"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { centered: "", size: "lg" },
          model: {
            value: _vm.openConfirmCommand,
            callback: function ($$v) {
              _vm.openConfirmCommand = $$v
            },
            expression: "openConfirmCommand",
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 text-center" }, [
                _c("span", { staticStyle: { display: "inherit" } }, [
                  _vm._v(
                    "Vous êtes sur le point de passer votre commande. Etes-vous sûr ?"
                  ),
                ]),
                _c("br"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row text-center" }, [
              _c(
                "div",
                { staticClass: "offset-md-2 offset-lg-3 col-md-4 col-lg-3" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                      attrs: { type: "button" },
                      on: { click: _vm.completeOrder },
                    },
                    [_vm._v(" OUI ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 col-lg-3" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-100 btn btn-rounded btn-rounded--white btn-rounded--shadowed",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.openConfirmCommand = false
                      },
                    },
                  },
                  [_vm._v(" NON ")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }