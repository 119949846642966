<script>
    import AppPagination from '../../common/AppPagination';
    import {EventBus} from '../../../event';
    import axios from 'axios';
    export default {
        name: 'AppRegistrationList',
        props: {
            registrations: {
                type: Array
            },
            headers: {
                type: Object
            },
            sendUrl: {
                type: String
            }

        },
        components: { AppPagination },
        data: function () {
            return {
                displayCount: 50,
                fields: [],
                items: [],
                displayModalTrigger: false,
                emailToSend: "",
                idRegistration : 0,
                errorSend: 0,
                searchTerm: ''
            }
        },
        watch: {
            searchTerm: function (val) {
                let tempArray = this.registrations.filter((registration) => {
                    return (
                        (registration.firstname.toLowerCase().includes(val.toLowerCase())) ||
                        (registration.lastname.toLowerCase().includes(val.toLowerCase())) ||
//                        (client.seller_firstname.toLowerCase().includes(val.toLowerCase())) ||
//                        (client.seller_lastname.toLowerCase().includes(val.toLowerCase())) ||
//                        (client.commercial_firstname.toLowerCase().includes(val.toLowerCase())) ||
//                        (client.commercial_lastname.toLowerCase().includes(val.toLowerCase())) ||
                        (registration.company.toLowerCase().includes(val.toLowerCase()))
                    );
                });
                this.items = tempArray.map(registration => ({
                    id: registration.id,
                    name: registration.firstname + ' ' + registration.lastname,
                    activity: registration.activity,
                    company: registration.company,
                    created: this.formatDate(registration.created_at),
                    status: registration.status
                    })
                )
                this.postPaginationItems = tempArray;
            },
        },
        mounted: function () {
            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push(
                        {
                            key: key,
                            label: this.headers[key],
                            sortable: (key !=='action') ? true : false
                        }
                    )
                }
            },
            populateTableItems: function (e) {
                this.items = e.map(registration =>
                    ({
                        id: registration.id,
                        name: registration.firstname + ' ' + registration.lastname,
                        activity: registration.activity,
                        company: registration.company,
                        created: this.formatDate(registration.created_at),
                        status: registration.status
                    })
                )
            },
            onStatusFilter: function (e) {

                let filters = {};

                filters.name = e.target.name;
                filters.value = e.target.value;

                EventBus.$emit('activated-filters', filters);
            },
            formatDate: function (date) {
                let d = new Date(date);
                let dd = (d.getUTCDate() < 10 ? '0' : '') + d.getUTCDate();
                let MM = ((d.getUTCMonth() + 1) < 10 ? '0' : '') + (d.getUTCMonth() + 1);
                let HH = (d.getUTCHours() < 10 ? '0' : '') + d.getUTCHours();
                let mm = (d.getUTCMinutes() < 10 ? '0' : '') + d.getUTCMinutes();
                return dd + '/' + MM + '/' + d.getFullYear() + ' - ' + HH + ':' + mm;
            },
            sendByMailTrigger: function(idCible)
            {
                this.displayModalTrigger = true;
                this.idRegistration = idCible;
            },
            sendRequest: function(e)
            {
                axios.post(this.sendUrl, {"email" : this.emailToSend,"idRegistration": this.idRegistration})
                    .then((response) => {
                        if(response.status === 200) {
                            this.hasResponse = true;
                            this.responseData = response.data;
                            if(this.responseData===true)
                                this.errorSend = 1;
                            else
                                this.errorSend = 2;
                        } else {
                            this.errors = response.data.errors;
                        }
                    })
                    .catch((error) => {
                        console.warn(error)
                    })
            },
            onHiddenSend: function()
            {
                this.errorSend = 0;
            }
        }
    }
</script>