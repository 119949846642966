<script>
    import axios from 'axios';

    export default {
        name: 'AppLibraryListed',
        props: {
            filters: {
                type: Array
            },
            slug: {
                type: String
            },
            medias: {
                type: Array
            },
            UrlResaArc:
                {
                    type: String
                },
            UrlByName:
                {
                    type: String
                },
        },
        data: function () {
            return {
                hasPlans: false,
                canBeSent: false,
                selected: -1,
                mainFilter: [],
                selectedFiltersArray: [],
                displayedFiltersArray: [],
                currentFilterIds: [],
                filteredMedia: [],
                confirmDeleteModal: false,
                isLast: false,
                deleteApiUrl: '',
                deleteMediaId: 0,
                sendTo: 0,
                emailModal: false,
                checkedMedias: [],
                emailAddress: '',
                mailSuccess: false,
                ref: '',
                name: '',
                dlResaArcModal: false,
                dlResaArcFailure: false,

            }
        },
        watch: {
            emailModal: function (val) {
                if(!val) this.mailSuccess = false;
            }
        },
        created: function() {
            this.populateMainFilter();
        },
        mounted: function() {
            this.setDefaultFilter();

        },
        methods: {
            // ID du filtre sélectionné et niveau du filtre sélectionné
            toggleFilters: function(event, filterLevel) {
                // let isLast = false;
                // console.log("On filter toggle -------------------------------------------------------------------");
                // console.log("Id du filtre sélectionné (this.selected)", event.target.value);
                // console.log("Niveau du filtre sélectionné (filterLevel)", filterLevel);
                let categoryElement = document.querySelector('#select-1');
                if(categoryElement) {
                    this.hasPlans = (categoryElement[categoryElement.selectedIndex].innerHTML === 'Réservations de Maçonneries');
                }
                this.canBeSent = false;
                this.selected = (event.target !== undefined) ? +event.target.value : event;
                let selectedFilter = [];
                // Si le filtre sélectionné fait partie de la première liste de filtres
                let isCategory = (filterLevel === 1);
                // console.log("Le filtre sélectionné fait partie de la première liste de filtres ?", isCategory);
                let idFound = this.selectedFiltersArray.some((item) => item.id === this.selected);
                // console.log("Le tableau des filtres selectionnés contient-il déjà l'id du filtre sélectionné ?", idFound);
                let levelFound = this.selectedFiltersArray.some((item) => item.lvl === filterLevel);
                // console.log("Le tableau des filtres selectionnés contient-il déjà le niveau du filtre sélectionné ?", levelFound);
                // Si le tableau contenant les filtres sélectionnés ne contient pas déjà l'id du filtre sélectionné
                if(!idFound) {
                    this.filters.find((item) => {
                        if (item.id === this.selected) {
                            // Trouver le filtre du même id dans le tableau filters et...
                            selectedFilter = item;
                            // ... Vérifier qu'il s'agit du filtre Notice d'entretien afin d'activer l'envoi par mail. Cela nous permettra d'activer la colonne "Envoyer"
                            this.canBeSent = (item.label === 'Notices d\'entretien');
                        }
                    });
                }
                // console.log('Filtre sélectionné', selectedFilter);
                // console.log('A une référence de plan', this.hasPlans);
                // console.log('Activer la colonne envoi par mail', this.canBeSent);
                // Si un filtre du même niveau n'est pas stocké dans le tableau des filtres sélectionnés...
                if(!levelFound){
                    // ... Le stocker dans le tableau des filtres sélectionnés
                    if(selectedFilter.rgt - selectedFilter.lft > 1) {
                        this.selectedFiltersArray.push({
                            id: selectedFilter.id,
                            lvl: selectedFilter.lvl,
                            isLast: false
                        });
                    } else {
                        this.selectedFiltersArray.push({
                            id: selectedFilter.id,
                            lvl: selectedFilter.lvl,
                            isLast: true
                        });
                    }
                } else {
                    let existingFilterIndex = this.selectedFiltersArray.findIndex(item => item.lvl === filterLevel);
                    // console.log("Un filtre de ce niveau existe déjà dans le tableau à l'emplacement : ", existingFilterIndex);
                    this.selectedFiltersArray[existingFilterIndex].id = this.selected;
                    this.selectedFiltersArray[existingFilterIndex].isLast = (existingFilterIndex.rgt - existingFilterIndex.lft <= 1);
                }
                // Une fois le filtre sélectionné, peupler le tableau de ses filtres enfants
                if (isCategory) {
                    // Si c'est un filtre de premier niveau, reinitialiser le tableau d'enfant et le tableau de filtres...
                    this.selectedFiltersArray = [];
                    this.displayedFiltersArray = [];
                    // Si la sélection est 0, peupler le tableau de filtres de la façon qui suit
                    if(this.selected === 0) {
                        this.selectedFiltersArray.push({
                            id: this.selected,
                            lvl: filterLevel,
                            isLast:false
                        });
                    } else {
                        // Sinon peupler comme suit
                        if(selectedFilter.rgt - selectedFilter.lft > 1) {
                            this.selectedFiltersArray.push({
                                id: selectedFilter.id,
                                lvl: selectedFilter.lvl,
                                isLast: false
                            });
                        } else {
                            this.selectedFiltersArray.push({
                                id: selectedFilter.id,
                                lvl: selectedFilter.lvl,
                                isLast: true
                            });
                        }
                    }
                    // Et remettre la sélection à zéro
                    let selectElement = document.querySelector('#select-' + (filterLevel + 1));
                    if(selectElement) {
                        selectElement.selectedIndex = 0;
                    }
                    this.filterProps(this.filters, this.displayedFiltersArray, filterLevel, selectedFilter);
                } else {
                    // Si c'est un filtre qui n'est pas de premier niveau, trouver dans la liste des filtres sélectionnés l'index où il est stoclé...
                    let selectedFilterIndex = this.selectedFiltersArray.findIndex(item => item.lvl === filterLevel + 1);
                    // console.log("Le filtre est stocké dans le tableau des filtres à l'index :", selectedFilterIndex);
                    // console.log("Before splice Display Array", JSON.parse(JSON.stringify(this.displayedFiltersArray)));
                    // console.log("Before splice Select Array", JSON.parse(JSON.stringify(this.selectedFiltersArray)));
                    if(selectedFilterIndex > -1) {
                        let tempArray = this.selectedFiltersArray;
                        // console.log(this.selectedFiltersArray[selectedFilterIndex - 1]);
                        this.selectedFiltersArray[selectedFilterIndex - 1].isLast = (selectedFilter.rgt - selectedFilter.lft <= 1);
                        this.selectedFiltersArray = tempArray.slice(0, selectedFilterIndex);
                        // console.log("After splice Display Array", JSON.parse(JSON.stringify(this.displayedFiltersArray)));
                        // console.log("After splice Select Array", JSON.parse(JSON.stringify(this.selectedFiltersArray)));
                        // Repeupler l'enfant
                    }
                    // Et si le filtre est listé dans le tableau de filtres
                    let displayFilterLvlIndex = this.displayedFiltersArray.findIndex(item => item.lvl === filterLevel + 1);
                    if(displayFilterLvlIndex > -1) {
                        // Si oui, supprimer les petits enfants...
                        this.displayedFiltersArray.splice(displayFilterLvlIndex);
                        // Et remettre la sélection à zéro
                        let selectElement = document.querySelector('#select-' + (filterLevel + 1));
                        if(selectElement) {
                            selectElement.selectedIndex = 0;
                        }
                    } else {
                        let currentSelectedFilterIndex = this.selectedFiltersArray.findIndex(item => item.lvl === filterLevel);
                        console.log(this.selectedFiltersArray,currentSelectedFilterIndex);
                        if(this.selectedFiltersArray[currentSelectedFilterIndex])
                          this.selectedFiltersArray[currentSelectedFilterIndex].isLast = (selectedFilter.rgt - selectedFilter.lft <= 1);
                    }
                    if(this.selected === 0) {
                        let currentSelectedFilterIndex = this.selectedFiltersArray.findIndex(item => item.lvl === filterLevel);
                        this.selectedFiltersArray.splice(currentSelectedFilterIndex );
                    }
                    this.filterProps(this.filters, this.displayedFiltersArray, filterLevel, selectedFilter);
                }
                // console.log("Tableau des filtres enfants", this.displayedFiltersArray);
                // console.log("Tableau des filtres sélectionnés", this.selectedFiltersArray);
                this.filterMedia(this.selected, isCategory);
            },
            filterProps: function(array, newArray, filterLevel, selectedFilter) {
                array.filter((item) => {
                    if((item.lvl === filterLevel + 1) && (item.lft > selectedFilter.lft) && (item.rgt <= selectedFilter.rgt)) {
                        newArray.push({
                            id: item.id,
                            label: item.label,
                            lvl: item.lvl
                        });
                    }
                });

                array.sort((a,b) => (a.label > b.label) ? 1 : -1); 
            },
            filterMedia: function(filterId, isCategory) {
                console.log(this.selectedFiltersArray);
                let currentFilter = [];
                if (filterId === 0 && !isCategory) {
                    currentFilter = this.filters.find((filter) => {
                        return filter.id === this.selectedFiltersArray[this.selectedFiltersArray.length - 1].id;
                    });
                } else {
                    currentFilter = this.filters.find((filter) => {
                        return filter.id === filterId;
                    });
                }
//                console.log(currentFilter);
                if (currentFilter && currentFilter.rgt - currentFilter.lft <= 1) {
                  this.currentFilterIds = [currentFilter.id];
                } else {
                  this.currentFilterIds = this.filters.filter((filter) => {
                      //console.log(filter);
                      if(filterId === 0 && isCategory) return false;
                      if(filter.lft < currentFilter.lft) return false;
                      return (filter.rgt <= currentFilter.rgt );
                  }).map(filter => filter.id);
                }
//                console.log(this.currentFilterIds);
                this.filteredMedia = this.medias.filter((media) => {
                    return this.currentFilterIds.includes(media.filter.id);
                });
//                console.log(this.filteredMedia);
            },
            populateMainFilter: function () {
                this.filters.filter((item) => {
                    if(item.lvl === 1) {
                        this.mainFilter.push({
                            id: item.id,
                            label: item.label
                        })
                    }
                });
            },
            toggleConfirmDeleteModal: function(apiUrl, id) {
                this.deleteApiUrl = apiUrl;
                this.deleteMediaId = id;
                this.confirmDeleteModal = !this.confirmDeleteModal;
            },
            checkBeforeDelete: function() {
                axios.delete(this.deleteApiUrl + '/' + this.deleteMediaId)
                    .finally(() => {
                        this.confirmDeleteModal = false;
                        this.deleteApiUrl = "";
                        this.deleteMediaId = 0;
                        location.reload();
                    })
            },
            setDefaultFilter: function () {
              console.log(this.slug);
              console.log(this.mainFilter.length);
                if(this.slug !== "" && this.mainFilter.length > 1) {
                    let selectedFilterId = 0;
                    let selectedFilterLvl = 0;
                    let selectBox = document.getElementById("select-1");
                   // console.log(selectBox);
                    this.filters.find((filter) => {
                        if(this.slug === this.$options.filters.sanitize(filter.label)){
                            selectedFilterId = filter.id;
                            selectedFilterLvl = filter.lvl;
                            selectBox.value = selectedFilterId;
                        }
                    });
                    this.toggleFilters(selectedFilterId, selectedFilterLvl);
                }
            },
            mediaCheckFileExtension: function (filename) {
                return filename.split('.').pop();
            },
            formatTime: function (date) {
                function pad(s) { return (s < 10) ? '0' + s : s; }
                let d = new Date(date);
                return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/');
            },
            toggleEmailModal: function () {
                this.emailModal = !this.emailModal;
            },
            sendEmail: function (url) {
                let mediaArray = this.checkedMedias.map((item) => {
                    return {
                        name: item.name,
                        files: item.files.map((file) => {
                            return file.path + file.filename;
                        })
                    }
                });

                let data = {};

                data.email = this.emailAddress;
                data.medias = mediaArray;

                const config = {
                    headers: { 'content-type': 'application/json' }
                };

                axios.post(url, data, config)
                    .then((response) => {
                        if(response.status === 201) {
                            this.emailAddress = '';
                            this.mailSuccess = true;
                        }
                    })
                    .catch((e) => {
                        console.warn(e);
                    });
            },
            checkByNum: function()
            {
               // console.log(this.ref);
                axios.get(this.UrlResaArc+'/'+this.ref)
                    .then((response) => {
                        //console.log(response);
                        if(response.data.status === 404) {
                            this.dlResaArcModal = true;
                            this.dlResaArcFailure = true;
                        }
                        else
                        {
                            var link = this.UrlResaArc+'/'+this.ref;
                            open(link,"_blank")
                        }
                    })
                    .catch((e) => {
                        console.warn(e);
                    });
            },
            checkByName: function()
            {
               // console.log(this.name);
                let data = {};
                data.name = this.name;
                axios.post(this.UrlByName,data)
                    .then((response) => {
                    //    console.log(response.data);
                        this.filteredMedia =response.data;
                     //   console.log(this.filteredMedia);
                    })
                    .catch((e) => {
                        console.warn(e);
                    });
            }
        }
    }
</script>
