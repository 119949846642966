<script>
    import AppPagination from '../../common/AppPagination';
    import {EventBus} from '../../../event';

    export default {
        name: 'AppCatalogRequestList',
        props: {
            catalogRequests: {
                type: Array
            },
            headers: {
                type: Object
            },
        },
        components: { AppPagination },
        data: function () {
            return {
                displayCount: 50,
                fields: [],
                items: [],
                filteredItems: [],
                filters: []
            }
        },
        mounted: function () {
            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
            EventBus.$on('filtered-data', this.populateFilters);

        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
            EventBus.$off('filtered-data', this.populateFilters);
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push(
                        {
                            key: key,
                            label: this.headers[key],
                            sortable: (key !== 'action')
                        }
                    )
                }
            },
            populateTableItems: function (e) {
                this.items = e.map(catalogRequest =>
                    ({
                        id: catalogRequest.id,
                        enquirer: catalogRequest.email,
                        // TYPE_ARCHITECT= 1
                        // TYPE_INDIVIDUAL = 2
                        // TYPE_JOURNALIST = 3
                        // TYPE_PROFESSIONNAL = 4
                        user_type: catalogRequest.user_type,
                        // TYPE_ALL = 1
                        // TYPE_DOOR = 2
                        // TYPE_GENERAL = 3
                        type: catalogRequest.type,
                        downloaded_at: this.formatDate(catalogRequest.downloaded_at),
                        download_count: catalogRequest.download_count,
                        // STATUS_DOWNLOADED = 1
                        // STATUS_COMPLETED = 2
                        status: catalogRequest.status
                    })
                );
                this.filteredItems = this.items;
            },
            populateFilters: function (e) {
                let statusFilter = e.map(catalogRequest => catalogRequest.status );
                let typeFilter = e.map(catalogRequest => catalogRequest.type );
                this.filters.statuses = [...new Set(statusFilter)];
                this.filters.types = [...new Set(typeFilter)];
            },
            onStatusFilter: function (e) {
                let value = +e.target.value;
                if (value === 0) return this.filteredItems = this.items;
                this.filteredItems = this.items.filter(item => {
                    return item.status === value;
                });
            },
            onTypeFilter: function (e) {
                let value = +e.target.value;
                if (value === 0) return this.filteredItems = this.items;
                this.filteredItems = this.items.filter(item => {
                    return item.type === value;
                });
            },
            formatDate: function (date) {
                let d = new Date(date);
                let dd = (d.getUTCDate() < 10 ? '0' : '') + d.getUTCDate();
                let MM = ((d.getUTCMonth() + 1) < 10 ? '0' : '') + (d.getUTCMonth() + 1);
                let HH = (d.getUTCHours() < 10 ? '0' : '') + d.getUTCHours();
                let mm = (d.getUTCMinutes() < 10 ? '0' : '') + d.getUTCMinutes();
                return dd + '/' + MM + '/' + d.getFullYear() + ' - ' + HH + ':' + mm;
            }
        }
    }
</script>