<script>
    import AppPagination from '../../common/AppPagination';
    import {EventBus} from '../../../event';

    export default {
        name: 'AppIncompleteOrderList',
        props: {
            orders: {
                type: Array
            },
            headers: {
                type: Object
            },
        },
        components: { AppPagination },
        data: function () {
            return {
                displayCount: 50,
                fields: [],
                items: [],
                filteredItems: [],
                postPaginationItems: [],
                searchTerm: ''
            }
        },
        watch: {
            searchTerm: function (val) {
                let tempArray = this.orders.filter((order) => {
                    return (
                        (order.contact.firstname.toLowerCase().includes(val.toLowerCase())) ||
                        (order.contact.lastname.toLowerCase().includes(val.toLowerCase())) ||
                        (order.project.reference.toLowerCase().includes(val.toLowerCase()))
                    );
                });

                this.items = tempArray.map(order => ({
                    id : order.id,
                    contact: (order.contact.lastname+" "+order.contact.firstname),
                    projet: order.project.reference,
                    created: this.formatDate(order.created),
                    })
                )

                this.filteredItems = this.items;
            },
        },
        created: function () {
            this.postPaginationItems = this.orders;
        },
        mounted: function () {
            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push({
                            key: key,
                            label: this.headers[key],
                            sortable: (key !=='action') ? true : false
                        }
                    )
                }
            },
            populateTableItems: function (e) {
                console.log(e);
                this.items = e.map(order => ({
                        id : order.id,
                        contact: (order.contact.lastname+" "+order.contact.firstname),
                        projet: order.project.reference,
                    created: this.formatDate(order.created),
                    })
                )
                this.filteredItems = this.items;
                console.log(this.filteredItems);

            },
            formatDate: function (date) {
                if(date !="" && date!="null")
                {
                    let d = new Date(date);
                    let dd = (d.getUTCDate() < 10 ? '0' : '') + d.getUTCDate();
                    let MM = ((d.getUTCMonth() + 1) < 10 ? '0' : '') + (d.getUTCMonth() + 1);
                    let HH = (d.getUTCHours() < 10 ? '0' : '') + d.getUTCHours();
                    let mm = (d.getUTCMinutes() < 10 ? '0' : '') + d.getUTCMinutes();
                    return dd + '/' + MM + '/' + d.getFullYear() + ' - ' + HH + ':' + mm;
                }
                else
                {
                    return "";
                }
            },
            onFilter: function (e) {
                let filters = {};

                filters.name = e.target.name;
                filters.value = e.target.value;

                EventBus.$emit('activated-filters', filters);
            },
            formatSaisieCommande: function(profile) {
                switch(profile) {
                    case 0:
                        return "Sans accès";
                        break;
                    case 2:
                        return "Accès commande en ligne";
                        break;
                    case 1:
                        return "Accès commande en ligne + Winpro";
                        break;
                    default:
                        return"";
                        break;
                }
            },
            formatStatus:function(status) {
                switch(status) {
                    case 0:
                        return "Inactif";
                        break;
                    case 1:
                        return "Actif";
                        break;
                    default:
                        return"";
                        break;
                }
            }
        }
    }
</script>