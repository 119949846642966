<template>
    <div data-label="Action" class="border-0 p-0">
        <b-dropdown :id="'item_' + rowIndex" class="project__actions-toggle" variant="link" no-caret right v-cloak>
            <template slot="button-content">
                <i class="responsive-table__icon icon-menu-dropdown"></i>
            </template>
            <template v-if="audience === 'projectItem'">
                <b-dropdown-item @click="sendAction(0)"><i class="icon-magnifier"></i> {{labels[0]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(1)"><i class="icon-shopping-cart"></i> {{labels[1]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(2)"><i class="icon-duplicate-image"></i> {{labels[2]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(3)"><i class="icon-waste-bin"></i> {{labels[3]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(4)"><i class="icon-verified"></i> {{labels[4]}} </b-dropdown-item>
            </template>
            <template v-if="audience === 'productItem'">
                <b-dropdown-item @click="sendAction(0)"><i class="icon icon-duplicate-image"></i> {{labels[6]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(1)"><i class="icon icon-waste-bin"></i> {{labels[5]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(2)"><i class="icon icon-file"></i> {{labels[7]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(3)"><i class="icon icon-file"></i> {{labels[12]}} </b-dropdown-item>
            </template>
            <template v-if="audience === 'quoteItem'">
                <b-dropdown-item @click="sendAction(0)"><i class="icon icon-duplicate-image"></i> {{labels[6]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(1)"><i class="icon icon-waste-bin"></i> {{labels[5]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(2)"><i class="icon icon-file"></i> {{labels[7]}} </b-dropdown-item>
            </template>
            <template v-if="audience === 'archiveItem'">
                <b-dropdown-item @click="sendAction(0)"><i class="icon icon-next"></i> {{labels[8]}} </b-dropdown-item>
                <b-dropdown-item @click="sendAction(1)"><i class="icon icon-waste-bin"></i> {{labels[9]}} </b-dropdown-item>
            </template>
            <template v-if="audience === 'orderItem'">
                <!--<b-dropdown-item >Bientôt disponible </b-dropdown-item>-->
<!--                <b-dropdown-item @click="sendAction(5)"><i class="icon icon-cube"></i> {{labels[10]}} </b-dropdown-item>-->
<!--                <b-dropdown-item @click="sendAction(6)"><i class="icon icon-file"></i> {{labels[11]}} </b-dropdown-item>-->
                    <b-dropdown-item @click="sendAction(12)">Télécharger ARC </b-dropdown-item>
                    <b-dropdown-item @click="sendAction(13)">Télécharger xxxxx </b-dropdown-item>
                    <b-dropdown-item @click="sendAction(5)">Télécharger plan de réservation </b-dropdown-item>
                    <b-dropdown-item @click="sendAction(14)">Annuler la commande </b-dropdown-item>
            </template>
        </b-dropdown>
    </div>
</template>

<script>
    import {EventBus} from '../../event';

    export default {
        name: 'AppActionDropdown',
        props: {
            audience: {
                type: String
            },
            rowIndex: {
                type: Number
            },
            itemId: {
                type: Number
            }
        },
        data: function () {
            return {
                labels: dropdownLabels,
                actionId: 0
            }
        },
        methods: {
            sendAction: function (action) {
                EventBus.$emit("send-action", { itemId: this.itemId, action: action });
            }
        }
    }
</script>
