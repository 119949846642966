var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row justify-content-center new-project__content" },
    _vm._l(_vm.items, function (item, index) {
      return _c("div", { staticClass: "col-md-11 col-xl-10" }, [
        _c(
          "div",
          {
            staticClass:
              "row align-items-center no-gutters new-project__content__header mt-3 px-4",
            class: { "bg-disabled": !_vm.step[item.toLowerCase()] },
          },
          [
            _vm._t("header" + item),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto" }, [
              _vm.step[item.toLowerCase()] && index < 3
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-rounded btn-rounded--white",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.togglePanel(index)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.currentPanel === index
                              ? _vm.closeLabel
                              : _vm.openLabel
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        class: {
                          "icon-angle-up": _vm.currentPanel === index,
                          "icon-angle-down": !(_vm.currentPanel === index),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step[item.toLowerCase()] && index === 3
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-rounded btn-rounded--white",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.togglePanel(index)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.currentPanel === index
                              ? _vm.closeLabel
                              : _vm.openLabel
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        class: {
                          "icon-angle-up": _vm.currentPanel === index,
                          "icon-angle-down": !(_vm.currentPanel === index),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentPanel === index,
                expression: "currentPanel === index",
              },
            ],
          },
          [_vm._t("item" + item)],
          2
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }