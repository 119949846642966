<template>
    <swiper :options="swiperOptions" ref="homeSwiper">
        <template v-for="slide in slides">
            <swiper-slide :style="{ 'background-image': 'url(' + slide.image + ')'}"></swiper-slide>
        </template>
    </swiper>
</template>

<script>
    import { swiper, swiperSlide } from 'vue-awesome-swiper'

    export default {
        name: 'AppSwiper',
        props: {
            swiperSlide: {
                type: String
            }
        },
        data:function () {
            return {
                swiperOptions: {
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    disabledClass: '.white-chevron-disabled',
                    direction: 'horizontal',
                    noSwiping: false
                },
                slides: [],
                window: {
                    width: 0,
                    height: 0
                }
            }
        },
        computed: {
            swiper() {
                return this.$refs.homeSwiper.swiper
            }
        },
        created:function () {
            window.addEventListener('load', this.populateSlides);
            this.handleResize();
        },
        beforeDestroy:function () {
            window.removeEventListener('load', this.populateSlides);
        },
        destroy:function () {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            populateSlides: function () {
                this.slides = [
                    {
                        id: 1,
                        image: this.swiperSlide
                    }
                ];
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            }
        }
    }
</script>