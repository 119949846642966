<script>
    export default {
        name: 'AppLibraryImaged',
        props: {
            filters: {
                type: Array
            },
            medias: {
                type: Array
            },
            title: {
                type: String
            },
            libraryUrl: {
                type: String
            },
            urlPublic:
            {
                type: String
            }
        },
        data: function () {
            return {
                imageFolder: "",
                selectedDocument: "",
                selectedFilter: "",
                filtersArray: [],
                searchTerme: ""
            }
        },
        mounted: function () {
            this.getImageFolderName();
            this.populateMainFilter();
        },
        methods: {
            toggleSelect: function(event, path) {
                this.selectedDocument = event.target.value;
                this.selectedFilter = path;
            },
            toggleSelectCalculAttestation: function(event, path) {
                console.log(event,path);
                this.selectedDocument = event.target.value;
                this.selectedFilter = path;
            },
            downloadDocument: function(filterID,filterPath) {
                //let path = this.libraryUrl + '/' + this.selectedFilter + this.selectedDocument;
                console.log(this.libraryUrl + '/' +filterPath + document.getElementById(filterID).value);
                let path = this.libraryUrl + '/' +filterPath + document.getElementById(filterID).value;

                window.open(path, '_target')
            },
            downloadDocumentCalculAttestation: function(filterID,filterPath) {
                let path = this.urlPublic + '/' + filterID+'/' + document.getElementById(filterID).value;
                console.log(this.libraryUrl);
                window.open(path, '_target')
            },

            populateMainFilter: function () {
                this.filters.filter((item) => {
                    if(item.lvl === 1) {
                        this.filtersArray.push({
                            id: item.id,
                            label: item.label,
                            slug: this.$options.filters.sanitize(item.label),
                            path: '',
                            medias: []
                        })
                    }
                });
                this.populateDropdowns();
            },
            populateDropdowns: function () {
                let currentFilter = [];
                this.filtersArray.filter((filter) => {
                    currentFilter.push(filter.id)
                });
                for(let i = 0 ; i < currentFilter.length ; i++) {
                    this.medias.filter((media) => {
                        if(media.filter.id !== currentFilter[i]) return false;
                        // this.filtersArray[i].path = media.files[0].path;
                        this.filtersArray[i].path = media.files[0].path.split("/")[0]+"/";
                        this.filtersArray[i].medias.push(media);
                    });
                }
            },
            getImageFolderName: function () {
                if(this.$options.filters.sanitize(this.title) === 'reglementation') {
                    this.imageFolder = 'regulation';
                } else {
                    this.imageFolder = 'warranties';
                }
            }
        }
    }
</script>
