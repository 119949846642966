<script>
    import axios from 'axios';
    import bcrypt from 'bcryptjs';
    export default {
        name: 'AppAccountData',
        components: {

        },
        props:{
            apiUpdateUrl :
            {
                type: String
            }
        },
        data: function () {
            return {
                contactId : 0,
                password :"",
                confirm : "",
                openUpdateModal:false,
                errorSave: -1
            }
        },
        mounted: function () {
        },
        computed: {

        },
        methods : {
            updateContact: function()
            {
                var bcrypt = require('bcryptjs');

                var hash = bcrypt.hashSync(this.password, 13);
                var hasConfirm = bcrypt.hashSync(this.confirm,13);
                let pwd = this.password;
                this.contactId= this.$refs.contactId.value;
                let urlToGo = this.apiUpdateUrl;
                let error = -1;
                if(this.contactId > 0) {
                    let idContact = this.contactId;
                    if(this.password!="" && this.confirm!="")
                    {
                        console.log(this.password);
                        let _passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_\.#])[A-Za-z\d@$!%*?&-_\.#]{8,}$/;
                        var resultat = _passwordRegex.test(this.password);
                        if(resultat===true)
                        {
                            bcrypt.compare(this.confirm, hash).then((res)=>{
                                if(res===true) {

                                    console.log("mdp match");
                                    axios.post(urlToGo, {
                                        contact: idContact,
                                        password: hash,
                                        confirm: hasConfirm
                                    }).then(response => {
                                        console.log("response", response);
                                        error = response.data;
                                        this.errorSave = error;
                                        console.log("error mdp", error);
                                    });
                                }
                                else
                                {
                                    this.errorSave = 3;
                                    console.log("mdp no match");
                                }
                            }).catch(error => {
                                console.warn(error);
                            });
                        }
                        else
                        {
                            this.errorSave = 2;
                        }
                        console.log("error final",this.errorSave);
                    }
                    else
                    {
                        axios.post(urlToGo, {
                            contact: idContact,
                            password: this.password,
                            confirm: this.confirm
                        }).then(response => {
                            this.errorSave = response.data;
                        });
                    }
                    this.openUpdateModal = true;
                    console.log("error save",this.errorSave);
                }

            },
            onHiddenSave: function()
            {
                this.errorSave=-1;
            },
        }
    }
</script>
