var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-4" }, [
    _c(
      "nav",
      { staticClass: "project-navbar project-navbar--full d-none d-lg-block" },
      [
        _c(
          "ul",
          {
            staticClass:
              "project-navbar__list row row-eq-height list-unstyled justify-content-center",
          },
          _vm._l(_vm.navItems, function (item, index) {
            return item.label != "documents"
              ? _c("li", { staticClass: "project-navbar__item" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "project-navbar__link box h-100 d-flex justify-content-center flex-column",
                      class: { active: item.slug == _vm.currentItem.slug },
                      attrs: { href: item.url },
                    },
                    [
                      _c("div", { staticClass: "text-left" }, [
                        _c("i", {
                          class: "project-navbar__icon icon " + item.icon,
                        }),
                        _vm._v(" "),
                        _c("span", {
                          domProps: { innerHTML: _vm._s(item.label) },
                        }),
                      ]),
                    ]
                  ),
                ])
              : _c(
                  "div",
                  { staticStyle: { position: "absolute", right: "12%" } },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          "font-weight": "normal",
                          "font-size": "1em",
                        },
                        attrs: { href: _vm.links[0], target: "_blank" },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "15%" },
                          attrs: {
                            src: "/build/images/common/icons/icon-pdf.svg",
                            alt: "PDF",
                          },
                        }),
                        _vm._v(" Tutoriel"),
                      ]
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          "font-weight": "normal",
                          "font-size": "1em",
                        },
                        attrs: { href: _vm.links[1], target: "_blank" },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "15%" },
                          attrs: {
                            src: "/build/images/common/icons/icon-pdf.svg",
                            alt: "PDF",
                          },
                        }),
                        _vm._v(" Fonctionnalités"),
                      ]
                    ),
                    _c("br"),
                  ]
                )
          }),
          0
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass:
          "project-navbar project-navbar--mobile d-lg-none row justify-content-center",
      },
      [
        _c("div", { staticClass: "col-11" }, [
          _c(
            "select",
            {
              staticClass: "form-control",
              attrs: { name: "" },
              on: {
                change: function ($event) {
                  return _vm.navigate($event)
                },
              },
            },
            _vm._l(_vm.navItemsMobile, function (item, index) {
              return _c(
                "option",
                {
                  domProps: {
                    selected: item.slug == _vm.currentItem.slug,
                    value: item.url,
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }