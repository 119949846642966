<script>
    import axios from 'axios';

    export default {
        name: 'AppRegistrationAdd',
        data: function () {
            return {
                errors: {},
                gdprError: false,
                btnAgree: null,
                btnDisagree: null,
                hasResponse: false,
                responseData: '',
                contentHeight: ''
            }
        },
        mounted: function () {
            this.btnAgree = document.getElementById('registration_gdpr_0');
            this.btnDisagree = document.getElementById('registration_gdpr_1');
            this.btnAgree.addEventListener('click', this.checkOnClick);
            this.btnDisagree.addEventListener('click', this.checkOnClick);
            this.calculateContentHeight();
        },
        beforeDestroy: function () {
            this.btnAgree.removeEventListener('click', this.checkOnClick);
            this.btnDisagree.removeEventListener('click', this.checkOnClick);
        },
        methods: {
            checkBeforeSubmit: function (e) {
                let data = new FormData();
                for(let d of e.target) {
                    if (d.name.split('[').pop().split(']')[0] === 'gdpr') {
                        if (+d.value === 1 && d.checked) {
                            data.append('gdpr', +d.value);
                        } else if (+d.value === 0 && d.checked){
                            data.append('gdpr', +d.value);
                        }
                    } else if(d.name.split('[').pop().split(']')[0] === 'newsletter') {
                        data.append('newsletter', +d.checked);
                    } else if(d.name.split('[').pop().split(']')[0] === 'status') {
                        data.append('status', '0');
                    } else {
                        data.append(d.name.split('[').pop().split(']')[0], d.value);
                    }
                }
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };
                axios.post(e.target.action, data, config)
                    .then((response) => {
                        if(response.status === 200) {
                            this.hasResponse = true;
                            this.responseData = response.data;

                        } else {
                            this.errors = response.data.errors;
                        }
                    })
                    .catch((error) => {
                        console.warn(error)
                    })
            },
            checkOnClick: function (e) {
                this.gdprError = (+e.target.value === 0);
            },
            addDashes: function (e) {
                if(e.target.value.length === 14) return;
                if(e.keyCode === 8 || e.keyCode === 37 || e.keyCode === 39) return;
                let newStr = '';
                let groups = e.target.value.split('-');
                for(let i in groups) {
                    if (groups[i].length % 2 === 0) {
                        newStr += groups[i] + "-";
                    } else {
                        newStr += groups[i];
                    }
                }
                e.target.value = newStr;
            },
            calculateContentHeight: function () {
                let header = document.querySelector(".bouvet-header");
                if(header) {
                    this.contentHeight = (window.innerHeight - header.clientHeight) + 'px';
                }
            }
        }
    }
</script>