var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "swiper",
    { ref: "homeSwiper", attrs: { options: _vm.swiperOptions } },
    [
      _vm._l(_vm.slides, function (slide) {
        return [
          _c("swiper-slide", {
            style: { "background-image": "url(" + slide.image + ")" },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }