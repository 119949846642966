<script>
    import AppPagination from '../../common/AppPagination';
    import {EventBus} from '../../../event';
    import axios from 'axios';
    export default {
        name: 'AppInfosRequestList',
        props: {
            infosRequests: {
                type: Array
            },
            headers: {
                type: Object
            },
            sendUrl: {
                type: String
            }
        },
        components: { AppPagination },
        data: function () {
            return {
                displayCount: 50,
                fields: [],
                items: [],
                filteredItems: [],
                filters: [],
                displayModalTrigger: false,
                emailToSend: "",
                idInfos : 0,
                errorSend: 0,
                searchTerm: ''
            }
        },
        watch: {
            searchTerm: function (val) {
                let tempArray = this.infosRequests.filter((infosRequest) => {
                    return (
                        (infosRequest.imported_user.firstname.toLowerCase().includes(val.toLowerCase())) ||
                        (infosRequest.imported_user.lastname.toLowerCase().includes(val.toLowerCase()))
                    );
                });

                this.items = tempArray.map(infosRequest => ({
                        id: infosRequest.id,
                        enquirer: (infosRequest.imported_user.civility+" "+infosRequest.imported_user.lastname+" "+infosRequest.imported_user.firstname),
                        message: infosRequest.message,
                        date: this.formatDate(infosRequest.date),
                        // STATUS_DOWNLOADED = 1
                        // STATUS_COMPLETED = 2
                        status: infosRequest.status
                    })
                )

                this.filteredItems = this.items;
            },
        },
        mounted: function () {
            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
            EventBus.$on('filtered-data', this.populateFilters);

        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
            EventBus.$off('filtered-data', this.populateFilters);
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push(
                        {
                            key: key,
                            label: this.headers[key],
                            sortable: (key !== 'action')
                        }
                    )
                }
            },
            populateTableItems: function (e) {
                console.log(e);
                this.items = e.map(infosRequest =>
                    ({

                        id: infosRequest.id,
                        enquirer: (infosRequest.imported_user.civility+" "+infosRequest.imported_user.lastname+" "+infosRequest.imported_user.firstname),
                        message: infosRequest.message,
                        date: this.formatDate(infosRequest.date),
                        // STATUS_DOWNLOADED = 1
                        // STATUS_COMPLETED = 2
                        status: infosRequest.status
                    })
                );
                this.filteredItems = this.items;
            },
            populateFilters: function (e) {
                let statusFilter = e.map(catalogRequest => catalogRequest.status );
                let typeFilter = e.map(catalogRequest => catalogRequest.type );
                this.filters.statuses = [...new Set(statusFilter)];
                this.filters.types = [...new Set(typeFilter)];
            },
            onStatusFilter: function (e) {
                let value = +e.target.value;
                if (value === 0) return this.filteredItems = this.items;
                this.filteredItems = this.items.filter(item => {
                    return item.status === value;
                });
            },
            onTypeFilter: function (e) {
                let value = +e.target.value;
                if (value === 0) return this.filteredItems = this.items;
                this.filteredItems = this.items.filter(item => {
                    return item.type === value;
                });
            },
            formatDate: function (date) {
                if(date !="" && date!="null")
                {
                    let d = new Date(date);
                    let dd = (d.getUTCDate() < 10 ? '0' : '') + d.getUTCDate();
                    let MM = ((d.getUTCMonth() + 1) < 10 ? '0' : '') + (d.getUTCMonth() + 1);
                    let HH = (d.getUTCHours() < 10 ? '0' : '') + d.getUTCHours();
                    let mm = (d.getUTCMinutes() < 10 ? '0' : '') + d.getUTCMinutes();
                    return dd + '/' + MM + '/' + d.getFullYear() + ' - ' + HH + ':' + mm;
                }
                else
                {
                    return "";
                }
            },
            sendByMailTrigger: function(idCible)
            {
                this.displayModalTrigger = true;
                this.idInfos = idCible;
            },
            sendRequest: function(e)
            {
                axios.post(this.sendUrl, {"email" : this.emailToSend,"idInfos": this.idInfos})
                    .then((response) => {
                        if(response.status === 200) {
                            this.hasResponse = true;
                            this.responseData = response.data;
                            if(this.responseData===true)
                                this.errorSend = 1;
                            else
                                this.errorSend = 2;
                        } else {
                            this.errors = response.data.errors;
                        }
                    })
                    .catch((error) => {
                        console.warn(error)
                    })
            },
            onHiddenSend: function()
            {
                this.errorSend = 0;
            }
        }
    }
</script>