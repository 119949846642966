var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row pt-4 justify-content-center new-project__content__body",
    },
    [
      _c("div", { staticClass: "col-md" }, [
        _c(
          "div",
          { staticClass: "row quote__edit" },
          [
            _vm._t("editPrintQuote"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-5 form-group mt-3" },
              [
                _vm._t("pdfIcon"),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedPDFQuoteType,
                        expression: "selectedPDFQuoteType",
                      },
                    ],
                    staticClass: "form-control ml-3 quote__edit__select",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedPDFQuoteType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onPDFQuoteTypeSelect,
                      ],
                    },
                  },
                  [
                    _c("option", { domProps: { value: -1 } }, [
                      _vm._v(_vm._s(_vm.selectLabel)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "option",
                      { domProps: { value: 0 } },
                      [_vm._t("pdfClient")],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "option",
                      { domProps: { value: 1 } },
                      [_vm._t("pdfPrice")],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "option",
                      { domProps: { value: 2 } },
                      [_vm._t("pdfNoPrice")],
                      2
                    ),
                  ]
                ),
              ],
              2
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._t("bonuses"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-5 align-self-start h-100" },
              [_vm._t("wordInfo")],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "w-100" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-5 form-group" },
              [
                _vm._t("wordIcon"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "form-control quote__edit__select ml-3",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onWordQuote()
                      },
                    },
                  },
                  [_vm._t("wordNoPrice")],
                  2
                ),
              ],
              2
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }