<template>
    <div class="row pt-4 justify-content-center new-project__content__body" v-cloak>
        <div class="col-md">
            <div class="row quote__edit">
                <slot name="editPrintQuote"></slot>
                <div class="col-md-6 col-lg-5 form-group mt-3">
                    <slot name="pdfIcon"></slot>
                    <select class="form-control ml-3 quote__edit__select" v-model="selectedPDFQuoteType" @change="onPDFQuoteTypeSelect">
                        <option :value="-1">{{ selectLabel }}</option>
                        <option :value="0"><slot name="pdfClient"></slot></option>
                        <option :value="1"><slot name="pdfPrice"></slot></option>
                        <option :value="2"><slot name="pdfNoPrice"></slot></option>
                    </select>
                </div>
            </div>
            <div class="row">
                <slot name="bonuses"></slot>
                <div class="col-md-6 col-lg-5 align-self-start h-100">
                    <slot name="wordInfo"></slot>
                </div>
                <!-- <div class="d-none d-md-block col-md-6 col-lg-5 align-self-start h-100">
                    <slot name="certificationInfo"></slot>
                </div> -->
                <div class="w-100"></div>
                <div class="col-md-6 col-lg-5 form-group">
                    <slot name="wordIcon"></slot>
                    <button @click="onWordQuote()" type="button" class="form-control quote__edit__select ml-3"><slot name="wordNoPrice"></slot></button>
                </div>
                <!-- <div class="d-md-none col-md-6 col-lg-5 align-self-start h-100">
                    <slot name="certificationInfoMobile"></slot>
                </div>
                <div class="col-md-6 col-lg-5 form-group">
                    <button class="quote__edit__button">
                        <slot name="downloadCertification"></slot>
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '../../event';

    export default {
        name: "app-project-accordeon-item-three",
        props: {
            selectLabel: {
                type: String
            },
            urlPdfQuotePrice: {
                type: String
            },
            urlPdfQuoteNoPrice: {
                type: String
            },
            urlPdfClientQuote: {
                type: String
            },
            urlWordQuotePrice: {
                type: String
            },
            urlWordQuoteNoPrice: {
                type: String
            },
            urlWordClientQuote: {
                type: String
            },
            project: {
                type: Object
            }
        },
        data: function () {
            return {
                selectedPDFQuoteType: -1,
                selectedWordQuoteType: -1,
                quoteId: 0,
            }
        },
        mounted: function () {
          if(this.project["id"] !== null) {
            let quote = this.project.quotes[this.project.quotes.length - 1];
            this.quoteId = quote.id;
          }
            EventBus.$on("send-quote-id", this.getQuoteId);
        },
        methods: {
            getQuoteId:function (value) {
                this.quoteId = value;
            },
            onPDFQuoteTypeSelect() {
                let urlQuotePrice = this.urlPdfQuotePrice.substring(0, this.urlPdfQuotePrice.length-1);
                let urlQuoteNoPrice = this.urlPdfQuoteNoPrice.substring(0, this.urlPdfQuoteNoPrice.length-1);
                let urlClientQuote = this.urlPdfClientQuote.substring(0, this.urlPdfClientQuote.length-1);
                if(this.selectedPDFQuoteType === 1) {
                    window.open(urlQuotePrice + this.quoteId, '_blank');
                    this.selectedPDFQuoteType = -1;
                } else if(this.selectedPDFQuoteType === 2) {
                    window.open(urlQuoteNoPrice + this.quoteId, '_blank');
                    this.selectedPDFQuoteType = -1;
                } else {
                    window.open(urlClientQuote + this.quoteId, '_blank');
                    this.selectedPDFQuoteType = -1;
                }
            },
            onWordQuote() {
                let urlQuoteDoc =this.urlWordClientQuote.substring(0, this.urlWordClientQuote.length-1) ;
                window.open(urlQuoteDoc + this.quoteId, '_blank');

            },
        }
    }
</script>

<style scoped>

</style>
