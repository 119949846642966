<script>
    export default {
        name: 'AppListNews',
        props: {
            categories: {
                type: Array
            },
            articles: {
                type: Array
            }
        },
        data: function() {
            return {
                selectedCategory: 0,
                searchTerm: "",

            }
        },
        computed: {
            filteredArticles: {
                get: function() {
                    return this.articles.filter(this.filterByCategory);
                },
                set: function() {
                    return true;
                }
            }
        },
        mounted: function () {
            this.filteredArticles = this.articles;
        },
        create: function()
        {
            window.addEventListener('resize', this.handleResize);

        },
        methods: {
            onCategoryChange: function (event) {
                this.selectedCategory = event.target.value;
            },
            filterByCategory: function (article) {
                if(this.searchTerm === "") {
                    if(+this.selectedCategory === 0) return true;
                    if(+this.selectedCategory > 0) return article.category.id === +this.selectedCategory;
                } else {
                    let title = this.$options.filters.sanitize(article.title);
                    let standfirst = this.$options.filters.sanitize(article.standfirst);
                    let description = this.$options.filters.sanitize(article.description);
                    let searchTerm = this.$options.filters.sanitize(this.searchTerm);
                    if(+this.selectedCategory === 0) {
                        if(title.includes(searchTerm)) {
                            return true;
                        } else if(standfirst.includes(searchTerm)) {
                            return true;
                        } else if(description.includes(searchTerm)) {
                            return true;
                        }
                    } else {
                        if(article.category.id === +this.selectedCategory) {
                            if(title.includes(searchTerm)) {
                                return true;
                            } else if(standfirst.includes(searchTerm)) {
                                return true;
                            } else if(description.includes(searchTerm)) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

    }
</script>