<template>
    <div class="col-auto menu-toggle" :class="{'is-active': openMenu }" @click="toggleMenu()">
        <span class="menu-toggle__line"></span>
        <span class="menu-toggle__line"></span>
        <span class="menu-toggle__line"></span>
    </div>
</template>

<script>
    export default {
        name: 'AppMenuToggle',
        props: {
            headerWelcome: {
                type: String
            },
            imageAlt: {
                type: String
            }
        },
        data:function () {
            return {
                openMenu: false
            }
        },
        methods: {
            scrollToTop:function () {
              let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
              if (currentScroll > 0) {
                window.requestAnimationFrame(this.scrollToTop);
                window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)))
              }
            },
            toggleMenu: function () {
                this.openMenu = !this.openMenu;
                this.$root.$emit('menu-state', this.openMenu);

                if (this.openMenu) {

                  this.scrollToTop();
                }
            }
        }
    }
</script>
