<template>
    <b-pagination v-cloak class="col-auto mt-4" size="md" @input="loadPageData()" :total-rows="totalDataCount()" v-model="currentPage" :per-page="dataPerPage">
    </b-pagination>
</template>

<script>
    import {EventBus} from '../../event';

    export default {
        name: 'AppPagination',
        props: {
            dataPerPage: {
                type: Number
            },
            data: {
                type: Array
            },
            filters: {
                type: Array
            }
        },
        data: function() {
            return {
                currentPage: 1,
                currentData: this.data
            }
        },
        watch: {
            dataPerPage: function () {
                this.loadPageData();
            },
            data: function (val) {
                this.currentData = val;
                this.loadPageData();
            },
        },
        mounted: function () {
            this.totalPages();
            window.addEventListener('load', this.loadPageData);
            EventBus.$on('activated-filters', this.filterData);
        },
        beforeDestroy: function () {
            window.removeEventListener('load', this.loadPageData);
            EventBus.$off('activated-filters', this.filterData);
        },
        methods: {
            totalDataCount: function () {
                return this.currentData.length;
            },
            totalPages: function () {
                return Math.ceil(this.currentData.length / this.dataPerPage);
            },
            filterData: function (e) {

                this.currentData = [];
                if(+e.value === -1) {
                    this.currentData = this.data;
                } else {
                    this.currentData = this.data.filter((obj) => {

                        if(obj[e.name] === +e.value) {
                            return obj;
                        }
                        else if(obj.status)
                        {

                                return obj.status === e.status;
                        }
                        else if(obj[e.name].root) {
                            return obj[e.name].root.label === e.value;
                        } else {
                            return obj[e.name].label === e.value;
                        }
                    });
                }
                this.loadPageData();
            },
            loadPageData: function () {
                let begin = ((this.currentPage - 1) * this.dataPerPage);
                let end = begin + this.dataPerPage;
                EventBus.$emit('filtered-data', this.currentData.slice(begin, end))
            },
        }
    }
</script>

<style scoped>

</style>