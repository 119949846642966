<template>
  <div class="py-4">
    <nav class="project-navbar project-navbar--full d-none d-lg-block" v-cloak>
      <ul class="project-navbar__list row row-eq-height list-unstyled justify-content-center">
        <li v-if="item.label!='documents'" class="project-navbar__item" v-for="(item, index) in navItems">
          <a  class="project-navbar__link box h-100 d-flex justify-content-center flex-column" :class="{ 'active': item.slug == currentItem.slug }" :href="item.url">
            <div class="text-left">
              <i :class="'project-navbar__icon icon ' + item.icon"></i>
              <span v-html="item.label"></span>
            </div>
          </a>
        </li>
        <div v-else style="position:absolute;right: 12%;">
          <a :href="links[0]" target="_blank" style="font-weight: normal;font-size: 1em;"><img src="/build/images/common/icons/icon-pdf.svg" style="width: 15%;" alt="PDF"> Tutoriel</a><br/><br/>
          <a :href="links[1]" target="_blank" style="font-weight: normal;font-size: 1em;"><img src="/build/images/common/icons/icon-pdf.svg" style="width: 15%;" alt="PDF"> Fonctionnalités</a><br/>
        </div>
      </ul>
    </nav>

    <form class="project-navbar project-navbar--mobile d-lg-none row justify-content-center">
      <div class="col-11">
        <select class="form-control " name="" @change="navigate($event)">
          <option v-for="(item, index) in navItemsMobile" :selected="item.slug == currentItem.slug" :value="item.url">{{ item.label }}</option>
        </select>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'AppProjectNavbar',
  props: {
    navItems: {
      type: Array
    },
    currentItem: {
      type: Object
    },
    links:{
      type: Array
    }
  },
  computed: {
    navItemsMobile: function(){
      return this.navItems.map(d =>{
        d.label = d.label.replace('<br>', ' ');
        return d;
      });
    },
  },
  methods: {
    navigate: function(event){
      window.document.location = event.target.value;
    },
  }
}
</script>
