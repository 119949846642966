<script>
    import { EventBus } from '../../../event';
    import AppPagination from '../../common/AppPagination';
    import axios from 'axios';

    export default {
        name: 'AppLibraryList',
        props: {
            libraryCategories: {
                type: Array
            },
            libraryFilters: {
                type: Array
            },
            libraryMedias: {
                type: Array
            },
            headers: {
                type: Object
            },
        },
        components: { AppPagination },
        data: function () {
            return {
                confirmDeleteModal: false,
                deleteApiUrl: '',
                deleteMediaId: 0,
                displayCount: 50,
                fields: [],
                items: [],
                postPaginationItems: [],
                searchTerm: ''
            }
        },
        created: function () {
            this.postPaginationItems = this.libraryMedias;
        },
        mounted: function () {
            this.populateTableFields();
            EventBus.$on('filtered-data', this.populateTableItems);
        },
        beforeDestroy: function () {
            EventBus.$off('filtered-data', this.populateTableItems);
        },
        watch: {
            searchTerm: function (val) {
                let tempArray = this.libraryMedias.filter((media) => {
                    return media.name.toLowerCase().includes(val.toLowerCase());
                });
                this.items = tempArray.map(libraryMedia =>
                    ({
                        id: libraryMedia.id,
                        category: libraryMedia.filter.root ? libraryMedia.filter.root.label : libraryMedia.filter.label,
                        name: libraryMedia.name,
                        filters: this.formatFilter(libraryMedia.filter),
                        publication_date: this.formatDate(libraryMedia.publication_date),
                        expiry_date: (libraryMedia.expiry_date) ? this.formatDate(libraryMedia.expiry_date) : "",
                        is_public: libraryMedia.is_public
                    })
                );
                this.postPaginationItems = tempArray;
            },
        },
        methods: {
            populateTableFields: function () {
                for(let key in this.headers) {
                    this.fields.push(
                        {
                            key: key,
                            label: this.headers[key],
                            sortable: (key !== 'action') ? true : false
                        }
                    )
                }
            },
            populateTableItems: function (e) {
                this.items = e.map(libraryMedia =>
                    ({
                        id: libraryMedia.id,
                        category: libraryMedia.filter.root ? libraryMedia.filter.root.label : libraryMedia.filter.label,
                        name: libraryMedia.name,
                        filters: this.formatFilter(libraryMedia.filter),
                        publication_date: this.formatDate(libraryMedia.publication_date),
                        expiry_date: (libraryMedia.expiry_date) ? this.formatDate(libraryMedia.expiry_date) : "",
                        is_public: libraryMedia.is_public
                    })
                )
            },
            onCategoryFilter: function (e) {
                let value = +e.target.value;
                if(value > -1) {
                    let lft;
                    let rgt;
                    let tempMediaArray = this.libraryMedias.filter((media) => {
                        return media.filter.id === value;
                    });
                    let tempFilterArray = this.libraryFilters.filter((filter) => {
                        return filter.id === value;
                    });
                    lft = tempFilterArray[0].lft;
                    rgt = tempFilterArray[0].rgt;
                    let listFilter = this.libraryFilters.filter((filter) => {
                        return (filter.lft >= lft && filter.rgt <rgt );
                    });
                    this.libraryMedias.filter((media) => {
                        for (let filter of listFilter) {
                            if(media.filter.id === filter.id) {
                                tempMediaArray.push(media);
                            }
                        }
                    });
                    this.items = tempMediaArray.map(libraryMedia =>
                        ({
                            id: libraryMedia.id,
                            category: libraryMedia.filter.root ? libraryMedia.filter.root.label : libraryMedia.filter.label,
                            name: libraryMedia.name,
                            filters: this.formatFilter(libraryMedia.filter),
                            publication_date: this.formatDate(libraryMedia.publication_date),
                            expiry_date: (libraryMedia.expiry_date) ? this.formatDate(libraryMedia.expiry_date) : "",
                            is_public: libraryMedia.is_public
                        })
                    );
                    this.postPaginationItems = tempMediaArray;
                } else {
                    this.populateTableItems(this.libraryMedias);
                }
            },
            toggleConfirmDeleteModal: function(apiUrl, id) {
                this.deleteApiUrl = apiUrl;
                this.deleteMediaId = id;
                this.confirmDeleteModal = !this.confirmDeleteModal;
            },
            checkBeforeDelete: function() {
                axios.delete(this.deleteApiUrl + '/' + this.deleteMediaId)
                    .then(()=> {
                        let indexOfMediaDeleted = this.items.findIndex((item) => item.id === +this.deleteMediaId);
                        this.items.splice(indexOfMediaDeleted, 1);
                    })
                    .finally(() => {
                        this.confirmDeleteModal = false;
                        this.deleteApiUrl = "";
                        this.deleteMediaId = 0;
                    })
            },
            formatFilter: function (itemFilters) {
                let array = [];
                let filter;
                array.push(itemFilters.label);
                let isLast = false;
                while(!isLast) {
                    if (itemFilters.hasOwnProperty('parent_filter')) {
                        let obj = itemFilters['parent_filter'];
                        if(obj.lvl > 0) {
                            array.push(obj.label);
                        }
                        itemFilters = obj;
                    } else {
                        isLast = true;
                    }
                }
                filter = array.reverse();
                return filter;
            },
            formatDate: function (date) {
                let d = new Date(date);
                let dd = (d.getUTCDate() < 10 ? '0' : '') + d.getUTCDate();
                let MM = ((d.getUTCMonth() + 1) < 10 ? '0' : '') + (d.getUTCMonth() + 1);
                return dd + '/' + MM + '/' + d.getFullYear();
            }
        }
    }
</script>