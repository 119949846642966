<template>
    <div class="row justify-content-center new-project__content" v-cloak>
        <div class="col-md-11 col-xl-10" v-for="(item, index) in items">
            <div class="row align-items-center no-gutters new-project__content__header mt-3 px-4" :class="{ 'bg-disabled': !step[item.toLowerCase()] }">
                <slot :name="'header' + item"></slot>
                <div class="col-auto">
                    <button v-if="step[item.toLowerCase()] && index < 3" @click="togglePanel(index)" type="button" class="btn btn-rounded btn-rounded--white">
                        <span>{{ (currentPanel === index) ? closeLabel : openLabel }}</span> <i :class="{'icon-angle-up': (currentPanel === index), 'icon-angle-down': !(currentPanel === index)}"></i>
                    </button>
                    <button v-if="step[item.toLowerCase()] && index === 3" @click="togglePanel(index)" type="button" class="btn btn-rounded btn-rounded--white">
                        <span>{{ (currentPanel === index) ? closeLabel : openLabel }}</span> <i :class="{'icon-angle-up': (currentPanel === index), 'icon-angle-down': !(currentPanel === index)}"></i>
                    </button>
                </div>
            </div>
            <!--<div>-->
            <div v-show="currentPanel === index">
                <slot :name="'item' + item"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '../../event';

    export default {
        name: 'AppProjectAccordeon',
        props: {
            editLabel: {
                type: String
            },
            openLabel: {
                type: String
            },
            closeLabel: {
                type: String
            },
            project: {
                type: Object
            }
        },
        data: function () {
            return {
                currentPanel: 0,
                items: ['Info', 'Numbering', 'Edit', 'Order'],
                step: {
                    info: true,
                    numbering: false,
                    edit: false,
                    order: false
                }
            }
        },
        mounted: function () {
            EventBus.$on("header-button-action", this.displayHeaderButton);
            let urlParams = new URLSearchParams(window.location.search);
            if(this.project["id"] !== null) {
              this.step = {
                  info: true,
                  numbering: true,
                  edit: true,
                  order: true
              }

              if (urlParams.get('action') == 'o') {
                this.togglePanel(3);
              }
            }
        },
        methods: {
            displayHeaderButton: function (event) {
                this.step[event.step] = event.state;
                if(event.step !== 'order') {
                    this.togglePanel(this.currentPanel + 1);
                } else {
                    this.togglePanel(2);
                }
            },
            togglePanel: function (index) {
                if(this.currentPanel === index) {
                    this.currentPanel = -1;
                } else {
                    this.currentPanel = index;

                    let el = document.getElementsByClassName('new-project__content')[0].children[index];

                    setTimeout(() => {
                      let ly = el.offsetTop + el.offsetParent.offsetTop - document.querySelector(".minco-header").clientHeight;
                      this.scrollToOffset(ly, (document.documentElement.scrollTop || document.body.scrollTop));
                    }, 100);
                }
            },

            scrollToOffset:function (offset, currentScroll) {
              if (Math.abs(Math.floor((offset - currentScroll))) > 1) {
                let targetScroll = currentScroll + (offset - currentScroll) / 5;
                window.scrollTo(0, targetScroll);
                window.requestAnimationFrame(() => this.scrollToOffset(offset, targetScroll));
              }
            },
        }
    }
</script>
